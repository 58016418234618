import axios from "axios";

const store = {
  namespaced: true,

  state: {
    loading: false,
    publishersList: [],
    selectedPublisher: {},
    publisherMapping: {},
    pfUnitList: [],
    enableOptions: [
      {
        text: "Enabled",
        value: true,
      },
      {
        text: "Disabled",
        value: false,
      },
    ],
  },

  getters: {
    getLoading: state => {
      return state.loading;
    },
    getPublishersList: state => {
      return state.publishersList;
    },
    getPfUnitList: state => {
      return state.pfUnitList;
    },
    getSelectedPublisher: state => {
      return state.selectedPublisher;
    },
    getPublisherMapping: state => {
      return state.publisherMapping;
    },
    getEnableOptions: state => {
      return state.enableOptions;
    },
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setPublishersList(state, publishersList) {
      state.publishersList = publishersList;
    },
    setPfUnitList(state, pfUnitList) {
      pfUnitList.forEach(element => {
        element.originalEnabledValue = element.enabled;
      });
      state.pfUnitList = pfUnitList;
    },
    setSelectedPublisher(state, publisher) {
      state.selectedPublisher = publisher;
    },
    setPublisherMapping(state, publisherMapping) {
      state.publisherMapping = publisherMapping;
    },
  },
  actions: {
    async fetchPublishersList({ commit }) {
      commit("setLoading", true);
      const response = await axios.get(
        "/price_floor/configuration/publisher/all"
      );
      commit("setPublishersList", response.data);
      commit("setLoading", false);
    },
    async selectPublisher({ commit, state }, publisher) {
      commit("setLoading", true);
      commit("setSelectedPublisher", publisher);

      let mapping_request;
      let pf_units_request;
      if (state.selectedPublisher) {
        mapping_request = axios.get(
          "/price_floor/configuration/publisher_mapping/" +
            state.selectedPublisher.publisher_mapping_id
        );

        pf_units_request = axios.get(
          "/price_floor/configuration/publisher/" +
            state.selectedPublisher.id +
            "/pf_units"
        );
      }

      let [response_mapping, response_pf_units] = await axios.all([
        mapping_request,
        pf_units_request,
      ]);
      let statusObj = {
        key: "enabled",
        label: "Enabled",
      };

      if (response_mapping) {
        response_mapping.data.mapping.push(statusObj);
        commit("setPublisherMapping", response_mapping.data);
      }
      if (response_pf_units) {
        commit("setPfUnitList", response_pf_units.data);
      }
      commit("setLoading", false);
    },
    async clearSwitcherData({ commit }) {
      commit("setPublisherMapping", {});
      commit("setPfUnitList", []);
      commit("setSelectedPublisher", {});
    },
    async savePfUnits({ commit, state }, changedPfUnitsList) {
      commit("setLoading", true);
      await axios.put(
        "/price_floor/configuration/publisher/" +
          state.selectedPublisher.id +
          "/pf_units",
        changedPfUnitsList
      );
      commit("setLoading", false);
    },
    async doManualRelease({ commit }) {
      commit("setLoading", true);
      const response = await axios.post(
        "/price_floor/configuration/manual_release"
      );
      commit("warnings/snackbarInfo", response.data.status, {
        root: true,
      });
      commit("setLoading", false);
    },
  },
};

export default store;
