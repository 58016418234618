const store = {
  namespaced: true,
  state: {
    local_selection: [],
  },
  getters: {
    getLocalSelection(state) {
      return state.local_selection;
    },
  },
  mutations: {
    setLocalSelection(state, input) {
      state.local_selection = input;
    },
  },
};

export default store;
