<template>
  <v-col>
    <v-row>
      <v-col> <PriceFloorSwitcher /> </v-col>
    </v-row>
  </v-col>
</template>

<script>
import PriceFloorSwitcher from "@/components/PriceFloor/Configuration/PriceFloorSwitcher.vue";

export default {
  components: {
    PriceFloorSwitcher,
  },
};
</script>

<style></style>
