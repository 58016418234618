var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',[_c('v-app-bar',{staticClass:"stickyTop",attrs:{"app":"","fixed":""}},[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-card-title',[_vm._v("PFUnits Manager")])],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"orange"},on:{"click":() => {
                _vm.manualReleaseConfirmationDialog = true;
              }}},[_vm._v(" Manual Deploy for all Publishers ")]),_c('ConfirmationDialog',{attrs:{"confirmation-dialog":_vm.manualReleaseConfirmationDialog,"on-confirm":_vm.onManualRelease,"on-close":() => {
                _vm.manualReleaseConfirmationDialog = false;
              },"msg":"Are you sure you want to do manual release?"}})],1)],1),_c('v-row',{ref:"filterRow",attrs:{"align":"center"}},[_c('v-col',[_c('v-autocomplete',{attrs:{"items":_vm.getPublishersList,"return-object":"","item-text":"name","label":"Publisher"},on:{"change":_vm.onPublisherSelected},model:{value:(_vm.publisher),callback:function ($$v) {_vm.publisher=$$v},expression:"publisher"}})],1),_c('v-divider',{directives:[{name:"show",rawName:"v-show",value:(_vm.treeLen !== 0 && _vm.publisher && _vm.publisher.id != null),expression:"treeLen !== 0 && publisher && publisher.id != null"}],attrs:{"vertical":""}}),_vm._l((_vm.getPublisherMapping.mapping),function(item){return _c('v-col',{key:item.id},[_c('v-autocomplete',{attrs:{"items":_vm.uniqueElementsObject[item.key],"label":item.label,"value":_vm.filters[item.key],"multiple":""},on:{"change":value => {
                _vm.filters[item.key] = value;
                _vm.generateTree();
              }},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('SelectAllCheckbox',{attrs:{"element":item.key,"filters":_vm.filters[item.key],"all":_vm.uniqueElementsObject[item.key]},on:{"toggle":_vm.selectAll}})]},proxy:true},{key:"append-outer",fn:function(){return [_c('v-icon',{attrs:{"icon":""},on:{"click":() => {
                    _vm.filters[item.key] = '';
                    _vm.generateTree();
                  }}},[_vm._v(" delete ")])]},proxy:true}],null,true)})],1)})],2),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.treeLen !== 0 && _vm.publisher && _vm.publisher.id != null),expression:"treeLen !== 0 && publisher && publisher.id != null"}]},[_c('v-card-actions',[_c('v-row',{attrs:{"align":"center","justify":"start"}},[_c('v-col',[_c('v-btn',{staticClass:"my-4",attrs:{"color":"green","disabled":_vm.changedPfUnitsList.length === 0},on:{"click":_vm.onSave}},[_vm._v(" Save ")]),_c('v-btn',{staticClass:"ml-4 my-4",attrs:{"color":"blue"},on:{"click":_vm.showAllElementsInTree}},[_vm._v(" "+_vm._s(_vm.expandedTxt)+" ")]),_c('v-dialog',{model:{value:(_vm.saveDialog),callback:function ($$v) {_vm.saveDialog=$$v},expression:"saveDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Items to save ")]),_c('v-card-text',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Path ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Original Status ")]),_c('th',{staticClass:"text-left"},[_vm._v(" New Status ")])])]),_c('tbody',_vm._l((_vm.changedPfUnitsList),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(_vm.generatePath(item)))]),_c('td',[(item.originalEnabledValue)?_c('v-chip',{attrs:{"color":"green"}},[_vm._v(" Enabled ")]):_c('v-chip',{attrs:{"color":"gray"}},[_vm._v(" Disabled ")])],1),_c('td',[(item.enabled)?_c('v-chip',{attrs:{"color":"green"}},[_vm._v(" Enabled ")]):_c('v-chip',{attrs:{"color":"gray"}},[_vm._v(" Disabled ")])],1)])}),0)]},proxy:true}])})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.savePfListModification}},[_vm._v(" Confirm and Save ")]),_c('v-btn',{on:{"click":() => {
                          _vm.saveDialog = false;
                        }}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1)],1)],1)],1),_c('v-divider'),_c('v-row',{style:(_vm.treeLen !== 0 && _vm.publisher && _vm.publisher.id != null
          ? `margin-top: ${_vm.filtersRowHeight}px`
          : `margin-top: ${_vm.filtersRowEmptyHeight}px`)},[_c('v-col',[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.treeLen !== 0 && _vm.publisher && _vm.publisher.id != null),expression:"treeLen !== 0 && publisher && publisher.id != null"}]},[_c('v-treeview',{ref:"priceFloorTreeConfiguration",attrs:{"selectable":"","hoverable":"","return-object":"","items":_vm.treeItems},on:{"input":_vm.onStatusChanged},scopedSlots:_vm._u([{key:"label",fn:function({ item, leaf, selected }){return [_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-spacer'),(leaf)?_c('v-col',{attrs:{"cols":"auto"}},[(selected)?_c('v-chip',{attrs:{"color":"green"}},[_vm._v(" Enabled ")]):_c('v-chip',{attrs:{"color":"gray"}},[_vm._v(" Disabled ")])],1):_vm._e()],1)]}}]),model:{value:(_vm.selectedItemsInTree),callback:function ($$v) {_vm.selectedItemsInTree=$$v},expression:"selectedItemsInTree"}})],1),_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.treeLen === 0 && _vm.publisher && _vm.publisher.id != null),expression:"treeLen === 0 && publisher && publisher.id != null"}],staticClass:"ma-5"},[_c('v-card-title',{staticClass:"ma-4"},[_vm._v("No PF units that are active (have traffic) or present in system")])],1),_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.publisher && _vm.publisher.id == null),expression:"publisher && publisher.id == null"}],staticClass:"ma-5"},[_c('v-card-title',{staticClass:"ma-4"},[_vm._v("Please select publisher")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }