<template>
  <v-row>
    <v-card height="80vh">
      <v-card-title>
        <router-link :to="{ name: 'AuditLog' }">Audit Log</router-link>
      </v-card-title>
      <v-data-table
        height="55vh"
        dense
        :loading="getLoading"
        :headers="headers"
        :items="getAuditLogs"
        :server-items-length="getAuditLogCount"
        :items-per-page="getInfoItemsPerPage"
        :page="getInfoPage"
        :options.sync="options"
        item-key="id"
        show-expand
        fixed-header
        :footer-props="{
          'show-first-last-page': true,
          'items-per-page-options': [10, 30, 50, 100],
        }"
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">{{ item.action_message }}</td>
        </template>
      </v-data-table>
      <v-card-actions class="justify-center">
        <v-btn v-on:click="fetchStartDate()" color="primary">{{
          $t("buttons.general.refresh")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AuditLogSmall",
  computed: {
    ...mapGetters("auditLog", [
      "getLoading",
      "getInfoItemsPerPage",
      "getInfoPage",
      "getAuditLogs",
      "getAuditLogCount",
      "getAuditStartDate",
    ]),
  },
  data() {
    return {
      options: {},
      headers: [
        { text: "Date", value: "date", sortable: false },
        { text: "User", value: "user", sortable: false },
        { text: "Action", value: "action", sortable: false },
        { value: "data-table-expand" },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        if (this.getAuditStartDate !== "") {
          this.updatePaginationInfo({
            page: this.options.page,
            itemsPerPage: this.options.itemsPerPage,
          });
        }
      },
      deep: true,
    },
  },
  beforeMount() {
    this.clearData();
    this.fetchStartDate();
  },
  methods: {
    ...mapActions("auditLog", [
      "clearData",
      "updatePaginationInfo",
      "fetchStartDate",
    ]),
  },
};
</script>

<style lang="scss"></style>
