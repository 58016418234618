<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-card-title>SSP sync reports</v-card-title>
      </v-row>
      <SyncReports />
    </v-col>
  </v-row>
</template>

<script>
import SyncReports from "../../components/Settings/SSP/SyncReports";

export default {
  name: "SyncReportsPage",
  components: { SyncReports },
};
</script>

<style scoped></style>
