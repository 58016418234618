<template>
  <v-card>
    <v-card-title>Redis Live</v-card-title>
    <v-card-text>
      <v-data-table
        height="80vh"
        :items="getRedisLive"
        :loading="getLoading"
        :headers="headers"
        fixed-header
        :items-per-page="30"
        :footer-props="{
          'items-per-page-options': [30],
          'show-first-last-page': true,
        }"
      >
        <template v-slot:[`item.config`]="{ item }">
          <p class="truncate">{{ item.config }}</p>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-btn depressed icon large class="mx-4" @click="viewItem(item)">
            <v-icon small>
              visibility
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog v-model="dialog" max-width="60%">
      <v-card>
        <v-card-title>
          <v-icon class="ma-4" small>visibility</v-icon>
          Config for {{ viewedItem.name }}
        </v-card-title>
        <v-card-text>
          <v-textarea
            no-resize
            rows="20"
            class="textarea"
            outlined
            readonly
            label="JSON"
            v-model="viewedItem.config"
          />
        </v-card-text>
        <v-card-actions
          ><v-spacer />
          <v-btn color="primary" @click="closeDialog()"
            >Close</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      viewedItem: {},
      dialog: false,
      headers: [
        { text: "Name", value: "name" },
        { text: "Config", value: "config" },
        { text: "Action", value: "action" },
      ],
    };
  },
  mounted() {
    this.fetchRedisLive();
  },
  computed: {
    ...mapGetters("releasesCrusader", ["getRedisLive", "getLoading"]),
  },
  methods: {
    ...mapActions("releasesCrusader", ["fetchRedisLive"]),
    viewItem(item) {
      this.viewedItem = item;
      this.dialog = true;
    },
    closeDialog() {
      this.viewedItem = {};
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.truncate {
  width: 25vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
