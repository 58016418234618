import axios from "axios";
import store from "./store/index";

axios.defaults.baseURL = process.env.VUE_APP_SERVER_HOST;

const token = localStorage.getItem("authorization_token");
if (token) {
  axios.defaults.headers.common.Authorization = `${token}`;
}

function refreshToken() {
  const access_token = store.getters["context/authorization_token"];
  const refresh_token = store.getters["context/refresh_authorization_token"];
  return new Promise(resolve => {
    const axios_refresh_instance = axios.create();
    axios_refresh_instance
      .post("/auth/refresh_token", {
        access_token,
        refresh_token,
      })
      .then(response => {
        if (response.status !== 200) {
          resolve(false);
        }
        store.commit(
          "context/setAuthorizationToken",
          response.data.Authorization
        );
        store.commit(
          "context/setRefreshAuthorizationToken",
          response.data.RefreshAuthorization
        );
        resolve(true);
      })
      .catch(() => {
        resolve(false);
      });
  });
}

async function refreshTokenHandler() {
  if (!store.getters["context/isAuthorizationToken"]) {
    return false;
  }
  if (store.getters["context/getRefreshingToken"] != null) {
    return await store.getters["context/getRefreshingToken"];
  }
  const refreshPromise = refreshToken();
  store.commit("context/setRefreshingToken", refreshPromise);
  const isRefreshResultSuccess = await refreshPromise;
  store.commit("context/setRefreshingToken", null);
  return isRefreshResultSuccess;
}

axios.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    if (error.response.status === 401) {
      const result = await refreshTokenHandler();
      if (result) {
        error.config.headers["Authorization"] =
          store.getters["context/authorization_token"];
        return axios.request(error.config);
      } else {
        store.dispatch("context/logout");
      }
    }
    return Promise.reject(error);
  }
);

export default axios;
