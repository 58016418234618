import axios from "axios";

const store = {
  namespaced: true,

  state: {
    loading: true,
    info: {
      itemsPerPage: 30,
      page: 1,
      sort_by: "",
      logCount: 0,
      date: "",
      publisher_id: "",
      invoice_type: "",
      DPA_type: "",
      status: "",
      payment_due: "",
    },
    paymentItems: [],
    paymentStatus: ["issued", "paid", "overdue"],
    dpaTypes: ["publisher", "optidigital"],
    overduePayments: [],
  },

  getters: {
    getLoading: state => {
      return state.loading;
    },
    getPayments: state => {
      return state.paymentItems;
    },
    getInfoItemsPerPage: state => {
      return state.info.itemsPerPage;
    },
    getLogCount: state => {
      return state.info.logCount;
    },
    getPaymentStatus: state => {
      return state.paymentStatus;
    },
    getDpaType: state => {
      return state.dpaTypes;
    },
    getOverduePayments: state => {
      return state.overduePayments;
    },
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setPaymentsList(state, payload) {
      state.paymentItems = payload;
    },
    setPaginationInfo(state, payload) {
      state.info.page = payload.page;
      state.info.itemsPerPage = payload.itemsPerPage;
      state.info.sort_by = `${payload.sortDesc ? "-" : ""}${
        payload.sortBy ? payload.sortBy : ""
      }`;
    },
    setLogsCount(state, payload) {
      state.info.logCount = payload;
    },
    setPublisherName(state, payload) {
      state.info.publisher_id = payload;
    },
    setInvoiceType(state, payload) {
      state.info.invoice_type = payload;
    },
    setDpaType(state, payload) {
      state.info.DPA_type = payload;
    },
    setPaymentStatus(state, payload) {
      state.info.status = payload;
    },
    setDateOfInvoice(state, payload) {
      state.info.date = payload;
    },
    setPaymentDue(state, payload) {
      state.info.payment_due = payload;
    },
    setOverduePayments(state, payload) {
      state.overduePayments = payload;
    },
  },

  actions: {
    async fetchPayments({ commit, state }) {
      commit("setLoading", true);
      return new Promise(resolve => {
        let queryParams = {};
        for (const key in state.info) {
          if (state.info[key]) {
            queryParams[key] = state.info[key];
          }
        }
        axios
          .get("/financial_payment/publisher/payments", {
            params: queryParams,
          })
          .then(response => {
            commit("setPaymentsList", response.data.items);
            commit("setLogsCount", response.data.items_count);
            commit("setLoading", false);
          });
        resolve();
      });
    },
    async updateStatus({ commit }, data) {
      commit("setLoading", true);
      const payload = { payment_id: data.id, payment_date: data.date };
      return new Promise(resolve => {
        axios
          .put("/financial_payment/publisher/payment/paid", payload)
          .then(() => {
            commit("setLoading", false);
            resolve();
          });
      });
    },
    async updatePaginationInfo({ commit, dispatch }, data) {
      return new Promise(resolve => {
        commit("setLoading", true);
        commit("setPaginationInfo", {
          page: data.page,
          itemsPerPage: data.itemsPerPage,
          sortBy: data.sortBy,
          sortDesc: data.sortDesc,
        });
        dispatch("fetchPayments");
        resolve();
      });
    },
    async fetchFiltering({ commit, dispatch }, { name, data }) {
      return new Promise(resolve => {
        commit("setLoading", true);
        commit(name, data);
        dispatch("fetchPayments");
        resolve();
      });
    },
  },
};

export default store;
