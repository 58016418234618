<template>
  <v-card>
    <v-card-title>Released {{ inventoryType }}</v-card-title>
    <v-card-text>
      <v-data-table
        height="80vh"
        :headers="getHeaders"
        :items="tableItems"
        :loading="getLoading"
        :search="search"
        fixed-header
        :items-per-page="30"
        sort-by="date"
        sort-desc
        :footer-props="{
          'items-per-page-options': [30],
          'show-first-last-page': true,
        }"
      >
        <template v-slot:top>
          <v-row><v-divider /></v-row>
          <v-row align="center" justify="space-between">
            <v-col cols="6">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('buttons.general.search')"
                single-line
                hide-details
              />
            </v-col>
          </v-row>
          <v-row><v-divider /></v-row>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-btn depressed icon large class="mx-4" @click="viewItem(item)">
            <v-icon small>
              visibility
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.config`]="{ item }">
          <p class="truncate">{{ item.config }}</p>
        </template>
        <template v-slot:[`item.sr`]="{ item }">
          <p class="truncate">{{ item.sr }}</p>
        </template>
      </v-data-table>
      <v-dialog v-model="dialog" max-width="60%">
        <v-card>
          <v-card-title>
            <v-icon class="ma-4" small>visibility</v-icon>
            {{ inventoryType == "AMP" ? "Store Request" : "Config" }} for
            {{ inventoryType == "AMP" ? viewedItem.sr_id : viewedItem.name }}
          </v-card-title>
          <v-card-text>
            <v-textarea
              no-resize
              rows="20"
              class="textarea"
              outlined
              readonly
              label="JSON"
              v-model="getEditedItemConfig"
            />
          </v-card-text>
          <v-card-actions
            ><v-spacer />
            <v-btn color="primary" @click="closeDialog()"
              >Close</v-btn
            ></v-card-actions
          >
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    inventoryType: {
      type: String,
      requaried: true,
    },
  },
  data() {
    return {
      dialog: false,
      search: "",
      tableItems: [],
      viewedItem: {},
      headers_amp: [
        { text: "ID", value: "id" },
        {
          text: "Date",
          value: "date",
          // sort: (a, b) => new Date(a) > new Date(b),
        },
        { text: "Config ID", value: "config_id" },
        { text: "SR ID", value: "sr_id" },
        // { text: "Datetime", value: "date" },
        { text: "Revision", value: "revision" },
        { text: "Store Request", value: "sr" },
        { text: "Action", value: "action" },
      ],
      headers_web: [
        { text: "ID", value: "id" },
        { text: "Date", value: "date" },
        { text: "Prerelease", value: "prerelease" },
        { text: "Name", value: "name" },
        // { text: "Datetime", value: "date" },
        { text: "Revision", value: "revision" },
        { text: "Config", value: "config" },
        { text: "Action", value: "action" },
      ],
    };
  },
  watch: {
    inventoryType() {
      this.fetchComponentData();
    },
  },
  async mounted() {
    await this.fetchComponentData();
  },
  computed: {
    ...mapGetters("releasesCrusader", [
      "getAmpStoreRequests",
      "getWebConfigs",
      "getLoading",
    ]),
    getEditedItemConfig() {
      if (this.inventoryType === "WEB") {
        return this.viewedItem.config;
      } else {
        return this.viewedItem.sr;
      }
    },
    getHeaders() {
      if (this.inventoryType === "WEB") {
        return this.headers_web;
      } else {
        return this.headers_amp;
      }
    },
    isTypeWeb() {
      return this.inventoryType === "WEB";
    },
  },
  methods: {
    ...mapActions("releasesCrusader", [
      "fetchWebConfigs",
      "fetchAmpStoreRequests",
    ]),
    async fetchComponentData() {
      if (this.inventoryType === "WEB") {
        await this.fetchWebConfigs();
        this.tableItems = this.getWebConfigs;
      } else {
        await this.fetchAmpStoreRequests();
        this.tableItems = this.getAmpStoreRequests;
      }
    },
    viewItem(item) {
      this.viewedItem = item;
      this.dialog = true;
    },
    closeDialog() {
      this.viewedItem = {};
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.truncate {
  width: 25vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
