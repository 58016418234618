<template>
  <v-col>
    <v-app-bar app fixed class="stickyTop">
      <v-row justify="space-between" align="center">
        <v-col cols="auto">
          <v-card-title>PFUnits Manager</v-card-title>
        </v-col>
        <v-spacer />
        <v-col cols="auto">
          <v-btn
            color="orange"
            @click="
              () => {
                manualReleaseConfirmationDialog = true;
              }
            "
          >
            Manual Deploy for all Publishers
          </v-btn>
          <ConfirmationDialog
            :confirmation-dialog="manualReleaseConfirmationDialog"
            :on-confirm="onManualRelease"
            :on-close="
              () => {
                manualReleaseConfirmationDialog = false;
              }
            "
            msg="Are you sure you want to do manual release?"
          />
        </v-col>
      </v-row>
      <v-row align="center" ref="filterRow">
        <v-col>
          <v-autocomplete
            :items="getPublishersList"
            return-object
            item-text="name"
            v-model="publisher"
            label="Publisher"
            @change="onPublisherSelected"
          />
        </v-col>
        <v-divider
          v-show="treeLen !== 0 && publisher && publisher.id != null"
          vertical
        />
        <v-col v-bind:key="item.id" v-for="item in getPublisherMapping.mapping">
          <v-autocomplete
            :items="uniqueElementsObject[item.key]"
            :label="item.label"
            :value="filters[item.key]"
            multiple
            @change="
              value => {
                filters[item.key] = value;
                generateTree();
              }
            "
          >
            <template v-slot:prepend-item>
              <SelectAllCheckbox
                :element="item.key"
                :filters="filters[item.key]"
                :all="uniqueElementsObject[item.key]"
                @toggle="selectAll"
              />
            </template>
            <template v-slot:append-outer>
              <v-icon
                icon
                v-on:click="
                  () => {
                    filters[item.key] = '';
                    generateTree();
                  }
                "
              >
                delete
              </v-icon>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row v-show="treeLen !== 0 && publisher && publisher.id != null">
        <v-card-actions>
          <v-row align="center" justify="start">
            <v-col>
              <v-btn
                color="green"
                :disabled="changedPfUnitsList.length === 0"
                @click="onSave"
                class="my-4"
              >
                Save
              </v-btn>
              <v-btn
                color="blue"
                @click="showAllElementsInTree"
                class="ml-4 my-4"
              >
                {{ expandedTxt }}
              </v-btn>
              <v-dialog v-model="saveDialog">
                <v-card>
                  <v-card-title>
                    Items to save
                  </v-card-title>
                  <v-card-text>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              Path
                            </th>
                            <th class="text-left">
                              Original Status
                            </th>
                            <th class="text-left">
                              New Status
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in changedPfUnitsList" :key="item.id">
                            <td>{{ generatePath(item) }}</td>
                            <td>
                              <v-chip
                                v-if="item.originalEnabledValue"
                                color="green"
                              >
                                Enabled
                              </v-chip>
                              <v-chip v-else color="gray">
                                Disabled
                              </v-chip>
                            </td>
                            <td>
                              <v-chip v-if="item.enabled" color="green">
                                Enabled
                              </v-chip>
                              <v-chip v-else color="gray">
                                Disabled
                              </v-chip>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" @click="savePfListModification">
                      Confirm and Save
                    </v-btn>
                    <v-btn
                      @click="
                        () => {
                          saveDialog = false;
                        }
                      "
                    >
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-row>
    </v-app-bar>
    <v-divider />
    <v-row
      :style="
        treeLen !== 0 && publisher && publisher.id != null
          ? `margin-top: ${filtersRowHeight}px`
          : `margin-top: ${filtersRowEmptyHeight}px`
      "
    >
      <v-col>
        <v-card v-show="treeLen !== 0 && publisher && publisher.id != null">
          <v-treeview
            ref="priceFloorTreeConfiguration"
            selectable
            hoverable
            return-object
            v-model="selectedItemsInTree"
            :items="treeItems"
            @input="onStatusChanged"
          >
            <template v-slot:label="{ item, leaf, selected }">
              <v-row align="center">
                <v-col cols="auto">
                  {{ item.name }}
                </v-col>
                <v-spacer />
                <v-col v-if="leaf" cols="auto">
                  <v-chip v-if="selected" color="green">
                    Enabled
                  </v-chip>
                  <v-chip v-else color="gray">
                    Disabled
                  </v-chip>
                </v-col>
              </v-row>
            </template>
          </v-treeview>
        </v-card>
        <v-card
          v-show="treeLen === 0 && publisher && publisher.id != null"
          class="ma-5"
        >
          <v-card-title class="ma-4"
            >No PF units that are active (have traffic) or present in
            system</v-card-title
          >
        </v-card>
        <v-card v-show="publisher && publisher.id == null" class="ma-5">
          <v-card-title class="ma-4">Please select publisher</v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import ConfirmationDialog from "@/components/Helpers/ConfirmationDialog";
import SelectAllCheckbox from "./SelectAllCheckbox.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ConfirmationDialog,
    SelectAllCheckbox,
  },
  created() {
    this.clearSwitcherData();
    this.fetchPublishersList();
  },
  computed: {
    ...mapGetters("priceFloorConfiguration", [
      "getLoading",
      "getPublishersList",
      "getSelectedPublisher",
      "getPfUnitList",
      "getPublisherMapping",
      "getEnableOptions",
    ]),
    changedPfUnitsList() {
      const itemsList = [];
      this.getPfUnitList.forEach(element => {
        if (element.enabled !== element.originalEnabledValue) {
          itemsList.push(element);
        }
      });
      return itemsList;
    },
    generateFiltersWrapper() {
      this.getPublisherMapping["mapping"].forEach((item, i) => {
        let obj = {
          name: item.key,
          level: i,
          value: [],
        };
        this.filtersWrapper.push(obj);
      });
      return this.filtersWrapper;
    },
  },
  data: () => ({
    publisher: {},
    keys: [],
    filters: {},
    selectedItemsInTree: [],
    prevSelectedItemsInTree: [],
    isSelectedItemsInTreeInputEnabled: false,
    treeItems: [],
    itemsArrayFilter: [],
    filteredPfUnits: [],
    saveDialog: false,
    uniqueElementsObject: {},
    treeLen: 0,
    expaned: false,
    expandedTxt: "Expand all",
    manualReleaseConfirmationDialog: false,
    filtersRowHeight: 200,
    filtersRowEmptyHeight: 100,
    filtersWrapper: [],
  }),
  watch: {
    getPublishersList() {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const queryStringParams = Object.fromEntries(urlSearchParams.entries());
      if (Object.entries(queryStringParams).length !== 0) {
        this.getPublishersList.forEach(item => {
          if (item.name === queryStringParams.publisher) {
            this.publisher = item;
            this.onPublisherSelected(item);
          }
        });
      }
    },
  },
  methods: {
    ...mapActions("priceFloorConfiguration", [
      "fetchPublishersList",
      "selectPublisher",
      "savePfUnits",
      "clearSwitcherData",
      "doManualRelease",
    ]),
    filterItems(array, filters) {
      filters.forEach(filter => {
        array = array.filter(item => {
          return item[filter[0]] === filter[1];
        });
      });
      return array;
    },
    filterItemsMultiple(array, filters) {
      this.generateFiltersWrapper;
      this.filtersWrapper.forEach(arr => {
        arr.value = [];
      });

      filters.forEach(filter => {
        let result = this.filtersWrapper.filter(obj => {
          return obj.name === filter[0];
        });
        let currentLevel = result[0].level;
        let previousLevel;
        this.filtersWrapper.forEach((arr, index) => {
          if (arr.value.length && index !== currentLevel) {
            previousLevel = arr.level;
          }
        });

        if (previousLevel >= 0) {
          this.filtersWrapper[currentLevel].value = this.filtersWrapper[
            currentLevel
          ].value.concat(
            this.filtersWrapper[previousLevel].value.filter(item => {
              return item[filter[0]] === filter[1];
            })
          );
        } else {
          this.filtersWrapper[currentLevel].value = this.filtersWrapper[
            currentLevel
          ].value.concat(
            array.filter(item => {
              return item[filter[0]] === filter[1];
            })
          );
        }
      });
      let result = this.filtersWrapper.filter(obj => {
        return obj.name === filters[filters.length - 1][0];
      });

      return result[0].value;
    },
    async onPublisherSelected(publisher) {
      await this.selectPublisher(publisher);
      const filterObject = {};
      const keys = [];

      this.getPublisherMapping.mapping.forEach(element => {
        if (this.filters && Object.keys(this.filters).length > 0) {
          if (typeof this.filters[element.key] === "undefined") {
            this.filters[element.key] = "";
          }
          filterObject[element.key] = this.filters[element.key];
        } else {
          filterObject[element.key] = "";
        }
        keys.push(element.key);
      });

      this.filters = filterObject;
      this.keys = keys;
      this.selectedItemsInTree = [];
      this.generateUniqueElementObj();

      this.generateTree();
    },
    async savePfListModification() {
      await this.savePfUnits(this.changedPfUnitsList);
      this.saveDialog = false;
      await this.onPublisherSelected(this.publisher);
    },
    generateUniqueElementObj() {
      const uniqueElementsObject = {};
      this.keys.forEach(key => {
        let arr = [];
        this.getPfUnitList.forEach(element => {
          arr.push(element[key]);
        });
        uniqueElementsObject[key] = [...new Set(arr)];
      });

      Object.keys(uniqueElementsObject).forEach(item => {
        if (this.filters[item].length) {
          let arr = [];
          uniqueElementsObject[item].map(el => {
            if (this.filters[item].includes(el)) {
              arr.push(el);
            }
          });
          if (arr.length === 0) {
            this.filters[item] = "";
          } else {
            this.filters[item] = arr;
          }
        }
      });

      if (uniqueElementsObject["enabled"]) {
        uniqueElementsObject["enabled"] = uniqueElementsObject["enabled"].map(
          (item, index) => {
            return this.getEnableOptions[index];
          }
        );
      }

      this.uniqueElementsObject = uniqueElementsObject;
    },
    generateTree() {
      this.isSelectedItemsInTreeInputEnabled = false;
      const that = this;
      const keysOptions = this.keys;
      let tree = [];
      const selectedItemsInTree = [];

      this.itemsArrayFilter = [];

      this.keys.forEach(key => {
        if (this.filters[key].toString().length) {
          this.filters[key].forEach(item => {
            this.itemsArrayFilter.push([key, item]);
          });
        }
      });

      if (this.itemsArrayFilter.length === 0) {
        this.filteredPfUnits = this.getPfUnitList;
      } else {
        this.filteredPfUnits = this.filterItemsMultiple(
          this.getPfUnitList,
          this.itemsArrayFilter
        );
      }
      function generateChild(array, filters, depth, path) {
        const return_array = [];

        let unique_elements = [];
        array.forEach(element => {
          unique_elements.push(element[keysOptions[depth]]);
        });

        unique_elements = [...new Set(unique_elements)];
        if (unique_elements[0] !== true || unique_elements[0] !== false) {
          unique_elements.forEach(element => {
            const filter = [...filters, [keysOptions[depth], element]];
            const id = path + "/" + element;
            if (depth === keysOptions.length - 2) {
              const itemObj = that.filterItems(array, filter)[0];
              const leafObj = {
                id,
                name: element,
                filter,
                enabled: itemObj.enabled,
              };
              if (itemObj.enabled) {
                selectedItemsInTree.push(leafObj);
              }
              return_array.push(leafObj);
            } else {
              const branchObj = {
                id,
                name: element,
                filter,
                children: generateChild(
                  that.filterItems(array, [
                    ...filters,
                    [keysOptions[depth], element],
                  ]),
                  [...filters, [keysOptions[depth], element]],
                  depth + 1,
                  id
                ),
              };
              return_array.push(branchObj);
            }
          });
          return return_array;
        }
      }
      tree = generateChild(this.filteredPfUnits, [], 0, "");
      this.treeLen = tree.length;
      this.treeItems = [
        {
          id: -1,
          name: this.publisher ? this.publisher.name : "",
          filter: [],
          children: tree,
        },
      ];
      this.$nextTick(() => {
        this.selectedItemsInTree = [...selectedItemsInTree];
        this.prevSelectedItemsInTree = [...this.selectedItemsInTree];
        this.isSelectedItemsInTreeInputEnabled = true;
      });

      setTimeout(() => {
        this.filtersRowHeight = 150 + this.$refs.filterRow.clientHeight;
        this.filtersRowEmptyHeight = 20 + this.$refs.filterRow.clientHeight;
      }, 100);
    },
    onStatusChanged() {
      if (this.isSelectedItemsInTreeInputEnabled) {
        const toEnabled = this.selectedItemsInTree.filter(
          x => !this.prevSelectedItemsInTree.includes(x)
        );

        const toDisable = this.prevSelectedItemsInTree.filter(
          x => !this.selectedItemsInTree.includes(x)
        );

        toEnabled.forEach(treeItem => {
          const listItem = this.filterItems(
            this.filteredPfUnits,
            treeItem.filter
          )[0];
          listItem.enabled = true;
        });

        toDisable.forEach(treeItem => {
          const listItem = this.filterItems(
            this.filteredPfUnits,
            treeItem.filter
          )[0];
          listItem.enabled = false;
        });

        this.prevSelectedItemsInTree = [...this.selectedItemsInTree];
      }
    },
    generatePath(item) {
      let path = "";
      this.keys.forEach((key, index) => {
        if (index === 0) {
          path += item[key];
        } else if (this.keys.length === index + 1) {
          path += "";
        } else {
          path += "/" + item[key];
        }
      });
      return path;
    },
    onSave() {
      this.saveDialog = true;
    },
    showAllElementsInTree() {
      if (this.expanded) {
        this.$refs.priceFloorTreeConfiguration.updateAll(false);
      } else {
        this.$refs.priceFloorTreeConfiguration.updateAll(true);
      }
      this.expanded = !this.expanded;
      this.expandedTxt = this.expanded ? "Collapse all" : "Expand all";
    },
    onManualRelease() {
      this.doManualRelease();
      this.manualReleaseConfirmationDialog = false;
    },
    selectAll(element) {
      this.$nextTick(() => {
        if (
          this.uniqueElementsObject[element].length ===
          this.filters[element].length
        ) {
          this.filters[element] = "";
          this.generateTree();
        } else {
          this.filters[element] = this.uniqueElementsObject[element];
          this.generateTree();
        }
      });
    },
  },
};
</script>

<style scoped>
>>> .v-toolbar__content {
  display: grid;
  height: auto !important;
  background-color: #fff;
  padding-top: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
>>> .v-select__selections {
  max-height: 100px;
  overflow: hidden;
}
</style>
