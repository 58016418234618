<template>
  <v-tooltip
    bottom
    persistent
    v-model="should_open"
    :opacity="100"
    :open-on-hover="false"
    :open-on-click="false"
  >
    <template v-slot:activator="{ on }">
      <span>
        {{ content }}
      </span>
      <v-icon
        v-on="on"
        light
        small
        class="help-mark"
        end
        @mouseleave="delayed_close"
        @mouseover="stop_delayed_close"
        @click="make_persistent"
        :active="should_open"
        >{{ icon }}</v-icon
      >
    </template>
    <v-card
      @mouseleave="delayed_close"
      @mouseover="stop_delayed_close"
      class="ma-0 pa-0"
    >
      <v-card-text>
        <span
          v-html="
            description.trim() ||
              'description not available: work in progress...'
          "
        />
      </v-card-text>
    </v-card>
  </v-tooltip>
</template>
<script>
import { eventBus } from "@/main";

export default {
  name: "IconTooltip",
  props: {
    icon: {
      type: String,
      default: "mdi-help-circle-outline",
    },
    content: {
      type: String,
    },
    description: {
      type: String,
      default: "description not available: work in progress..",
    },
  },
  mounted() {
    eventBus.$on("close-other-tooltip", () => {
      this.open_tooltip = false;
      this.force_persistence = false;
    });
  },
  data() {
    return {
      open_tooltip: false,
      force_persistence: false,
    };
  },
  computed: {
    should_open: {
      get() {
        return this.open_tooltip || this.force_persistence;
      },
      set(value) {
        this.open_tooltip = value;
      },
    },
  },
  methods: {
    make_persistent(event) {
      event.preventDefault();
      this.force_persistence = !this.force_persistence;
      if (!this.force_persistence) {
        this.open_tooltip = false;
      }
    },
    delayed_close() {
      this.close_timeout = setTimeout(() => (this.open_tooltip = false), 500);
    },
    stop_delayed_close() {
      eventBus.$emit("close-other-tooltip");
      this.open_tooltip = true; // order is important since the event will close all tooltips
      if (this.close_timeout) {
        clearTimeout(this.close_timeout);
      }
    },
  },
};
</script>
<style scoped>
span {
  margin-right: 2px;
}
.v-icon {
  color: #666666;
}
.v-icon[active="true"] {
  color: #448aff !important;
}
.v-icon.v-icon::after {
  background-color: transparent;
  color: #448aff;
}

.v-icon:hover,
.v-icon:hover {
  color: #448aff !important;
}
.v-tooltip__content {
  pointer-events: auto;
  background-color: transparent;
}
.v-tooltip__content.menuable__content__active {
  opacity: 100%;
}
.v-card__text {
  color: black;
}
</style>
