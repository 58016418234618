<template>
  <div class="pt-10">
    <v-card class="mx-auto" max-width="650">
      <v-img height="200px" :src="odLogo" />
      <v-card-title>
        Dashboard
      </v-card-title>
      <v-card-subtitle>
        Login to see more
      </v-card-subtitle>
      <v-card-actions>
        <div ref="googleLoginBtn" />
        <v-spacer />
        <v-btn @click="show = !show" icon>
          <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
        </v-btn>
      </v-card-actions>
      <v-expand-transition>
        <div v-show="show">
          <v-divider />
          <v-card-text>
            This is portal to manage configuration and review own reports. It
            requires to have G-mail account accepted by Opti Digital support.
          </v-card-text>
        </div>
      </v-expand-transition>
    </v-card>
    <v-spacer class="ma-4" />
    <v-alert
      type="error"
      v-show="showAlertError"
      class="mx-auto pa-4"
      max-width="650"
    >
      Error: Connection to server refused.
    </v-alert>
    <v-alert
      type="warning"
      v-show="showAlertNoAccess"
      class="mx-auto pa-4"
      max-width="650"
    >
      Error: Access denied.
    </v-alert>
  </div>
</template>

<!--<router-link to="/login">Go to login</router-link>-->

<script>
import colors from "vuetify/lib/util/colors";
// import axios from "axios";
// import { createNamespacedHelpers } from 'vuex';
import { mapActions } from "vuex";

const odLogo = require("../../public/logo-optidigital_versionhorizontal_fullcolor-1.png");

export default {
  name: "Login",
  data() {
    return {
      odLogo,
      show: false,
      googleLoginColor: colors.blue.lighten1,
      isInit: false,
      showAlertError: false,
      showAlertNoAccess: false,
    };
  },
  methods: {
    ...mapActions("context", ["loginGoogle"]),
    handleCredentialResponse(response) {
      this.loginGoogle(response.credential).then(res => {
        if (res[1] === true) {
          this.$router.push({ name: "Home" });
        } else {
          this.showAlertNoAccess = true;
        }
      });
    },
  },
  mounted() {
    const gClientId =
      "123391447784-9ebr33205qtm7pt4v85eu9gphjgr57ng.apps.googleusercontent.com";
    window.onload = () => {
      window.google.accounts.id.initialize({
        client_id: gClientId,
        callback: this.handleCredentialResponse,
        auto_select: true,
      });
      window.google.accounts.id.renderButton(this.$refs.googleLoginBtn, {
        text: "signin",
        size: "large",
        width: 200,
        theme: "outline",
      });
    };
  },
  computed: {},
  beforeCreate() {
    const authorizationToken = localStorage.getItem("authorization_token");
    if (authorizationToken) {
      this.$router.push({ name: "Home" });
    }
  },

  created() {},
};
</script>
