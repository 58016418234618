import axios from "axios";

const store = {
  namespaced: true,

  state: {
    loading: true,
    info: {
      startDate: "",
      endDate: "",
      itemsPerPage: 30,
      page: 1,
      publishers_filter_list: [],
    },
    logCount: 0,
    pfPublishers: [],
    calculatorVersions: [],
    stats: {},
    currencies: {},
    publisher_currency: {},
    publisher_data: {},
    publisher_countries: [],
    countries: [],
  },

  getters: {
    getLoading: state => {
      return state.loading;
    },
    getPfPublishers: state => {
      return state.pfPublishers;
    },
    getInfoItemsPerPage: state => {
      return state.info.itemsPerPage;
    },
    getLogCount: state => {
      return state.logCount;
    },
    getCalculatorVersions: state => {
      return [{ id: "", code: "Default" }, ...state.calculatorVersions];
    },
    getStats: state => {
      return state.stats;
    },
    getAllCurrencies: state => {
      return state.currencies;
    },
    getPublisherCurrency: state => {
      return state.publisher_currency;
    },
    getPublisherData: state => {
      return state.publisher_data;
    },
    getAllCountries: state => {
      return state.countries;
    },
    getPublisherCountries: state => {
      return state.publisher_countries;
    },
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setPfPublishers(state, payload) {
      state.pfPublishers = payload.map(pfPub => {
        if (pfPub.publisher_calculator_version === null) {
          pfPub.publisher_calculator_version = "";
        }
        return pfPub;
      });
    },
    setSearchData(state, payload) {
      state.info.date_filter_start = payload.length
        ? payload[0].replace(/-/g, "")
        : "";
      state.info.date_filter_end = payload.length
        ? payload[1].replace(/-/g, "")
        : "";
    },
    setPaginationInfo(state, payload) {
      state.info.page = payload.page;
      state.info.itemsPerPage = payload.itemsPerPage;
    },
    setLogsCount(state, payload) {
      state.logCount = payload;
    },
    setPublisherName(state, payload) {
      state.info.publishers_filter_list = payload;
    },
    setCalculatorVersions(state, payload) {
      payload.map(item => {
        let obj = { id: item, code: item };
        state.calculatorVersions.push(obj);
      });
    },
    setStats(state, payload) {
      state.stats = payload;
    },
    setAllCurrencies(state, payload) {
      state.currencies = payload;
    },
    setPublisherCurrency(state, payload) {
      state.publisher_currency = payload;
    },
    setPublisherData(state, payload) {
      state.publisher_data = payload;
    },
    setAllCountries(state, payload) {
      state.countries = payload;
    },
    setPublisherCountries(state, payload) {
      state.publisher_countries = payload;
    },
  },

  actions: {
    async fetchPfPublishers({ commit, state }) {
      commit("setLoading", true);
      return new Promise(resolve => {
        let queryParams = new URLSearchParams();
        for (const key in state.info) {
          if (state.info[key] && key !== "publishers_filter_list") {
            queryParams.append(key, state.info[key]);
          }
          if (Array.isArray(state.info[key])) {
            state.info[key].map(i => {
              queryParams.append(key, i);
            });
          }
        }
        axios
          .get("/price_floor/configuration/publisher/all", {
            params: queryParams,
          })
          .then(response => {
            commit("setPfPublishers", response.data);
            commit("setLogsCount", response.data.items_count);
            commit("setLoading", false);
          });
        resolve();
      });
    },
    async setSearchDataAction({ commit, dispatch }, data) {
      return new Promise(resolve => {
        commit("setLoading", true);
        commit("setSearchData", data);
        dispatch("fetchPfPublishers");
        resolve();
      });
    },
    async updatePaginationInfo({ commit, dispatch }, data) {
      return new Promise(resolve => {
        commit("setLoading", true);
        commit("setPaginationInfo", {
          page: data.page,
          itemsPerPage: data.itemsPerPage,
        });
        dispatch("fetchPfPublishers");
        resolve();
      });
    },
    async fetchFilter({ commit, dispatch }, { data, name }) {
      return new Promise(resolve => {
        commit("setLoading", true);
        commit(name, data);
        dispatch("fetchPfPublishers");
        resolve();
      });
    },
    async updatePublisher({ commit, dispatch }, publisher) {
      commit("setLoading", true);
      return new Promise(resolve => {
        axios
          .put(
            `/price_floor/configuration/publisher/${publisher.id}`,
            publisher
          )
          .then(() => {
            dispatch("fetchPfPublishers");
          });
        resolve();
      });
    },
    async fetchCalculatorVersions({ commit }) {
      commit("setLoading", true);
      return new Promise(resolve => {
        axios
          .get("/price_floor/configuration/settings/calculator_versions")
          .then(response => {
            commit("setCalculatorVersions", response.data);
            commit("setLoading", false);
          });
        resolve();
      });
    },
    async fetchAllCurrencies({ commit }) {
      commit("setLoading", true);
      return new Promise(resolve => {
        axios
          .get("/price_floor/configuration/settings/currency/all")
          .then(response => {
            commit("setAllCurrencies", response.data);
            commit("setLoading", false);
          });
        resolve();
      });
    },
    async fetchCurrencyForPublisher({ commit }, networkId) {
      commit("setLoading", true);
      return new Promise(resolve => {
        axios
          .get(`/price_floor/configuration/publisher/${networkId}/currency`)
          .then(response => {
            commit("setPublisherCurrency", response.data);
            commit("setLoading", false);
          });
        resolve();
      });
    },
    async updatePublisherCurrency({ commit, dispatch }, currency) {
      commit("setLoading", true);
      return new Promise(resolve => {
        axios
          .put(
            `/price_floor/configuration/publisher/${currency.network_id}/currency`,
            {
              gam_currency_id: currency.gam_currency_id,
              prebid_currency_id: currency.prebid_currency_id,
            }
          )
          .then(() => {
            dispatch("fetchPfPublishers");
          });
        resolve();
      });
    },
    async fetchDataForPublisher({ commit }, networkId) {
      commit("setLoading", true);
      return new Promise(resolve => {
        axios
          .get(`/price_floor/configuration/publisher/${networkId}/profile_info`)
          .then(response => {
            commit("setPublisherData", response.data);
            commit("setLoading", false);
          });
        resolve();
      });
    },
    async fetchAllCountries({ commit }) {
      commit("setLoading", true);
      return new Promise(resolve => {
        axios.get(`/publisher/countries`).then(response => {
          commit("setAllCountries", response.data);
          commit("setLoading", false);
        });
        resolve();
      });
    },
    async fetchPublisherCountries({ commit }, publisher_code) {
      commit("setLoading", true);
      commit("setPublisherCountries", {
        network_id: null,
        publisher_code,
        countries: [],
      });
      return new Promise(resolve => {
        axios
          .get(`/publisher/${publisher_code}/pfcReporter/countries`)
          .then(response => {
            commit("setPublisherCountries", response.data);
            commit("setLoading", false);
          });
        resolve();
      });
    },
    async updatePublisherCountries({ commit }, publisher_countries) {
      commit("setLoading", true);
      return new Promise(resolve => {
        axios.put(
          `/publisher/${publisher_countries.publisher_code}/pfcReporter/countries`,
          publisher_countries
        );
        resolve();
      });
    },
  },
};

export default store;
