<template>
  <div class="main-container">
    <v-row>
      <v-card-title>Diff Tool</v-card-title>
      <v-spacer />
      <v-col cols="auto">
        <v-btn color="primary" @click="goBackToTM">
          {{ $t("common.button.manage_tags") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="auto">
        <v-form v-model="revisionAForm">
          <v-col cols="12">
            <v-row align="center" justify="start" dense>
              <v-col cols="auto">
                <v-autocomplete
                  v-model="siteAutocompleteModelA"
                  :readonly="disable_revision_a"
                  :items="getSiteEntities"
                  item-text="name"
                  return-object
                  label="Site A"
                  :rules="baseValidation.required"
                  dense
                />
              </v-col>
              <v-col cols="auto">
                <v-select
                  v-model="inventoryTypeSelectModelA"
                  :readonly="disable_revision_a"
                  :items="getInventoryTypeList()"
                  :rules="baseValidation.required"
                  label="Inventory Type"
                  dense
                />
              </v-col>
              <v-col cols="auto">
                <v-autocomplete
                  :items="revisionSelectListA"
                  v-model="revisionSelectModelA"
                  :readonly="disable_revision_a"
                  label="Revision A"
                  dense
                  return-object
                >
                  <template v-slot:selection="data">
                    <v-container dense class="ma-0 pa-0">
                      <v-row dense>
                        <v-col
                          >{{ data.item.number }}.{{ data.item.name }}</v-col
                        >
                        <v-chip
                          small
                          :color="mapStatusColor(data.item.status || '')"
                          >{{ data.item.status }}</v-chip
                        >
                      </v-row>
                    </v-container>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-form>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="auto">
        <v-form v-model="revisionBForm">
          <v-col cols="12">
            <v-row align="center" justify="start" dense>
              <v-col cols="auto">
                <v-autocomplete
                  v-model="siteAutocompleteModelB"
                  :items="getSiteEntities"
                  item-text="name"
                  return-object
                  label="Site B"
                  :rules="baseValidation.required"
                  @change="onSiteSelect"
                  dense
                />
              </v-col>
              <v-col cols="auto">
                <v-autocomplete
                  :items="revisionSelectListB"
                  v-model="revisionSelectModelB"
                  label="Revision B"
                  @change="onRevisionSelect"
                  dense
                  return-object
                >
                  <template v-slot:selection="data">
                    <v-container dense class="ma-0 pa-0">
                      <v-row dense>
                        <v-col
                          >{{ data.item.number }}.{{ data.item.name }}</v-col
                        >
                        <v-chip
                          small
                          :color="mapStatusColor(data.item.status)"
                          >{{ data.item.status }}</v-chip
                        >
                      </v-row>
                    </v-container>
                  </template>
                  <template v-slot:item="data">
                    <v-container dense class="ma-0 pa-0">
                      <v-row dense>
                        <v-col
                          >{{ data.item.number || 0 }}.{{
                            data.item.name || ""
                          }}</v-col
                        >
                        <v-chip
                          small
                          :color="mapStatusColor(data.item.status || 'empty')"
                          >{{ data.item.status || "empty" }}</v-chip
                        ></v-row
                      >
                    </v-container>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="auto">
                <v-switch v-model="folding" label="Colapse no-diff" />
              </v-col>
            </v-row>
          </v-col>
        </v-form>
      </v-col>
    </v-row>
    <v-row dense>
      <div class="containerif">
        <iframe
          ref="diffIframe"
          class="responsive-iframe"
          id="iframe-diff"
          width="100%"
          height="70%"
          :src="getDiffToolURL"
        />
      </div>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { mapStatusColor } from "@/helpers/mapStatusColorMixin";
import { eventBus } from "@/main";
import { baseValidation } from "@/validation/baseValidation";

export default {
  name: "FileDiffTool",
  components: {},
  mixins: [mapStatusColor, baseValidation],
  data() {
    return {
      siteAutocompleteModelA: null,
      siteAutocompleteModelB: null,
      inventoryTypeSelectModelA: null,
      inventoryTypeSelectModelB: null,
      revisionSelectModelA: null,
      revisionSelectModelB: null,
      revisionListA: [],
      revisionListB: [],
      revisionAForm: null,
      revisionBForm: null,
      disable_revision_a: true,
      folding: true,
    };
  },
  watch: {
    folding() {
      let frame = this.$refs.diffIframe;
      let payload = {
        folding: this.folding,
      };
      frame.contentWindow.postMessage(
        { call: "sendFolding", value: payload },
        "*"
      );
    },
  },
  async mounted() {
    await this.fetchSiteEntities();
    if (
      this.getRevisionA.site &&
      this.getRevisionA.inventory_type &&
      this.getRevisionA.revision
    ) {
      this.siteAutocompleteModelA = this.getRevisionA.site;
      this.siteAutocompleteModelB = { ...this.getRevisionA.site };
      this.inventoryTypeSelectModelA = this.getRevisionA.inventory_type;
      this.revisionSelectModelA = this.getRevisionA.revision;
    } else if (
      this.$route.query.site &&
      this.$route.query.inventory_type &&
      this.$route.query.revision &&
      this.$route.query.revision_status
    ) {
      this.inventoryTypeSelectModelA = this.$route.query.inventory_type;
      this.siteAutocompleteModelA = this.getSiteEntities.find(item => {
        return item.id === Number(this.$route.query.site);
      });
      this.siteAutocompleteModelB = this.siteAutocompleteModelA;
      this.revisionListA = await this.fetchRevisionsList({
        site_id: this.siteAutocompleteModelA.id,
        inventory_type: this.inventoryTypeSelectModelA,
      });
      this.revisionListB = await this.fetchRevisionsList({
        site_id: this.siteAutocompleteModelB.id,
        inventory_type: this.inventoryTypeSelectModelA,
      });
      let selectConfigData = {
        site: { ...this.siteAutocompleteModelA },
        inventoryType: this.$route.query.inventory_type,
      };
      await this.updateSiteAndInventoryInStore(selectConfigData);
      await this.fetchSiteConfigInfo();
      this.revisionSelectModelA = {
        ...this.revisionListA.find(item => {
          return item.number === Number(this.$route.query.revision);
        }),
      };
      selectConfigData = {
        site: { ...this.siteAutocompleteModelA },
        inventory_type: this.inventoryTypeSelectModelA,
        revision: { ...this.revisionSelectModelA },
      };
      await this.selectRevisionInfoA(selectConfigData);
    }
    this.revisionListB = await this.fetchRevisionsList({
      site_id: this.siteAutocompleteModelB.id,
      inventory_type: this.inventoryTypeSelectModelA,
    });
    this.revisionSelectModelB =
      {
        ...this.revisionListB.find(item => {
          return item.status.toLowerCase() === "live";
        }),
      } || null;
    await this.onSiteSelect();
    await this.onRevisionSelect();
  },
  async created() {
    await this.fetchSiteEntities();
  },
  computed: {
    revisionSelectListA() {
      if (this.revisionListA.length) return this.revisionListA;
      else if (this.revisionListA.length === 0 && this.revisionSelectModelA)
        return [this.revisionSelectModelA];
      else return [];
    },
    revisionSelectListB() {
      if (this.revisionListB.length) return this.revisionListB;
      else if (this.revisionListB.length === 0 && this.revisionSelectModelB)
        return [this.revisionSelectModelB];
      else return [];
    },
    ...mapGetters("siteWebConfig", ["getWebSetting", "isDefaultConfig"]),
    ...mapGetters("siteEntities", ["getSiteEntities"]),
    ...mapGetters("tagsManager", [
      "getInventoryTypeList",
      "getSite",
      "getInventoryType",
      "getRevisionsList",
      "getSiteEntities",
    ]),
    ...mapGetters("revisionsDiff", [
      "getTextA",
      "getTextB",
      "getRevisionA",
      "getRevisionB",
      "getLoading",
      "getDiffToolURL",
    ]),
  },
  methods: {
    ...mapActions("revisionsDiff", [
      "selectRevisionInfoA",
      "selectRevisionInfoB",
      "fetchRevisionsList",
    ]),
    ...mapActions("siteEntities", ["fetchSiteEntities"]),
    ...mapActions("tagsManager", [
      "updateSiteAndInventoryInStore",
      "fetchSiteConfigInfo",
    ]),
    async onSiteSelect() {
      if (!this.siteAutocompleteModelB) {
        return;
      }
      let selectConfigData = {
        site: { ...this.siteAutocompleteModelB },
        inventoryType: this.$route.query.inventory_type,
      };
      await this.updateSiteAndInventoryInStore(selectConfigData);
      this.revisionListB = await this.fetchRevisionsList({
        site_id: this.siteAutocompleteModelB.id,
        inventory_type: this.inventoryTypeSelectModelA,
      });
    },
    async onRevisionSelect() {
      if (!this.revisionSelectModelB) {
        return;
      }
      let revisionInfo = {
        site: { ...this.siteAutocompleteModelB },
        site_id: { ...this.siteAutocompleteModelB.id },
        inventory_type: this.$route.query.inventory_type,
        revision: this.revisionSelectModelB,
        revision_number: this.revisionSelectModelB.number,
      };
      await this.selectRevisionInfoB(revisionInfo);
      let frame = this.$refs.diffIframe;
      let payload = {
        textA: this.getTextA,
        textB: this.getTextB,
        language: this.getInventoryType.toLowerCase() === "web" ? "js" : "json",
      };
      frame.contentWindow.postMessage(
        { call: "sendTextAB", value: payload },
        "*"
      );
    },
    async goBackToTM() {
      await this.$router
        .push({
          name: "TagsManager",
          query: {
            site: this.siteAutocompleteModelA.id,
            inventory_type: this.inventoryTypeSelectModelA,
            revision: this.revisionSelectModelA.number,
          },
        })
        .catch(() => {});
    },
    showWarning(warnings) {
      eventBus.$emit("action_warning", warnings);
    },
  },
};
</script>

<style scoped>
.containerif {
  position: relative;
  overflow: auto;
  width: 100%;
  height: 50%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  margin-bottom: 0;
  padding-bottom: 0;

  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  /*background-color: blue;*/
  overflow: hidden;
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  border: 0;

  flex-grow: 1;
  border: none;
  margin: 0;
  padding: 0;
}
body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.main-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  /*background-color: blue;*/
  overflow: hidden;
}
</style>
