import { render, staticRenderFns } from "./PublisherDialog.vue?vue&type=template&id=fbb58a88&scoped=true"
import script from "./PublisherDialog.vue?vue&type=script&lang=js"
export * from "./PublisherDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbb58a88",
  null
  
)

export default component.exports