export const validationHelper = {
  methods: {
    createRules(rules) {
      let result = [];
      for (const r of rules) {
        result.push(...r);
      }
      return result;
    },
  },
};
