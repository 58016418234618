import axios from "../../axios";
import fileDownload from "js-file-download";

const store = {
  namespaced: true,

  state: {
    loading: false,
    publisher_id: null,
    product_id: 1,
    date: null,
    showSite: false,
    publisher_total: {
      estimated_revenue: 2,
      final_revenue: 3,
      correction_ratio: 1.5,
    },
    queryFilters: {
      paymentTerm: "",
      dpaType: "",
    },
    publisherFinancial: { rows: [], estimated_revenue: 0, final_revenue: 0 },
    payment_status_info: {
      pub_net30: { status: "not_available" },
      pub_net60: { status: "not_available" },
      opti_net30: { status: "not_available" },
      opti_net60: { status: "not_available" },
    },
    invoiceSentNotification: "",
    invoiceSnackbar: false,
  },

  getters: {
    getLoading: state => {
      return state.loading;
    },
    getPublisherFinancial: state => {
      return state.publisherFinancial;
    },
    getPublisherId: state => {
      return state.publisher_id;
    },
    getProductId: state => {
      return state.product_id;
    },
    getMonth: state => {
      if (state.date) return Number(state.date.split("-")[1]);
      else return null;
    },
    getYear: state => {
      if (state.date) return Number(state.date.split("-")[0]);
      else return null;
    },
    getDate: state => {
      return state.date;
    },
    getShowSite: state => {
      return state.showSite;
    },
    getQueryFilters: state => state.queryFilters,
    getPaymentStatusInfo: state => state.payment_status_info,
    getInvoiceSentNotification: state => {
      return state.invoiceSentNotification;
    },
    getInvoiceSnackbar: state => {
      return state.invoiceSnackbar;
    },
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setPublisherFinancial(state, publisherFinancial) {
      state.publisherFinancial = publisherFinancial;
      state.publisherFinancial.correction_ratio =
        publisherFinancial.total_final / publisherFinancial.total_estimated;
    },
    setPublisherTotal(state, total) {
      state.publisher_total = total;
    },
    setPublisherId(state, publisherID) {
      state.publisher_id = publisherID;
    },
    setProductId(state, product_id) {
      state.product_id = product_id;
    },
    setDate(state, date) {
      state.date = date;
    },
    setShowSite(state, show_site) {
      state.showSite = show_site;
    },
    setQueryFilters(state, queryFilters) {
      state.queryFilters.paymentTerm = queryFilters.paymentTerm;
      state.queryFilters.dpaType = queryFilters.dpaType;
    },
    setPaymentStatusInfo(state, payment_obj) {
      state.payment_status_info.pub_net30 = payment_obj.pub_net30;
      state.payment_status_info.pub_net60 = payment_obj.pub_net60;
      state.payment_status_info.opti_net30 = payment_obj.opti_net30;
      state.payment_status_info.opti_net60 = payment_obj.opti_net60;
    },
    setDefaultPaymentStatusInfo(state) {
      state.payment_status_info.pub_net30 = { status: "not_available" };
      state.payment_status_info.pub_net60 = { status: "not_available" };
      state.payment_status_info.opti_net30 = { status: "not_available" };
      state.payment_status_info.opti_net60 = { status: "not_available" };
    },
    setInvoiceSentNotification(state, payload) {
      state.invoiceSentNotification = payload;
    },
    setInvoiceSnackbar(state, payload) {
      state.invoiceSnackbar = payload;
    },
  },

  actions: {
    async fetchPublisherFinancial({ commit, getters }) {
      const publisher_id = getters.getPublisherId;
      const product_id = getters.getProductId;
      const year = getters.getYear;
      const month = getters.getMonth;
      const show_site = getters.getShowSite;
      const payload = { year, month, publisher_id, show_site, product_id };
      const queryFilters = getters.getQueryFilters;
      let queryParams = {};
      if (queryFilters.dpaType) {
        queryParams.dpa_type = queryFilters.dpaType;
      }
      if (queryFilters.paymentTerm) {
        queryParams.payment_term = queryFilters.paymentTerm;
      }
      if (!publisher_id || publisher_id < 0 || !year || !month || !product_id)
        return null;
      const url = "/reporting_turnover_final/";

      commit("setLoading", true);
      return new Promise(resolve => {
        axios
          .get(url, { params: { ...payload, ...queryParams } })
          .then(response => {
            commit("setPublisherFinancial", response.data.data);
            commit(
              "setPaymentStatusInfo",
              response.data.data.payment_status_info
            );
            commit("setLoading", false);
          });
        resolve();
      });
    },
    async generateInvoice({ commit, getters, dispatch }, params) {
      commit("setLoading", true);

      const publisher_id = getters.getPublisherId;
      const product_id = getters.getProductId;
      const year = getters.getYear;
      const month = getters.getMonth;

      if (
        !publisher_id ||
        !product_id ||
        publisher_id < 0 ||
        !year ||
        !month ||
        !params.dpa_type ||
        !params.payment_term ||
        !params.invoice_date
      )
        return;

      const payload = {
        year,
        month,
        publisher_id,
        product_id,
        dpa_type: params.dpa_type,
        payment_term: params.payment_term,
        invoice_date: params.invoice_date,
      };
      const url = "/invoice/generate";

      await axios.post(url, payload);
      await dispatch("fetchPublisherFinancial");
    },
    async downloadInvoice({ commit, getters, dispatch }, params) {
      commit("setLoading", true);
      const publisher_id = getters.getPublisherId;
      const product_id = getters.getProductId;
      const year = getters.getYear;
      const month = getters.getMonth;

      if (
        !publisher_id ||
        !product_id ||
        publisher_id < 0 ||
        !year ||
        !month ||
        !params.dpa_type ||
        !params.payment_term
      )
        return;

      const payload = {
        year,
        month,
        publisher_id,
        product_id,
        dpa_type: params.dpa_type,
        payment_term: params.payment_term,
      };
      const url = "/invoice/download";

      const response = await axios.get(url, {
        params: payload,
        responseType: "blob",
      });
      const suggestedFileName = response.headers["x-suggested-filename"];
      fileDownload(response.data, suggestedFileName);
      await dispatch("fetchPublisherFinancial");
    },
    async sendInvoice({ commit, getters, dispatch }, params) {
      commit("setLoading", true);
      const publisher_id = getters.getPublisherId;
      const product_id = getters.getProductId;
      const year = getters.getYear;
      const month = getters.getMonth;

      if (
        !publisher_id ||
        !product_id ||
        publisher_id < 0 ||
        !year ||
        !month ||
        !params.dpa_type ||
        !params.payment_term
      )
        return;

      const payload = {
        year,
        month,
        publisher_id,
        product_id,
        dpa_type: params.dpa_type,
        payment_term: params.payment_term,
      };
      const url = "/financial_payment/publisher/run_send_invoice";

      const response = await axios.post(url, payload);
      if (response.status !== 200) {
        commit("setInvoiceSentNotification", response.data.message);
      } else {
        commit("setInvoiceSentNotification", "Invoice will be send");
      }
      commit("setInvoiceSnackbar", true);
      await dispatch("fetchPublisherFinancial");
    },
    setPublisher({ commit }, payload) {
      commit("setPublisherId", payload);
    },
    setSelectedDate({ commit }, payload) {
      commit("setDate", payload);
    },
    async validatePayment({ commit, getters, dispatch }, params) {
      commit("setLoading", true);

      const publisher_id = getters.getPublisherId;
      const product_id = getters.getProductId;
      const year = getters.getYear;
      const month = getters.getMonth;

      if (
        !publisher_id ||
        !product_id ||
        publisher_id < 0 ||
        !year ||
        !month ||
        !params.dpa_type ||
        !params.payment_term ||
        !params.payment_date
      )
        return;

      const payload = {
        year,
        month,
        publisher_id,
        product_id,
        dpa_type: params.dpa_type,
        payment_term: params.payment_term,
        payment_date: params.payment_date,
      };
      const url = "/financial_payment/publisher/validate_payment";

      await axios.post(url, payload);
      await dispatch("fetchPublisherFinancial");
    },
    async validateInvoice({ commit, getters, dispatch }, params) {
      commit("setLoading", true);

      const publisher_id = getters.getPublisherId;
      const product_id = getters.getProductId;
      const year = getters.getYear;
      const month = getters.getMonth;

      if (
        !publisher_id ||
        !product_id ||
        publisher_id < 0 ||
        !year ||
        !month ||
        !params.dpa_type ||
        !params.payment_term
      )
        return;

      const payload = {
        year,
        month,
        publisher_id,
        product_id,
        dpa_type: params.dpa_type,
        payment_term: params.payment_term,
      };
      const url = "/financial_payment/publisher/run_validate_invoice";

      await axios.post(url, payload);
      await dispatch("fetchPublisherFinancial");
    },
  },
};

export default store;
