<template>
  <v-row dense align="center">
    <v-col cols="6">
      <v-row align-content="stretch" justify="center">
        <v-col cols="12">
          <v-card style="height: 40vh;">
            <v-list-item-content>
              <v-list-item-title class="headline mb-1"
                >??????</v-list-item-title
              >
              ?????
            </v-list-item-content>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card style="height: 40vh;">
            <v-list-item-content>
              <v-list-item-title class="headline mb-1"
                >??????</v-list-item-title
              >
              ?????
            </v-list-item-content>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="6">
      <v-row align="stretch" justify="center">
        <v-col cols="12">
          <v-card style="height: 40vh;">
            <v-list-item-content>
              <v-list-item-title class="headline mb-1"
                >??????</v-list-item-title
              >
              ?????
            </v-list-item-content>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "AccountingDashboard",
  data() {
    return {
      homeState: true,
    };
  },
  methods: {},
  computed: {},
  created() {},
};
</script>
