<template>
  <v-row>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="getAllCurrencies"
        item-key="id"
        fixed-header
      >
        <template v-slot:top>
          <v-row><v-divider /></v-row>
          <v-row><v-card-title>Opti Yield Currencies</v-card-title></v-row>
          <v-row class="justify-end mb-10">
            <v-col cols="2" class="text-center">
              <v-btn color="primary" v-on:click="editPublisher({})">
                Add new currency <v-icon dark right>add_box</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.action="{ item }">
          <div style="white-space: nowrap">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  depressed
                  icon
                  @click="editPublisher(item)"
                  target="_blank"
                >
                  <v-icon small color="blue">
                    edit
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
      <PriceFloorSettingsDialog
        ref="pf_currency_dialog"
        v-bind:edited-item="editedItem"
        v-bind:edited-index="editedIndex"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { filterMixin } from "../../../helpers/filterMixin";
import PriceFloorSettingsDialog from "./PriceFloorSettingsDialog";

export default {
  name: "PriceFloorSettings",
  components: {
    PriceFloorSettingsDialog,
  },
  mixins: [filterMixin],
  data() {
    return {
      options: {},
      readOnly: false,
      headers: [
        {
          text: "ID",
          value: "id",
          sortable: false,
          align: "left",
        },
        {
          text: "Code",
          value: "code",
          sortable: false,
          align: "center",
        },
        {
          text: "Name",
          value: "name",
          sortable: false,
          align: "center",
        },
        {
          text: "Action",
          value: "action",
          sortable: false,
          align: "center",
        },
      ],
      editedIndex: -1,
      editedItem: [],
      confirmationDialog: false,
    };
  },
  computed: {
    ...mapGetters("priceFloorSettings", ["getAllCurrencies"]),
  },
  mounted() {
    this.fetchAllCurrencies();
  },
  methods: {
    ...mapActions("priceFloorSettings", ["fetchAllCurrencies"]),
    linkColor(param = false) {
      if (param) return "blue";
      else return "grey";
    },
    editPublisher(item) {
      if (
        item &&
        Object.keys(item).length === 0 &&
        Object.getPrototypeOf(item) === Object.prototype
      ) {
        this.editedIndex = -1;
      } else {
        this.editedIndex = 1;
      }
      this.editedItem = { ...item };
      this.$refs.pf_currency_dialog.readOnly = false;
      this.$refs.pf_currency_dialog.show = true;
    },
    onArchive() {
      this.confirmationDialog = true;
    },
    confirmArchive() {
      this.confirmationDialog = false;
    },
    closeArchive() {
      this.confirmationDialog = false;
    },
  },
};
</script>

<style scoped></style>
