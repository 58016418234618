<template>
  <v-row>
    <v-col cols="12">
      <v-row justify="space-between">
        <v-card-title>
          Config render
        </v-card-title>
        <v-spacer />
        <v-col cols="auto">
          <v-btn color="primary" @click="routeTM">
            {{ $t("common.button.manage_tags") }}
          </v-btn>
        </v-col>
      </v-row>
      <AdConfigRenderView />
    </v-col>
  </v-row>
</template>

<script>
import AdConfigRenderView from "../../components/Settings/AdConfigFileManager/AdConfigRenderView";

export default {
  name: "AdConfigLoader",
  components: { AdConfigRenderView },
  methods: {
    async routeTM() {
      const { query } = this.$route;
      await this.$router
        .push({
          name: "TagsManager",
          query,
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped></style>
