import axios from "axios";
import dpa_store from "./demandPartnerAccounts";

const store = {
  namespaced: true,

  state: {
    loading: true,
    publishers: [],
    dpa_for_publisher_list: [],
    publisher_for_dpa: {},
    publisher_gam_network_name: "",
    gam_network_fetch_loading: false,
    pennylane_suppliers: [],
    pennylane_customers: [],
    pennylane_mapping_publisher: {},
    loading_pennylane: false,
    publisher_countries: [],
    publisher_seller_types: [],
    countries: [],
    default_schain: "optidigital",
  },

  getters: {
    getLoading: state => {
      return state.loading;
    },
    getLoadingPennylane: state => {
      return state.loading_pennylane;
    },
    getPublishers: state => {
      return state.publishers;
    },
    getGAMNetworkName: state => state.publisher_gam_network_name,
    getGAMNetworkFetchLoading: state => state.gam_network_fetch_loading,
    getPDPAforPublisherList: state => {
      return state.dpa_for_publisher_list;
    },
    getPDPAforPublisher: state => dpa_id => {
      return (
        state.dpa_for_publisher_list.find(
          el => el.demand_partner_account_id === dpa_id
        ) || {}
      );
    },
    getDefaultSchain: state => state.default_schain,
    getDefaultPublisher: () => () => {
      return {
        id: 0,
        name: "",
        publisher_code: "",
        url: "",
        gam_network_name: "",
        sales_contact_name: "",
        sales_contact_email: "",
        invoicing_contact_name: "",
        publisher_gam_network_name: "",
        invoice_type_id: 1,
        company_name: "",
        company_address: "",
        company_country: "",
        company_iban: "",
        company_bic: "",
        company_vat: "",
        ownerdomain: "",
        vat_present: true,
        traffective_percentage: "0",
        share_publisher_percentage: "80",
        odas_account_name: "",
        odas_account_id: null,
        limited_ads: true,
        agent_user_id: null,
        currency: null,
        active: true,
        mcm_manage_inventory: false,
        seller_type: "PUBLISHER",
        products: [],
        default_schain: "optidigital",
        invoice_contacts: [
          {
            id: null,
            name: "",
            email: "",
          },
        ],
      };
    },
    getPublisherForDPA(state) {
      return state.publisher_for_dpa;
    },
    getDPAList(state) {
      return state.dpa_for_publisher_list;
    },
    getActiveWinners(state) {
      return state.active_winners;
    },
    getPennylaneCustomers(state) {
      return [
        {
          publisher_id: null,
          pennylane_source_id: "Not Mapped",
          name: "Not Mapped",
        },
        ...state.pennylane_customers,
      ];
    },
    getPennylaneSuppliers(state) {
      return [
        {
          publisher_id: null,
          pennylane_source_id: "Not Mapped",
          name: "Not Mapped",
        },
        ...state.pennylane_suppliers,
      ];
    },
    getPennylaneMappingPublisher(state) {
      return state.pennylane_mapping_publisher;
    },
    getAllCountries(state) {
      return state.countries;
    },
    getPublisherCountries(state) {
      return state.publisher_countries;
    },
    getSellerTypes(state) {
      return state.publisher_seller_types;
    },
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setPublishers(state, publishers) {
      state.publishers = publishers;
    },
    setExternalData(state, external_data) {
      external_data?.forEach?.(item => {
        const foundIndex = state.publishers.findIndex(
          publisher => publisher.publisher_code === item.publisher_code
        );
        if (foundIndex !== -1) {
          state.publishers[foundIndex].mcm_manage_inventory =
            item.mcm_manage_inventory;
          state.publishers[foundIndex].products = item.products;
        }
      });
      if (!external_data) {
        external_data = [];
      }
    },
    setSellerTypes(state, seller_types) {
      state.publisher_seller_types = seller_types;
    },
    setGAMNetworkName(state, network_name) {
      state.publisher_gam_network_name = network_name;
    },
    setGamNetworkFetchLoading(state, gam_network_fetch_loading) {
      state.gam_network_fetch_loading = gam_network_fetch_loading;
    },
    setPublisherForDPA(state, publisher) {
      state.publisher_for_dpa = publisher;
    },
    setPDPAList(state, dpa_list) {
      state.dpa_for_publisher_list = dpa_list.map(item => {
        const ret = item;
        ret.default_match_config = {
          ...dpa_store.getters
            .getDemandPartnerAccounts(dpa_store.state)
            .find(el => el.id === item.demand_partner_account_id)
            .default_match_config,
        };
        if (!item.match_config) {
          ret.match_config = { ...ret.default_match_config };
        }
        ret.winners = ret.match_config.winners || [];
        ret.programmatic_channels =
          ret.match_config.programmatic_channels || [];
        return ret;
      });
    },
    setLoadingPennylane(state, loading) {
      state.loading_pennylane = loading;
    },
    setPennylaneCustomers(state, customers) {
      state.pennylane_customers = customers;
    },
    setPennylaneSuppliers(state, suppliers) {
      state.pennylane_suppliers = suppliers;
    },
    setPennylaneMappingPublisher(state, mapping) {
      if (mapping.supplier?.pennylane_source_id === null) {
        mapping.supplier = {
          publisher_id: null,
          pennylane_source_id: "Not Mapped",
          name: "Not Mapped",
        };
      }
      if (mapping.customer?.pennylane_source_id === null) {
        mapping.customer = {
          publisher_id: null,
          pennylane_source_id: "Not Mapped",
          name: "Not Mapped",
        };
      }
      state.pennylane_mapping_publisher = mapping;
    },
    setAllCountries(state, payload) {
      state.countries = payload;
    },
    setPublisherCountries(state, payload) {
      state.publisher_countries = payload;
    },
    setDefaultSchain(state, value) {
      state.default_schain = value;
    },
  },

  actions: {
    async fetchDefaultSchain({ commit, getters }, publisher_id) {
      const {
        data: { data },
      } = await axios.get(`/publisher/schain/${publisher_id}`).catch(() => {
        return { data: { default_schain: getters.getDefaultSchain } };
      });
      commit("setDefaultSchain", data.default_schain);
    },
    async fetchPublishers({ commit, dispatch }, archived = false) {
      const params = { show_archived: archived };
      commit("setLoading", true);
      return new Promise(resolve => {
        axios
          .get("/publisher/", { params })
          .then(response => {
            commit("setPublishers", response.data.data);
            commit("setLoading", false);
          })
          .then(() => {
            dispatch("fetchExternalData");
          });
        resolve();
      });
    },
    async fetchEditablePublisherName({ commit, dispatch }) {
      commit("setLoading", true);
      return new Promise(resolve => {
        axios
          .get("/publisher/name")
          .then(response => {
            commit("setPublishers", response.data.data);
            commit("setLoading", false);
          })
          .then(() => {
            dispatch("fetchExternalData");
          });
        resolve();
      });
    },
    async fetchEditablePublishers({ commit, dispatch }, archived = false) {
      const params = { show_archived: archived, can_edit: true };
      commit("setLoading", true);
      return new Promise(resolve => {
        axios
          .get("/publisher/", { params })
          .then(response => {
            commit("setPublishers", response.data.data);
            commit("setLoading", false);
          })
          .then(() => {
            dispatch("fetchExternalData");
          });
        resolve();
      });
    },
    async fetchExternalData({ commit }) {
      commit("setLoading", true);
      return new Promise(resolve => {
        axios.get("/publisher/get_external_data").then(response => {
          commit("setExternalData", response.data);
          commit("setLoading", false);
        });
        resolve();
      });
    },
    async fetchSellerTypes({ commit }) {
      commit("setLoading", true);
      return new Promise(resolve => {
        axios.get("/publisher/get_seller_types").then(response => {
          commit("setSellerTypes", response.data);
          commit("setLoading", false);
        });
        resolve();
      });
    },
    async fetchPDPAForPublisher({ commit, getters }) {
      const id = getters.getPublisherForDPA.id;
      if (!id) return new Promise(resolve => resolve());
      commit("setLoading", true);
      return new Promise(resolve => {
        axios
          .get("/publisherdpa/" + id)
          .then(response => {
            commit("setPDPAList", response.data.data);
          })
          .then(commit("setLoading", false))
          .catch(er => console.log(er));
        resolve();
      });
    },
    async updatePublisher(
      { commit, dispatch },
      { publisher, publisher_countries }
    ) {
      commit("setLoading", true);
      await Promise.all([
        new Promise(resolve => {
          axios.put("/publisher/", publisher).then(() => {
            dispatch("fetchPublishers");
          });
          resolve();
        }),
        new Promise(resolve => {
          axios.put(
            `/publisher/${publisher_countries.publisher_code}/reporter/countries`,
            publisher_countries
          );
          resolve();
        }),
      ]);
    },
    async createPublisher(
      { commit, dispatch },
      { publisher, publisher_countries }
    ) {
      commit("setLoading", true);
      publisher.share_publisher_percentage = Number(
        publisher.share_publisher_percentage.toString().replace(",", ".")
      );
      publisher.traffective_percentage = Number(
        publisher.traffective_percentage.toString().replace(",", ".")
      );

      await new Promise(resolve => {
        axios.post("/publisher/", publisher).then(async () => {
          await new Promise(resolve => {
            axios.put(
              `/publisher/${publisher_countries.publisher_code}/reporter/countries`,
              publisher_countries
            );
            return resolve();
          });

          dispatch("fetchPublishers");
        });
        return resolve();
      });
    },
    async deletePublisher({ dispatch }, publisher) {
      return new Promise(resolve => {
        axios.delete("/publisher/" + publisher.id).then(() => {
          dispatch("fetchPublishers");
        });
        resolve();
      });
    },
    selectPublisher({ commit }, publisher) {
      commit("setPublisherForDPA", publisher);
    },
    setWinners({ commit }, payload) {
      commit("setWinners", payload);
    },
    async createDPA({ commit, dispatch, getters }, dpa) {
      commit("setLoading", true);
      const obj = {
        publisher_id: getters.getPublisherForDPA.id,
        demand_partner_account_id: dpa.id,
        demand_partner_account_name: dpa.name,
        match_config: dpa.default_match_config,
      };
      return new Promise((resolve, reject) => {
        axios
          .post("/publisherdpa/", obj)
          .then(response => {
            if (response.status !== 200) throw new Error("Bad request");
            if (response.data.status === "warning") {
              commit("warnings/addWarning", response.data.warnings, {
                root: true,
              });
              commit("setLoading", false);
              dispatch("fetchPDPAForPublisher");
              resolve(response.data);
            }
            if (response.data.status !== "success")
              throw new Error(response.data.msg);
            dispatch("fetchPDPAForPublisher");
            commit("setLoading", false);
            resolve(response.data);
          })
          .catch(err => {
            if (err.response.status !== 200) {
              let msg =
                "warnings" in err.response.data && err.response.data.warnings
                  ? err.response.data.warnings
                  : err.toString();
              commit("warnings/snackbarError", msg, {
                root: true,
              });
              commit("warnings/addWarning", msg, {
                root: true,
              });
            }
            dispatch("fetchPDPAForPublisher");
            console.log("e=" + err);
            reject(err);
          });
      });
    },
    async update_dpa({ commit, dispatch }, item) {
      commit("setLoading", true);

      if (item.match_config) {
        item.match_config.winners = item.winners;
        item.match_config.programmatic_channels = item.programmatic_channels;
      } else {
        item.match_config = item.default_match_config;
      }

      return new Promise(resolve => {
        axios
          .put("/publisherdpa/", item)
          .then(() => {
            dispatch("fetchPDPAForPublisher");
          })
          .catch(err => console.log(err));
        commit("setLoading", false);
        resolve();
      });
    },
    async delete_pdpa({ commit, dispatch }, item) {
      commit("setLoading", true);

      return new Promise(resolve => {
        axios
          .delete(
            `/publisherdpa/${item.publisher_id}/${item.demand_partner_account_id}`,
            item
          )
          .then(() => {
            dispatch("fetchPDPAForPublisher");
          })
          .then()
          .catch(err => console.log(err));
        commit("setLoading", false);
        resolve();
      });
    },
    async restore_dpa_config({ commit }, item) {
      commit("setLoading", true);
      return new Promise(resolve => {
        axios
          .put("/publisherdpa/restore_default_config/", item)
          .then()
          .catch(err => console.log(err));
        commit("setLoading", false);
        resolve();
      });
    },
    async addDefaultsDpa({ commit, getters, dispatch }) {
      commit("setLoading", true);
      const postData = {
        publisher_id: getters.getPublisherForDPA.id,
      };
      await axios.post("/publisherdpa/addDefaults", postData);
      await dispatch("fetchPDPAForPublisher");
      commit("setLoading", false);
    },
    async getGAMNetworkName({ commit }, publisher_id) {
      commit("setGamNetworkFetchLoading", true);
      return new Promise(resolve => {
        axios
          .get("/sspa/gam/get_network_name/" + publisher_id)
          .then(response => {
            if (response.status === 200) {
              commit("setGAMNetworkName", response.data.network_name);
              commit("setGamNetworkFetchLoading", false);
            }
          });
        resolve();
      });
    },
    async getPennylaneClients({ commit }) {
      commit("setLoadingPennylane", true);
      let all_customers = axios.get("/pennylane_actions/customer/all");
      let all_suppliers = axios.get("/pennylane_actions/supplier/all");
      return Promise.all([all_customers, all_suppliers]).then(
        ([all_customers, all_suppliers]) => {
          commit("setPennylaneCustomers", all_customers.data);
          commit("setPennylaneSuppliers", all_suppliers.data);
          commit("setLoadingPennylane", false);
        }
      );
    },
    async getPennylaneClientMappingByPublisher({ commit }, publisher_id) {
      commit("setLoadingPennylane", true);
      const customer = axios.get("/pennylane_actions/customer/" + publisher_id);
      const supplier = axios.get("/pennylane_actions/supplier/" + publisher_id);
      return Promise.all([customer, supplier]).then(([customer, supplier]) => {
        commit("setPennylaneMappingPublisher", {
          customer: customer.data,
          supplier: supplier.data,
        });
        commit("setLoadingPennylane", false);
      });
    },
    async updatePennylaneSupplierMapping({ commit, dispatch }, payload) {
      commit("setLoadingPennylane", true);
      await axios.put("/pennylane_actions/supplier", payload);
      dispatch("getPennylaneClientMappingByPublisher", payload.publisher_id);
    },
    async updatePennylaneCustomerMapping({ commit, dispatch }, payload) {
      commit("setLoadingPennylane", true);
      await axios.put("/pennylane_actions/customer", payload);
      dispatch("getPennylaneClientMappingByPublisher", payload.publisher_id);
    },
    async fetchAllCountries({ commit }) {
      commit("setLoading", true);
      return new Promise(resolve => {
        axios.get(`/publisher/countries`).then(response => {
          commit("setAllCountries", []); // little hack to avoid duplications, we should use a set in this case
          commit("setAllCountries", response.data);
          commit("setLoading", false);
        });
        resolve();
      });
    },
    async fetchPublisherCountries({ commit }, publisher_code) {
      commit("setLoading", true);
      commit("setPublisherCountries", {
        network_id: null,
        publisher_code,
        countries: [],
      });
      return new Promise(resolve => {
        axios
          .get(`/publisher/${publisher_code}/reporter/countries`)
          .then(response => {
            commit("setPublisherCountries", response.data);
            commit("setLoading", false);
          })
          .catch(() => {
            commit("setPublisherCountries", {});
            commit("setLoading", false);
          });
        resolve();
      });
    },
  },
};

export default store;
