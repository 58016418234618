<template>
  <v-row class="sites-grid">
    <v-snackbar v-model="snackbar" top color="blue">
      {{ snackbar_message }}
      <v-btn color="white" text @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="getPaymentList"
        fixed-header
        :items-per-page="30"
        :footer-props="{
          'show-first-last-page': true,
          'items-per-page-options': [30, 50, 80],
        }"
      >
        <template v-slot:top>
          <v-row align="center" justify="space-around">
            <v-col cols="4">
              <v-row>
                <v-menu
                  v-model="datePicker"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="datePickerDate"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      label="Select date"
                    />
                  </template>
                  <v-date-picker
                    v-model="datePickerDate"
                    type="month"
                    @input="selectDate(datePickerDate)"
                  />
                </v-menu>
                <v-icon
                  :disabled="!datePickerDate"
                  color="red darken-3"
                  icon
                  @click="resetFilter('setPublisherName', 'datePickerDate')"
                >
                  mdi-close-circle
                </v-icon>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-autocomplete
                  :items="getPublishers"
                  item-text="name"
                  item-value="id"
                  label="Publisher name"
                  v-model="publisher_name"
                />
                <v-icon
                  :disabled="!publisher_name"
                  color="red darken-3"
                  icon
                  @click="resetFilter('setPublisherName', 'publisher_name')"
                >
                  mdi-close-circle
                </v-icon>
              </v-row>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.payment_status_info.pub_net30.status="{ item }">
          <v-tooltip
            bottom
            v-if="item.payment_status_info.pub_net30.status === 'invoiced'"
          >
            <template v-slot:activator="{ on }">
              <v-chip
                v-on="on"
                :color="
                  mapFinancialPaymentColor(
                    item.payment_status_info.pub_net30.status
                  )
                "
              >
                {{ getStatusName(item.payment_status_info.pub_net30.status) }}
                <v-icon
                  v-if="item.payment_status_info.pub_net30.invoice_sent_date"
                  color="black"
                  class="ml-3"
                >
                  mark_email_read
                </v-icon>
              </v-chip>
            </template>
            <div class="d-flex flex-column">
              <span>
                Invoice date:
                {{ item.payment_status_info.pub_net30.invoice_date }}
              </span>
              <span>
                Due date:
                {{ item.payment_status_info.pub_net30.invoice_due_date }}
              </span>
              <span v-if="item.payment_status_info.pub_net30.invoice_sent_date">
                Invoice sent date:
                {{ item.payment_status_info.pub_net30.invoice_sent_date }}
              </span>
              <span>
                Value: {{ item.payment_status_info.pub_net30.value }} €
              </span>
            </div>
          </v-tooltip>
          <v-tooltip
            bottom
            v-else-if="
              item.payment_status_info.pub_net30.status === 'payment_done'
            "
          >
            <template v-slot:activator="{ on }">
              <v-chip
                v-on="on"
                :color="
                  mapFinancialPaymentColor(
                    item.payment_status_info.pub_net30.status
                  )
                "
              >
                {{ getStatusName(item.payment_status_info.pub_net30.status) }}
                <v-icon
                  v-if="item.payment_status_info.pub_net30.invoice_sent_date"
                  color="black"
                  class="ml-3"
                >
                  mark_email_read
                </v-icon>
              </v-chip>
            </template>
            <div class="d-flex flex-column">
              <span
                v-if="item.payment_status_info.pub_net30.invoice_payment_date"
              >
                Invoice payment date:
                {{ item.payment_status_info.pub_net30.invoice_payment_date }}
              </span>
              <span v-if="item.payment_status_info.pub_net30.invoice_sent_date">
                Invoice sent date:
                {{ item.payment_status_info.pub_net30.invoice_sent_date }}
              </span>
              <span>
                Value: {{ item.payment_status_info.pub_net30.value }} €
              </span>
            </div>
          </v-tooltip>
          <v-chip
            v-else
            :color="
              mapFinancialPaymentColor(
                item.payment_status_info.pub_net30.status
              )
            "
          >
            {{ getStatusName(item.payment_status_info.pub_net30.status) }}
          </v-chip>
          <template
            v-if="item.payment_status_info.pub_net30.status === 'invoiced'"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn depressed icon v-on="on" @click="onDownloadInvoice">
                  <v-icon small> download </v-icon>
                </v-btn>
              </template>
              <span>Download Invoice</span>
            </v-tooltip>
            <!--<v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn depressed icon v-on="on">
                  <v-icon small color="blue"> email </v-icon>
                </v-btn>
              </template>
              <span>Send Invoice</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn depressed icon v-on="on">
                  <v-icon small color="warning"> autorenew </v-icon>
                </v-btn>
              </template>
              <span>Regenerate Invoice</span>
            </v-tooltip>-->
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn depressed icon v-on="on" @click="onValidatePayment">
                  <v-icon small> approval </v-icon>
                </v-btn>
              </template>
              <span>Payment Validated</span>
            </v-tooltip>
          </template>
          <template
            v-if="item.payment_status_info.pub_net30.status === 'payment_done'"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn depressed icon v-on="on" @click="onDownloadInvoice">
                  <v-icon small> download </v-icon>
                </v-btn>
              </template>
              <span>Download Invoice</span>
            </v-tooltip>
          </template>
          <template
            v-if="item.payment_status_info.pub_net30.status === 'validated'"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn depressed icon v-on="on" @click="onGenerateInvoice">
                  <v-icon small> description </v-icon>
                </v-btn>
              </template>
              <span>Generate Payment</span>
            </v-tooltip>
          </template>
        </template>
        <template v-slot:item.payment_status_info.pub_net60.status="{ item }">
          <v-tooltip
            bottom
            v-if="item.payment_status_info.pub_net60.status === 'overdue'"
          >
            <template v-slot:activator="{ on }">
              <v-chip
                v-on="on"
                :color="
                  mapFinancialPaymentColor(
                    item.payment_status_info.pub_net60.status
                  )
                "
              >
                {{ getStatusName(item.payment_status_info.pub_net60.status) }}
              </v-chip>
            </template>
            <div class="d-flex flex-column">
              <span>
                Invoice date:
                {{ item.payment_status_info.pub_net60.invoice_date }}
              </span>
              <span>
                Due date:
                {{ item.payment_status_info.pub_net60.invoice_due_date }}
              </span>
              <span>
                Value:
                {{ item.payment_status_info.pub_net60.value }} €
              </span>
            </div>
          </v-tooltip>
          <v-chip
            v-else
            :color="
              mapFinancialPaymentColor(
                item.payment_status_info.pub_net60.status
              )
            "
          >
            {{ getStatusName(item.payment_status_info.pub_net60.status) }}
          </v-chip>
          <template
            v-if="item.payment_status_info.pub_net60.status === 'overdue'"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn depressed icon v-on="on" @click="onDownloadInvoice">
                  <v-icon small> download </v-icon>
                </v-btn>
              </template>
              <span>Download Invoice</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn depressed icon v-on="on" @click="onSendInvoice">
                  <v-icon small> email </v-icon>
                </v-btn>
              </template>
              <span>Send Invoice</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn depressed icon v-on="on" @click="onValidatePayment">
                  <v-icon small> approval </v-icon>
                </v-btn>
              </template>
              <span>Payment Validated</span>
            </v-tooltip>
          </template>
        </template>
        <template v-slot:item.payment_status_info.opti_net30.status="{ item }">
          <v-chip
            :color="
              mapFinancialPaymentColor(
                item.payment_status_info.opti_net30.status
              )
            "
          >
            {{ getStatusName(item.payment_status_info.opti_net30.status) }}
          </v-chip>
        </template>
        <template v-slot:item.payment_status_info.opti_net60.status="{ item }">
          <v-tooltip
            bottom
            v-if="item.payment_status_info.opti_net60.status === 'invoiced'"
          >
            <template v-slot:activator="{ on }">
              <v-chip
                v-on="on"
                :color="
                  mapFinancialPaymentColor(
                    item.payment_status_info.opti_net60.status
                  )
                "
              >
                {{ getStatusName(item.payment_status_info.opti_net60.status) }}
              </v-chip>
            </template>
            <div class="d-flex flex-column">
              <span>
                Invoice date:
                {{ item.payment_status_info.opti_net60.invoice_date }}
              </span>
              <span>
                Due date:
                {{ item.payment_status_info.opti_net60.invoice_due_date }}
              </span>
              <span>
                Value:
                {{ item.payment_status_info.opti_net60.value }} €
              </span>
            </div>
          </v-tooltip>
          <v-chip
            v-else
            :color="
              mapFinancialPaymentColor(
                item.payment_status_info.opti_net60.status
              )
            "
          >
            {{ getStatusName(item.payment_status_info.opti_net60.status) }}
          </v-chip>
          <template
            v-if="item.payment_status_info.opti_net60.status === 'invoiced'"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn depressed icon v-on="on" @click="onDownloadInvoice">
                  <v-icon small> download </v-icon>
                </v-btn>
              </template>
              <span>Download Invoice</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn depressed icon v-on="on" @click="onSendInvoice">
                  <v-icon small> email </v-icon>
                </v-btn>
              </template>
              <span>Send Invoice</span>
            </v-tooltip>
            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn depressed icon v-on="on" @click="onGenerateInvoice">
                  <v-icon small> autorenew </v-icon>
                </v-btn>
              </template>
              <span>Regenerate Invoice</span>
            </v-tooltip> -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn depressed icon v-on="on" @click="onValidatePayment">
                  <v-icon small> approval </v-icon>
                </v-btn>
              </template>
              <span>Payment Validated</span>
            </v-tooltip>
          </template>
        </template>
        <template v-slot:item.action="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                depressed
                icon
                v-on="on"
                :href="
                  `/accounting/publisherFinancial/?&publisher_id=${item.publisher_id}`
                "
              >
                <v-icon small color="blue">
                  euro
                </v-icon>
              </v-btn>
            </template>
            <span>Publisher Financial</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-col>
    <PaymentDateDialogVue
      :visible="dateDialog"
      :titleText="dateDialogTitle"
      :onConfirmCallback="dateDialogCallback"
      :onCancelCallback="onHideDateDialog"
      :dpaType="dpa_type"
    />
  </v-row>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { mapStatusColor } from "@/helpers/mapStatusColorMixin";
import PaymentDateDialogVue from "../../Accounting/ReportingTurnoverFinal/PaymentDateDialog.vue";

export default {
  name: "PaymentsTable",
  mixins: [mapStatusColor],
  components: {
    PaymentDateDialogVue,
  },
  data() {
    return {
      publisher_name: null,
      datePicker: false,
      startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      datePickerDate: null,
      dateDialog: false,
      dateDialogCallback: new Function(),
      dateDialogTitle: "",
      dpa_type: "",
      snackbar: false,
      snackbar_message: "",
      headers: [
        {
          text: "Month",
          value: "month",
        },
        {
          text: "Publisher",
          value: "publisher_name",
        },
        {
          text: "Auto-invoices Turnover Publisher NET 30",
          value: "payment_status_info.pub_net30.status",
        },
        {
          text: "Auto-invoices Turnover Publisher NET 60",
          value: "payment_status_info.pub_net60.status",
        },
        {
          text: "Invoice commission Opti Digital NET 30",
          value: "payment_status_info.opti_net30.status",
        },
        {
          text: "Invoice commission Opti Digital NET 60",
          value: "payment_status_info.opti_net60.status",
        },
        {
          text: "Action",
          value: "action",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("publishers", ["getPublishers"]),
    getPaymentList() {
      return [
        {
          month: "2022-05",
          publisher_name: "abroparaguas.com",
          publisher_id: 121,
          payment_status_info: {
            pub_net30: {
              status: "invoiced",
              invoice_date: "2022-04-29 00:00:00",
              invoice_due_date: "2022-05-29 00:00:00",
              invoice_payment_date: null,
              invoice_sent_date: "2022-05-25 00:00:00",
              value: 12345.02,
            },
            pub_net60: {
              status: "not_available",
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net30: {
              status: "not_available",
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net60: {
              status: "pending_validation",
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
          },
        },
        {
          month: "2022-05",
          publisher_name: "acontresens",
          publisher_id: 36,
          payment_status_info: {
            pub_net30: {
              status: "validated",
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            pub_net60: {
              status: "not_available",
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net30: {
              status: "not_available",
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net60: {
              status: "invoiced",
              invoice_date: "2022-02-15 00:00:00",
              invoice_due_date: "2022-04-16 00:00:00",
              invoice_payment_date: null,
              invoice_sent_date: null,
              value: 3332.01,
            },
          },
        },
        {
          month: "2022-05",
          publisher_name: "ADS4ALL",
          publisher_id: 157,
          payment_status_info: {
            pub_net30: {
              status: "payment_done",
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: "2022-05-26 14:00:00",
              invoice_sent_date: "2022-05-25 00:00:00",
              value: 7332.01,
            },
            pub_net60: {
              status: "overdue",
              invoice_date: "2022-05-01 00:00:00",
              invoice_due_date: "2022-05-26 14:00:00",
              invoice_payment_date: null,
              invoice_sent_date: null,
              value: 4441.98,
            },
            opti_net30: {
              status: "not_available",
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net60: {
              status: "invoiced",
              invoice_date: "2022-02-15 00:00:00",
              invoice_due_date: "2022-04-16 00:00:00",
              invoice_payment_date: null,
              invoice_sent_date: null,
              value: 3332.01,
            },
          },
        },
        {
          month: "2022-05",
          publisher_name: "Adweb Outremer",
          publisher_id: 155,
          payment_status_info: {
            pub_net30: {
              status: "invoiced",
              invoice_date: "2022-04-29 00:00:00",
              invoice_due_date: "2022-05-29 00:00:00",
              invoice_payment_date: null,
              invoice_sent_date: "2022-05-25 00:00:00",
              value: 12345.02,
            },
            pub_net60: {
              status: "not_available",
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net30: {
              status: "not_available",
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net60: {
              status: "pending_validation",
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
          },
        },
        {
          month: "2022-05",
          publisher_name: "aide_sociale",
          publisher_id: 58,
          payment_status_info: {
            pub_net30: {
              status: "validated",
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            pub_net60: {
              status: "not_available",
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net30: {
              status: "not_available",
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net60: {
              status: "invoiced",
              invoice_date: "2022-02-15 00:00:00",
              invoice_due_date: "2022-04-16 00:00:00",
              invoice_payment_date: null,
              invoice_sent_date: null,
              value: 3332.01,
            },
          },
        },
        {
          month: "2022-05",
          publisher_name: "Aujardin",
          publisher_id: 88,
          payment_status_info: {
            pub_net30: {
              status: "payment_done",
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: "2022-05-26 14:00:00",
              invoice_sent_date: "2022-05-25 00:00:00",
              value: 7332.01,
            },
            pub_net60: {
              status: "overdue",
              invoice_date: "2022-05-01 00:00:00",
              invoice_due_date: "2022-05-26 14:00:00",
              invoice_payment_date: null,
              invoice_sent_date: null,
              value: 4441.98,
            },
            opti_net30: {
              status: "not_available",
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net60: {
              status: "invoiced",
              invoice_date: "2022-02-15 00:00:00",
              invoice_due_date: "2022-04-16 00:00:00",
              invoice_payment_date: null,
              invoice_sent_date: null,
              value: 3332.01,
            },
          },
        },
        {
          month: "2022-05",
          publisher_name: "betaseries",
          publisher_id: 103,
          payment_status_info: {
            pub_net30: {
              status: "invoiced",
              invoice_date: "2022-04-29 00:00:00",
              invoice_due_date: "2022-05-29 00:00:00",
              invoice_payment_date: null,
              invoice_sent_date: "2022-05-25 00:00:00",
              value: 12345.02,
            },
            pub_net60: {
              status: "not_available",
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net30: {
              status: "not_available",
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net60: {
              status: "pending_validation",
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
          },
        },
        {
          month: "2022-05",
          publisher_name: "CambiumMedia",
          publisher_id: 118,
          payment_status_info: {
            pub_net30: {
              status: "validated",
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            pub_net60: {
              status: "not_available",
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net30: {
              status: "not_available",
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net60: {
              status: "invoiced",
              invoice_date: "2022-02-15 00:00:00",
              invoice_due_date: "2022-04-16 00:00:00",
              invoice_payment_date: null,
              invoice_sent_date: null,
              value: 3332.01,
            },
          },
        },
        {
          month: "2022-05",
          publisher_name: "Chien.com",
          publisher_id: 131,
          payment_status_info: {
            pub_net30: {
              status: "payment_done",
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: "2022-05-26 14:00:00",
              invoice_sent_date: "2022-05-25 00:00:00",
              value: 7332.01,
            },
            pub_net60: {
              status: "overdue",
              invoice_date: "2022-05-01 00:00:00",
              invoice_due_date: "2022-05-26 14:00:00",
              invoice_payment_date: null,
              invoice_sent_date: null,
              value: 4441.98,
            },
            opti_net30: {
              status: "not_available",
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net60: {
              status: "invoiced",
              invoice_date: "2022-02-15 00:00:00",
              invoice_due_date: "2022-04-16 00:00:00",
              invoice_payment_date: null,
              invoice_sent_date: null,
              value: 3332.01,
            },
          },
        },
        {
          month: "2022-05",
          publisher_name: "	clubic",
          publisher_id: 29,
          payment_status_info: {
            pub_net30: {
              status: "invoiced",
              invoice_date: "2022-04-29 00:00:00",
              invoice_due_date: "2022-05-29 00:00:00",
              invoice_payment_date: null,
              invoice_sent_date: "2022-05-25 00:00:00",
              value: 12345.02,
            },
            pub_net60: {
              status: "not_available",
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net30: {
              status: "not_available",
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net60: {
              status: "pending_validation",
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
          },
        },
        {
          month: "2022-05",
          publisher_name: "CMI Media",
          publisher_id: 136,
          payment_status_info: {
            pub_net30: {
              status: "validated",
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            pub_net60: {
              status: "not_available",
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net30: {
              status: "not_available",
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net60: {
              status: "invoiced",
              invoice_date: "2022-02-15 00:00:00",
              invoice_due_date: "2022-04-16 00:00:00",
              invoice_payment_date: null,
              invoice_sent_date: null,
              value: 3332.01,
            },
          },
        },
        {
          month: "2022-05",
          publisher_name: "	commenteconomiser",
          publisher_id: 62,
          payment_status_info: {
            pub_net30: {
              status: "payment_done",
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: "2022-05-26 14:00:00",
              invoice_sent_date: "2022-05-25 00:00:00",
              value: 7332.01,
            },
            pub_net60: {
              status: "overdue",
              invoice_date: "2022-05-01 00:00:00",
              invoice_due_date: "2022-05-26 14:00:00",
              invoice_payment_date: null,
              invoice_sent_date: null,
              value: 4441.98,
            },
            opti_net30: {
              status: "not_available",
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net60: {
              status: "invoiced",
              invoice_date: "2022-02-15 00:00:00",
              invoice_due_date: "2022-04-16 00:00:00",
              invoice_payment_date: null,
              invoice_sent_date: null,
              value: 3332.01,
            },
          },
        },
      ];
    },
  },
  created() {
    this.setPublisherName("");
    this.fetchPublishers();
    if (!this.datePickerDate) {
      const lastMonth = new Date(this.startDate);
      lastMonth.setMonth(lastMonth.getMonth() - 1);
      this.datePickerDate = lastMonth.toISOString().substr(0, 7);
    }
  },
  methods: {
    ...mapMutations("payments", ["setPublisherName"]),
    ...mapActions("publishers", ["fetchPublishers"]),
    ...mapActions("turnoverFinal", [
      "generateInvoice",
      "downloadInvoice",
      "validatePayment",
      "sendInvoice",
    ]),
    selectDate(date) {
      console.log(date);
      this.datePicker = false;
    },
    setUpDate() {
      let d = new Date();
      d.setDate(0); //prev month
      d.setDate(1); // set first day of prev month
      this.datePickerDate = this.getDate || d.toISOString().substr(0, 7);
      // store.dispatch("turnoverFinal/setSelectedDate", this.datePickerDate);
    },
    getStatusName(status) {
      if (status.toLowerCase() === "pending_validation")
        return "Pending Validation";
      if (status.toLowerCase() === "validated") return "Validated";
      if (status.toLowerCase() === "invoiced") return "Invoiced";
      if (status.toLowerCase() === "payment_done") return "Paid";
      if (status.toLowerCase() === "invoice_sent") return "Invoice Sent";
      if (status.toLowerCase() === "overdue") return "Overdue";
      else return "Not Available";
    },
    onShowDateDialog(title, callback) {
      this.dateDialogTitle = title;
      this.dateDialogCallback = callback;
      this.dateDialog = true;
    },
    onHideDateDialog() {
      this.dateDialog = false;
    },
    onValidatePayment() {
      this.onShowDateDialog("Select Payment Date", paymentDate => {
        this.validatePayment({
          dpa_type: this.dpa_type,
          payment_term: this.payment_term,
          payment_date: paymentDate,
        });
        this.onHideDateDialog();
      });
    },
    onGenerateInvoice() {
      this.onShowDateDialog("Select Invoice Date", generateDate => {
        this.generateInvoice({
          dpa_type: this.dpa_type,
          payment_term: this.payment_term,
          invoice_date: generateDate,
        });
        this.onHideDateDialog();
      });
    },
    resetFilter(a, b) {
      this[`${b}`] = "";
    },
    onDownloadInvoice() {
      this.snackbar = true;
      this.snackbar_message = "The invoice was downloaded";
    },
    onSendInvoice() {
      this.snackbar = true;
      this.snackbar_message = "The invoice was sent via e-mail";
    },
  },
};
</script>

<style scoped></style>
