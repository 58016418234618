<template>
  <div>
    <v-list-item ripple @mousedown.prevent @click="select">
      <v-list-item-action>
        <v-icon>
          {{ icon }}
        </v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          {{ selectAllLabel }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider class="mt-2" />
  </div>
</template>

<script>
export default {
  props: {
    element: {
      type: String,
    },
    all: {
      type: Array,
      default() {
        return [];
      },
    },
    filters: {
      default() {
        return [];
      },
    },
  },
  data: () => ({
    icon: "mdi-checkbox-blank-outline",
    selectAllLabel: "Select All",
  }),
  watch: {
    filters() {
      if (this.filters.length < this.all.length) {
        this.icon = "mdi-checkbox-blank-outline";
        this.selectAllLabel = "Select All";
      }
      if (this.filters.length === this.all.length) {
        this.icon = "mdi-close-box";
        this.selectAllLabel = "Unselect All";
      }
    },
  },
  methods: {
    select() {
      if (this.all.length === this.filters.length) {
        this.icon = "mdi-checkbox-blank-outline";
        this.selectAllLabel = "Select All";
      } else {
        this.icon = "mdi-close-box";
        this.selectAllLabel = "Unselect All";
      }
      this.$emit("toggle", this.element);
    },
  },
};
</script>
