<template>
  <v-dialog v-model="visible" width="auto" v-on:click:outside="onCancel">
    <v-card>
      <v-card-title>
        {{ titleText }}
      </v-card-title>
      <v-card-text>
        <v-date-picker v-model="selectedDate" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click="onConfirm">
          Confirm
        </v-btn>
        <v-btn @click="onCancel">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  watch: {
    visible(newValue) {
      if (newValue === true) {
        if (this.dpaType === "publisher") {
          const date = new Date(this.getYear, this.getMonth, 1);
          const formattedDate = new Date(
            date.getTime() - date.getTimezoneOffset() * 60000
          );
          this.selectedDate = formattedDate.toISOString().slice(0, 10);
        } else if (this.dpaType === "optidigital") {
          const date_day = this.getProductId === 3 ? 1 : 15;
          const date = new Date(this.getYear, this.getMonth, date_day);
          const formattedDate = new Date(
            date.getTime() - date.getTimezoneOffset() * 60000
          );
          this.selectedDate = formattedDate.toISOString().slice(0, 10);
        } else {
          this.selectedDate = new Date().toISOString().slice(0, 10);
        }
      }
    },
  },
  data() {
    return {
      selectedDate: "",
    };
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    dpaType: {
      type: String,
      required: true,
    },
    titleText: {
      type: String,
      required: true,
    },
    onConfirmCallback: {
      type: Function,
      required: true,
    },
    onCancelCallback: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapGetters("turnoverFinal", ["getYear", "getMonth", "getProductId"]),
  },
  methods: {
    onConfirm() {
      this.onConfirmCallback(this.selectedDate);
    },
    onCancel() {
      this.onCancelCallback(this.selectedDate);
    },
  },
};
</script>

<style></style>
