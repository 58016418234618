<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-card-title>{{ $tc("publishers.publisher", 2) }}</v-card-title>
      </v-row>
      <PublishersTable />
    </v-col>
  </v-row>
</template>

<script>
import PublishersTable from "../../components/Accounting/Publishers/PublishersTable.vue";

export default {
  name: "Publishers",
  components: { PublishersTable },
};
</script>

<style scoped></style>
