<template>
  <span>
    <v-dialog v-model="confirmationDialog" persistent width="50vw">
      <v-card class="pa-3 text-center">
        <v-card-title>
          <v-col>{{ msg }}</v-col>
        </v-card-title>
        <v-card-actions justify="center">
          <v-spacer />
          <v-btn :color="confirmColor" v-on:click="onConfirm()">{{
            confirmText
          }}</v-btn>
          <v-spacer />
          <v-btn :color="closeColor" v-on:click="onClose()">{{
            cancelText
          }}</v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
export default {
  props: {
    confirmationDialog: {
      type: Boolean,
      required: true,
    },
    msg: {
      type: String,
      required: true,
    },
    onConfirm: {
      type: Function,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
    confirmColor: {
      type: String,
      required: false,
      default: "error",
    },
    closeColor: {
      type: String,
      required: false,
      default: "green",
    },
    closeButtonText: {
      type: String,
      required: false,
      default: "",
    },
    confirmButtonText: {
      type: String,
      required: false,
      default: "",
    },
  },
  computed: {
    confirmText() {
      return "" !== this.confirmButtonText
        ? this.confirmButtonText
        : this.$t("buttons.general.yes");
    },
    cancelText() {
      return "" !== this.closeButtonText
        ? this.closeButtonText
        : this.$t("buttons.general.cancel");
    },
  },
};
</script>

<style scoped></style>
