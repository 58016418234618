import axios from "axios";

const store = {
  namespaced: true,

  state: {
    loading: true,
    syncReports: [],
    sync_in_progress: false,
    syncNotification: [],
  },

  getters: {
    getLoading: state => {
      return state.loading;
    },
    getSyncReports: state => {
      return state.syncReports;
    },
    getSiteInSync: state => {
      return state.sync_in_progress;
    },
    getSyncNotification: state => {
      return state.syncNotification;
    },
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setSyncReports(state, syncReports) {
      state.syncReports = syncReports;
    },
    setSiteInSync(state, in_sync) {
      state.sync_in_progress = in_sync;
    },
    setSyncNotification(state, syncNotification) {
      state.syncNotification = syncNotification;
    },
  },

  actions: {
    async checkSync({ commit, getters }, site_id) {
      commit("setSiteInSync", true);
      const refresh_interval =
        process.env.VUE_APP_SITE_LOCK_CHECK_INTERVAL * 1000;
      while (getters.getSiteInSync) {
        await new Promise(r => setTimeout(r, refresh_interval));
        await axios.get("/siteentity/" + site_id + "/check").then(resp => {
          if (resp.status === 204) {
            commit("setSyncNotification", { status: 0, message: "" });
            commit("setSiteInSync", false);
          } else if (
            resp.status === 200 &&
            resp.data.notifications &&
            resp.data.notifications.status !== 0
          ) {
            commit("setSyncNotification", resp.data.notifications);
            commit("setSiteInSync", false);
          }
        });
      }
    },
    async syncAdSlotSSP({ commit, rootState }) {
      await commit("setLoading", true);
      await commit("setSiteInSync", true);
      const payload = {
        site_id: rootState.tagsManager.site.id,
        site_config_id: rootState.siteWebConfig.webConfig.id,
        revision: rootState.tagsManager.revision.number,
        inventory_type: rootState.tagsManager.inventoryType,
      };
      await axios.post("/sspa/inventory", payload);
      commit("setLoading", false);
    },
    async fetchSyncReports({ commit, dispatch }) {
      commit("setLoading", true);
      await axios
        .get("/sspa/sync_reports")
        .then(response => {
          if (response.status === 200) commit("setSyncReports", response.data);
        })
        .catch(err => {
          dispatch("warnings/showSnackbarError", err.toString(), {
            root: true,
          });
        });
      commit("setLoading", false);
    },
    async triggerSyncReports({ commit, dispatch }) {
      commit("setLoading", true);
      await axios.post("/sspa/sync_reports/trigger").catch(err => {
        dispatch("warnings/showSnackbarError", err.toString(), {
          root: true,
        });
      });
      commit("setLoading", false);
    },
    async initBinding({ commit, dispatch }) {
      commit("setLoading", true);

      // await axios.post("/sspa/inventory/init_binding/trigger").catch(err => {
      await axios.post("/sspa/inventory/init_binding").catch(err => {
        dispatch("warnings/showSnackbarError", err.toString(), {
          root: true,
        });
      });
      commit("setLoading", false);
    },
  },
};

export default store;
