<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-card-title>Audit Log</v-card-title>
      </v-row>
      <v-row align="center" justify="space-around">
        <v-col cols="4">
          <v-row>
            <v-autocomplete
              label="User email"
              v-model="searchUser"
              v-on:change="
                () => {
                  this.setSearchDataAction({
                    searchAction: this.searchAction,
                    searchUser: this.searchUser,
                  });
                  this.options.page = 1;
                }
              "
              :items="getUsersEmails"
              hide-no-data
              :loading="getLoading"
            />
            <v-icon
              :disabled="!searchUser"
              color="red darken-3"
              @click="
                () => {
                  this.searchUser = '';
                  this.options.page = 1;
                  setSearchDataAction({
                    searchAction: this.searchAction,
                    searchUser: this.searchUser,
                  });
                }
              "
              icon
            >
              mdi-close-circle
            </v-icon>
          </v-row>
        </v-col>
        <v-col cols="4">
          <v-row>
            <v-autocomplete
              label="Action"
              v-model="searchAction"
              v-on:change="
                () => {
                  this.setSearchDataAction({
                    searchAction: this.searchAction,
                    searchUser: this.searchUser,
                  });
                  this.options.page = 1;
                }
              "
              :items="getAllActions"
              hide-no-data
              :loading="getLoading"
            />
            <v-icon
              :disabled="!searchAction"
              color="red darken-3"
              @click="
                () => {
                  this.searchAction = '';
                  this.options.page = 1;
                  setSearchDataAction({
                    searchAction: this.searchAction,
                    searchUser: this.searchUser,
                  });
                }
              "
              icon
            >
              mdi-close-circle
            </v-icon>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        dense
        height="60vh"
        :loading="getLoading"
        :headers="headers"
        :items="getAuditLogs"
        :server-items-length="getAuditLogCount"
        :items-per-page="getInfoItemsPerPage"
        :page="getInfoPage"
        :options.sync="options"
        item-key="id"
        fixed-header
        :footer-props="{
          'show-first-last-page': true,
          'items-per-page-options': [10, 20, 30, 50, 100],
        }"
      />
      <v-card-actions class="justify-center">
        <v-btn v-on:click="fetchStartDate()" color="primary">{{
          $t("buttons.general.refresh")
        }}</v-btn>
      </v-card-actions>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AuditLogFullPage",
  computed: {
    ...mapGetters("auditLog", [
      "getLoading",
      "getInfoItemsPerPage",
      "getInfoPage",
      "getAuditLogs",
      "getAuditLogCount",
      "getAuditStartDate",
      "getUsersEmails",
      "getAllActions",
    ]),
  },
  data() {
    return {
      options: {},
      headers: [
        { text: "Date", value: "date", sortable: false },
        { text: "User", value: "user", sortable: false },
        { text: "Action", value: "action", sortable: false },
        { text: "Action Message", value: "action_message", sortable: false },
      ],
      searchUser: "",
      searchAction: "",
    };
  },
  watch: {
    options: {
      handler() {
        if (this.getAuditStartDate !== "") {
          this.updatePaginationInfo({
            page: this.options.page,
            itemsPerPage: this.options.itemsPerPage,
          });
        }
      },
      deep: true,
    },
  },
  beforeMount() {
    this.clearData();
    this.fetchStartDate();
  },
  methods: {
    ...mapActions("auditLog", [
      "clearData",
      "updatePaginationInfo",
      "setSearchDataAction",
      "fetchStartDate",
    ]),
  },
};
</script>

<style lang="scss"></style>
