import axios from "axios";

const store = {
  namespaced: true,

  state: {
    loading: true,
    DemandPartnerAccounts: [],
    active_winners: [],
    programmatic_channels: [],
    consent_type_target_options: [
      {
        value: "all_consent_types",
        name: "All Consent Types",
      },
      {
        value: "li_consent",
        name: "Continue without accepting",
      },
      {
        value: "accept_all",
        name: "Accept All",
      },
    ],
  },

  getters: {
    getLoading: state => {
      return state.loading;
    },
    getDemandPartnerAccounts: state => {
      return state.DemandPartnerAccounts;
    },
    getDemandPartnerAccountById: state => id => {
      return state.DemandPartnerAccounts.find(el => el.id === id) || {};
    },
    getDefaultDemandPartnerAccount: () => () => {
      return {
        id: 0,
        name: "",
        demand_partner_id: null,
        bidder_code: "",
        web_bidder_discount: 0.8,
        amp_bidder_discount: 0.8,
        default: false,
        prebidserver: false,
        consent_type_target: "all_consent_types",
      };
    },
    getActiveWinners(state) {
      return state.active_winners;
    },
    getProgrammaticChannels(state) {
      return state.programmatic_channels;
    },
    getConsentTypeTargetOptions(state) {
      return state.consent_type_target_options;
    },
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setDemandPartnerAccounts(state, DemandPartnerAccounts) {
      state.DemandPartnerAccounts = DemandPartnerAccounts.map(function(item) {
        if (!item.default_match_config)
          item.default_match_config = {
            winners: [],
            programmatic_channels: [],
          };
        item.winners = item.default_match_config.winners || [];
        item.programmatic_channels =
          item.default_match_config.programmatic_channels || [];

        if (item.custom_fields_config.static === null)
          item.custom_fields_config.static = [];
        if (item.custom_fields_config.global === null)
          item.custom_fields_config.global = [];
        if (item.custom_fields_config.by_adslot === null)
          item.custom_fields_config.by_adslot = [];

        if (!item.bidder_config) item.bidder_config = {};
        item.bidder_config_obj = item.bidder_config;
        return item;
      });
    },
    setActiveWinners(state, payload) {
      state.active_winners = payload.map(el => el.winner);
    },
    setProgrammaticChannels(state, payload) {
      state.programmatic_channels = payload.map(el => el.programmatic_channel);
    },
  },
  actions: {
    async fetchDemandPartnerAccounts({ commit }, payload) {
      commit("setLoading", true);
      return new Promise(resolve => {
        let fetchPromise = axios
          .get("/dpa/", { params: payload })
          .then(response => {
            commit("setDemandPartnerAccounts", response.data);
            commit("setLoading", false);
          });
        resolve(fetchPromise);
      });
    },
    async updateDemandPartnerAccount(
      { commit, dispatch },
      demandPartnerAccount
    ) {
      demandPartnerAccount.default_match_config.winners =
        demandPartnerAccount.winners;

      demandPartnerAccount.default_match_config.programmatic_channels =
        demandPartnerAccount.programmatic_channels;

      if (!demandPartnerAccount.type) demandPartnerAccount.type = "publisher";

      commit("setLoading", true);
      return new Promise(resolve => {
        axios
          .put("/dpa/", demandPartnerAccount)
          .then(response => {
            if (response.status !== 200) throw new Error("Bad request");
            if (response.data.status === "warning") {
              commit("warnings/addWarning", response.data.warnings, {
                root: true,
              });
              commit("setLoading", false);
              dispatch("fetchDemandPartnerAccounts");
              resolve(response.data);
            }
            if (response.status !== 200) throw new Error(response.data.msg);
            dispatch("fetchDemandPartnerAccounts");
            commit("setLoading", false);
            resolve(response.data);
          })
          .catch(err => {
            if (err.response.status !== 200) {
              let msg =
                "warnings" in err.response.data && err.response.data.warnings
                  ? err.response.data.warnings
                  : err.toString();
              commit("warnings/snackbarError", msg, {
                root: true,
              });
              commit("warnings/addWarning", msg, {
                root: true,
              });
            }
            dispatch("fetchDemandPartnerAccounts");
          });
      });
    },
    async createDemandPartnerAccount(
      { commit, dispatch },
      demandPartnerAccount
    ) {
      commit("setLoading", true);
      const response = await axios.post("/dpa/", demandPartnerAccount);
      if (response.status !== 200) {
        if (response.status !== 400) {
          throw new Error("Bad request");
        }
        if (response.data.status === "warning") {
          commit("warnings/addWarning", response.data.warnings, {
            root: true,
          });
        }
      }
      dispatch("fetchDemandPartnerAccounts");
      commit("setLoading", false);
      return response;
    },
    async deleteDemandPartnerAccount({ dispatch }, demandPartnerAccount) {
      return new Promise(resolve => {
        axios.delete("/dpa/" + demandPartnerAccount.id).then(response => {
          if (response.status !== 200) throw new Error("Bad request");
          if (response.data.status !== "success")
            throw new Error(response.data);
          dispatch("fetchDemandPartnerAccounts");
          resolve(response.data);
        });
      });
    },
    async fetchActiveWinners({ commit }) {
      commit("setLoading", true);
      return new Promise(resolve => {
        axios.get("/reporting_financial/winners_list").then(response => {
          commit("setActiveWinners", response.data);
          commit("setLoading", false);
        });
        resolve();
      });
    },
    async fetchProgrammaticChannels({ commit }) {
      commit("setLoading", true);
      return new Promise(resolve => {
        axios
          .get("/reporting_financial/programmatic_channel_list")
          .then(response => {
            commit("setProgrammaticChannels", response.data);
            commit("setLoading", false);
          });
        resolve();
      });
    },
    async updateDPABidder({ commit }, data) {
      commit("setLoading", true);
      const params = {
        dpa_id: data.id,
      };
      data.bidder_config = data.bidder_config_obj;

      return new Promise(resolve => {
        axios.put("/dpa_bidder/", data, { params }).then(response => {
          commit("setDPABidder", response.data);
          commit("setLoading", false);
          resolve();
        });
      });
    },
  },
};

export default store;
