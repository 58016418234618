<template>
  <div>
    <LeftSideMenu v-if="!isExtern()" />
    <ExternLeftSideMenu v-else />

    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
    <WarningsDialog />
  </div>
</template>

<script>
import LeftSideMenu from "../components/LeftSideMenu.vue";
import WarningsDialog from "../components/Helpers/WarningsDialog";
import { mapGetters } from "vuex";
import ExternLeftSideMenu from "@/components/Extern/ExternLeftSideMenu.vue";

export default {
  name: "Layout",
  components: {
    ExternLeftSideMenu,
    LeftSideMenu,
    WarningsDialog,
  },
  computed: {
    ...mapGetters("context", ["getUserProfile"]),
  },
  methods: {
    isExtern() {
      const user = this.getUserProfile;
      return user.role === "extern";
    },
  },
};
</script>

<style scoped></style>
