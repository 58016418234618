<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-card-title>{{
          $tc("demand_partner_accounts.demand_partner_account", 2)
        }}</v-card-title>
      </v-row>
      <DemandPartnerAccountsTable />
    </v-col>
  </v-row>
</template>

<script>
import DemandPartnerAccountsTable from "../../components/Accounting/DemandPartnerAccounts/DemandPartnerAccountsTable";

export default {
  name: "DemandPartnerAccounts",
  components: { DemandPartnerAccountsTable },
};
</script>

<style scoped></style>
