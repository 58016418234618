<template>
  <v-card>
    <v-card-title>Releases Audit Log</v-card-title
    ><v-row align="center" justify="space-around">
      <v-col cols="4">
        <v-row>
          <v-text-field
            label="Search action"
            v-model="search_action"
            v-on:change="
              () => {
                this.setAuditLogSearch(this.search_action);
                this.options.page = 1;
              }
            "
            :loading="getLoading"
          />
          <v-icon
            :disabled="!search_action"
            color="red darken-3"
            @click="
              () => {
                this.search_action = '';
                this.options.page = 1;
                this.setAuditLogSearch(this.search_action);
              }
            "
            icon
          >
            mdi-close-circle
          </v-icon>
        </v-row>
      </v-col>
    </v-row>
    <v-card-text>
      <v-data-table
        height="80vh"
        :items="getAuditLog"
        :loading="getLoading"
        :headers="headers"
        fixed-header
        :server-items-length="getAuditLogCount"
        :items-per-page="100"
        :page="this.getAuditLogPage"
        :options.sync="options"
        item-key="id"
        :footer-props="{
          'items-per-page-options': [100],
          'show-first-last-page': true,
        }"
      >
        <template v-slot:[`item.view_context`]="{ item }">
          <v-btn depressed icon large class="mx-4" @click="viewItem(item)">
            <v-icon small>
              visibility
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.context`]="{ item }">
          <p class="truncate">{{ item.context }}</p>
        </template>
      </v-data-table>
      <v-dialog v-model="dialog" max-width="60%">
        <v-card>
          <v-card-title>
            <v-icon class="ma-4" small>visibility</v-icon>
            Context view for action {{ viewedItem.action }} at
            {{ viewedItem.date }}
          </v-card-title>
          <v-card-text>
            <v-textarea
              no-resize
              rows="20"
              class="textarea"
              outlined
              readonly
              label="JSON"
              v-model="viewedItem.context"
            />
          </v-card-text>
          <v-card-actions
            ><v-spacer />
            <v-btn color="primary" @click="closeDialog()"
              >Close</v-btn
            ></v-card-actions
          >
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      viewedItem: {},
      dialog: false,
      options: {},
      search_action: "",
      headers: [
        { text: "ID", value: "id" },
        { text: "Datetime", value: "date" },
        { text: "Action", value: "action" },
        { text: "Context", value: "context" },
        { text: "View Context", value: "view_context" },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.updatePaginationInfo({
          page: this.options.page,
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchAuditLog(this.options.page);
  },
  computed: {
    ...mapGetters("releasesCrusader", [
      "getAuditLog",
      "getLoading",
      "getAuditLogCount",
      "getAuditLogPage",
    ]),
  },
  methods: {
    ...mapActions("releasesCrusader", [
      "fetchAuditLog",
      "setAuditLogSearch",
      "updatePaginationInfo",
    ]),
    viewItem(item) {
      this.viewedItem = item;
      this.dialog = true;
    },
    closeDialog() {
      this.viewedItem = {};
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.truncate {
  width: 25vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
