import axios from "axios";

const store = {
  namespaced: true,

  state: {
    loading: true,
    inventory_type: "WEB",
    text_a: "",
    text_b: "",
    revision_a: {},
    revision_b: {},
    revisions_a_list: [],
  },

  getters: {
    getLoading: state => state.loading,
    getDiffToolURL: () => process.env.VUE_APP_DIFF_APP_URL,
    getInventoryType: state => state.inventory_type,
    getTextA: state => (state.text_a && state.text_b ? state.text_a : ""),
    getTextB: state => (state.text_a && state.text_b ? state.text_b : ""),
    getRevisionA: state => state.revision_a,
    getRevisionB: state => state.revision_b,
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setTextA(state, rendered_text) {
      state.text_a = rendered_text;
    },
    setTextB(state, rendered_text) {
      state.text_b = rendered_text;
    },
    setRevisionsListA(state, revisionsList) {
      state.revisionsList = revisionsList.sort((a, b) => b.number - a.number);
    },
    setRevisionsListB(state, revisionsList) {
      state.revisionsList = revisionsList.sort((a, b) => b.number - a.number);
    },
    setRevisionInfoA(state, renderData) {
      state.inventory_type = renderData.inventory_type;
      state.revision_a.inventory_type = renderData.inventory_type;
      state.revision_a.revision = renderData.revision;
      state.revision_a.revision_number = renderData.revision.number;
      state.revision_a.site = renderData.site;
      state.revision_a.site_id = renderData.site.id;
    },
    setRevisionInfoB(state, renderData) {
      state.revision_b.inventory_type = renderData.inventory_type;
      state.revision_b.revision = renderData.revision;
      state.revision_b.revision_number = renderData.revision.number;
      state.revision_b.site = renderData.site;
      state.revision_b.site_id = renderData.site.id;
    },
  },

  actions: {
    async selectRevisionInfoA({ commit, getters }, payload) {
      commit("setRevisionInfoA", payload);
      payload = {
        site_id: getters.getRevisionA.site.id,
        inventory_type: getters.getRevisionA.inventory_type,
        revision: getters.getRevisionA.revision.number,
      };
      return new Promise((resolve, reject) => {
        axios
          .get("/ad_manager/config_renderer", {
            params: payload,
            transformResponse: [data => data],
          })
          .then(response => {
            if (response.status !== 200) throw new Error(response.toString());
            commit("setTextA", response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    async fetchRevisionsList({ commit }, payload) {
      let params = {
        site_id: payload.site_id,
        inventory_type: payload.inventory_type,
      };
      return new Promise((resolve, reject) => {
        axios
          .get("/tags_manager/config_info", {
            params,
          })
          .then(response => {
            commit("setLoading", false);
            if (response.data.revisions.length === 0) resolve([]);
            else
              resolve(
                response.data.revisions.sort((a, b) => b.number - a.number)
              );
          })
          .catch(reject);
      });
    },
    async selectRevisionInfoB(
      { commit, getters },
      payload = {
        site_id: null,
        site: null,
        inventory_type: getters.getInventoryType,
        revision: null,
        revision_number: null,
      }
    ) {
      commit("setTextB", "");
      commit("setRevisionInfoB", payload);
      payload = {
        site_id: getters.getRevisionB.site.id,
        inventory_type: getters.getRevisionB.inventory_type,
        revision: getters.getRevisionB.revision.number,
      };
      return new Promise((resolve, reject) => {
        axios
          .get("/ad_manager/config_renderer", {
            params: payload,
            transformResponse: [data => data],
          })
          .then(response => {
            if (response.status !== 200) throw new Error(response.toString());
            commit("setTextB", response.data);
            resolve(response.data);
          })
          .catch(e => {
            commit("setTextB", "");
            reject(e);
          })
          .finally();
      });
    },
  },
};

export default store;
