<template>
  <div>
    <v-col>
      <v-row class="ma-9">
        <v-col cols="12">
          <v-form v-model="siteAutocompleteAndInventoryTypeValid">
            <v-container>
              <v-row align="center" justify="center" class="text-center">
                <v-col cols="12">
                  <v-row>
                    <v-col cols="4">
                      <v-autocomplete
                        v-model="siteAutocompleteModel"
                        :items="getSiteEntities"
                        item-text="name"
                        return-object
                        label="Site"
                        :rules="baseValidation.required"
                        @change="onConfigSelectsUpdate"
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        v-model="inventoryTypeSelectModel"
                        :items="getInventoryTypeList()"
                        :rules="baseValidation.required"
                        label="Inventory Type"
                        @change="onConfigSelectsUpdate"
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        :items="getRevisionsList"
                        v-model="revisionSelectModel"
                        label="Revision"
                        :disabled="!isSiteAndInventoryTypeSelected"
                        no-data-text="No revision for this site and inventory version"
                        return-object
                        outlined
                        @change="onRevisionUpdate"
                      >
                        <template v-slot:selection="{ parent, item }">
                          {{ item.number }}
                          <v-chip small :color="mapStatusColor(item.status)">{{
                            item.status
                          }}</v-chip>
                          {{ item.name }}
                        </template>
                        <template v-slot:item="{ parent, item }">
                          {{ item.number }}
                          <v-chip small :color="mapStatusColor(item.status)">{{
                            item.status
                          }}</v-chip>
                          {{ item.name }}
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row align="center" justify="center">
                    <v-col cols="auto">
                      <v-btn
                        :disabled="getRenderedText.length === 0"
                        @click="copyText"
                        value="Copy Config"
                        append
                      >
                        Copy
                        <v-icon right>mdi-content-copy</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="auto" hidden>
                      <v-btn
                        :disabled="
                          getRenderedText.length === 0 || !isConfigRendered
                        "
                        color="primary"
                        append
                        @click="onDownload"
                      >
                        Download Render
                        <v-icon right>mdi-download</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="auto">
                      <v-btn
                        :disabled="!isRenderDataSelected"
                        color="primary"
                        @click="onRender"
                      >
                        Render
                      </v-btn>
                    </v-col>
                    <v-col cols="auto">
                      <v-btn
                        v-if="
                          getUserProfile.role === 'admin' &&
                            (
                              getRendererDataInventoryType || ''
                            ).toLowerCase() === 'web'
                        "
                        :disabled="!isRenderDataSelected"
                        color="primary"
                        @click="onODASRender"
                      >
                        ODAS Render
                      </v-btn>
                    </v-col>
                    <v-col cols="auto">
                      <v-menu
                        v-model="ad_unit_menu"
                        :close-on-content-click="false"
                        bottom
                        offset-y
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            :disabled="getAdUnits.length === 0"
                            color="primary"
                            v-bind="attrs"
                            v-on="on"
                          >
                            Generate Tags
                          </v-btn>
                        </template>

                        <v-card class="justify-center">
                          <v-list one-line flat dense>
                            <v-list-item-group v-model="ad_unit_selection">
                              <v-list-item
                                v-for="ad_unit in getAdUnits"
                                v-bind:key="ad_unit.id"
                              >
                                <template v-slot:default="{ active }">
                                  <v-list-item-action>
                                    <v-checkbox
                                      :input-value="active"
                                      color="primary"
                                    />
                                  </v-list-item-action>

                                  <v-list-item-content>
                                    <v-list-item-title>{{
                                      ad_unit.name
                                    }}</v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>

                          <v-card-actions>
                            <v-btn
                              :disabled="
                                !isRenderDataSelected ||
                                  ad_unit_selection === undefined
                              "
                              color="primary"
                              text
                              v-on:click="generateTags(false)"
                            >
                              Generate
                            </v-btn>
                            <v-btn
                              :disabled="
                                !isRenderDataSelected ||
                                  ad_unit_selection === undefined
                              "
                              color="primary"
                              text
                              @click="generateTags(true)"
                            >
                              Download tags
                              <v-icon right>mdi-download</v-icon>
                            </v-btn>
                            <v-btn text @click="ad_unit_menu = false">
                              Cancel
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-col>
      </v-row>
      <v-row class="ma-9 align-center justify-center">
        <v-textarea
          v-if="getRenderedText.length !== 0"
          id="exported_file"
          readonly
          solo
          auto-grow
          filled
          :value="getRenderedText"
        >
          <template v-slot:prepend />
        </v-textarea>
      </v-row>
    </v-col>

    <v-snackbar v-model="snackbar" top :timeout="5000" :color="snackbar_color">
      {{ snackbar_text }}
      <v-btn text @click="hideAlert()">
        Close
      </v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import store from "./../../../store/index";
import { baseValidation } from "@/validation/baseValidation";
import { validationHelper } from "@/validation/validationHelper";
import { mapStatusColor } from "@/helpers/mapStatusColorMixin";
import { eventBus } from "@/main";
export default {
  name: "AdConfigRenderView",
  mixins: [baseValidation, validationHelper, mapStatusColor],
  data() {
    return {
      ad_unit_menu: false,
      ad_unit_selection: [],
      ad_unit_id: undefined,
      ad_config_id: null,
      snackbar: false,
      snackbar_text: "",
      snackbar_color: "error",
      siteAutocompleteAndInventoryTypeValid: false,
      siteAutocompleteModel: null,
      inventoryTypeSelectModel: null,
      revisionSelectModel: null,
    };
  },
  async mounted() {
    await this.fetchSiteEntities();
    this.setRenderedText("");
    if (
      this.$route.query.site &&
      this.$route.query.inventory_type &&
      "ad_unit" in this.$route.query &&
      this.$route.query.ad_unit
    ) {
      this.ad_unit_id = this.$route.query.ad_unit.id;
      this.siteAutocompleteModel = this.getSiteEntities.find(item => {
        return item.id === Number(this.$route.query.site);
      });
      this.inventoryTypeSelectModel = this.$route.query.inventory_type;

      await this.fetchSiteConfigInfo({
        site: { ...this.siteAutocompleteModel },
        inventoryType: this.inventoryTypeSelectModel,
        setNewestRevision: false,
      });
      if (this.$route.query.revision) {
        this.revisionSelectModel = this.getRevisionsList.find(item => {
          return item.number === Number(this.$route.query.revision);
        });
      } else {
        if (this.getRevisionsList.length !== 0) {
          this.revisionSelectModel = this.getNewestRevision();
        }
      }
      await this.onRevisionUpdate();
      this.onTagGenerate();
    } else if (this.$route.query.site && this.$route.query.inventory_type) {
      this.siteAutocompleteModel = this.getSiteEntities.find(item => {
        return item.id === Number(this.$route.query.site);
      });
      this.inventoryTypeSelectModel = this.$route.query.inventory_type;

      let selectConfigData = {
        site: { ...this.siteAutocompleteModel },
        inventoryType: this.inventoryTypeSelectModel,
        setNewestRevision: false,
      };
      await this.fetchSiteConfigInfo(selectConfigData);
      if (this.$route.query.revision) {
        this.revisionSelectModel = this.getRevisionsList.find(item => {
          return item.number === Number(this.$route.query.revision);
        });
      } else {
        if (this.getRevisionsList.length !== 0) {
          this.revisionSelectModel = this.getNewestRevision();
        }
      }
      await this.onRevisionUpdate();
      this.onRender();
    }
  },
  computed: {
    ...mapGetters("siteEntities", ["getLoading", "getSiteEntities"]),
    ...mapGetters("adUnits", ["getAdUnits"]),
    ...mapGetters("context", ["getUserProfile"]),
    ...mapGetters("configLoaderRenderer", [
      "getRenderedText",
      "getRevisionsList",
      "isSiteAndInventoryTypeSelected",
      "getNewestRevision",
      "getRendererDataRevision",
      "isRenderDataSelected",
      "getRendererDataSite",
      "getRendererDataInventoryType",
      "isConfigRendered",
    ]),
    ...mapGetters("tagsManager", ["getInventoryTypeList", "getNewestRevision"]),
  },
  methods: {
    ...mapMutations("configLoaderRenderer", [
      "setRenderedText",
      "setRendererConfigData",
    ]),
    ...mapActions("configLoaderRenderer", [
      "fetchSiteConfigInfo",
      "downloadAdConfig",
      "downloadTags",
    ]),
    ...mapActions("siteEntities", ["fetchSiteEntities"]),
    ...mapActions("adUnits", ["fetchAdUnits"]),
    copyText() {
      let textToCopy = document.querySelector("#exported_file");
      textToCopy.select();

      try {
        const successful = document.execCommand("copy");
        const msg = successful ? "successfully" : "unsuccessfully";
        this.showInfo("Text was copied " + msg);
      } catch (err) {
        this.showAlert("Oops, unable to copy");
      }

      window.getSelection().removeAllRanges();
    },
    async onConfigSelectsUpdate() {
      if (this.inventoryTypeSelectModel && this.siteAutocompleteModel) {
        let selectConfigData = {
          site: { ...this.siteAutocompleteModel },
          inventoryType: this.inventoryTypeSelectModel,
          setNewestRevision: true,
        };
        await this.fetchSiteConfigInfo(selectConfigData).then(() => {
          this.revisionSelectModel = this.getRendererDataRevision;
        });
        await this.fetchAdUnits(true);
      }
      let revision = null;
      if (this.revisionSelectModel) {
        revision = this.revisionSelectModel.number;
      }
      await this.$router
        .push({
          query: {
            site: this.siteAutocompleteModel.id,
            inventory_type: this.inventoryTypeSelectModel,
            revision,
          },
        })
        .catch(() => {});
    },
    async onRevisionUpdate() {
      if (this.revisionSelectModel) {
        let selectConfigData = {
          revision: this.revisionSelectModel,
          site: { ...this.siteAutocompleteModel },
          inventoryType: this.inventoryTypeSelectModel,
          setNewestRevision: false,
        };
        await this.fetchSiteConfigInfo(selectConfigData).then(() => {
          this.revisionSelectModel = this.getRendererDataRevision;
        });
      }
      let revision = null;
      if (this.revisionSelectModel) {
        revision = this.revisionSelectModel.number;
      }
      await this.fetchAdUnits(true);
      await this.$router
        .push({
          query: {
            site: this.siteAutocompleteModel.id,
            inventory_type: this.inventoryTypeSelectModel,
            revision,
            ad_unit: this.$route.query.ad_unit,
          },
        })
        .catch(() => {});
    },
    onRender() {
      store
        .dispatch("configLoaderRenderer/renderAdConfig")
        .then(() => this.showInfo("Successfully rendered config"))
        .catch(e => this.showAlert(e));
    },
    onODASRender() {
      store
        .dispatch("configLoaderRenderer/renderODASStoredRequest")
        .then(() => this.showInfo("Successfully rendered odas sr"))
        .catch(e => this.showAlert(e));
    },
    onDownload() {
      this.downloadAdConfig()
        .then(() => this.showInfo("Successfully downloaded config"))
        .catch(e => this.showAlert(e));
    },
    onDownloadTags() {
      this.downloadTags(this.ad_unit_id)
        .then(() => this.showInfo("Successfully downloaded tags"))
        .catch(e => this.showAlert(e));
    },
    async generateTags(download = false) {
      let unit = { ...this.getAdUnits[this.ad_unit_selection] } || undefined;
      if (!unit) return (this.ad_unit_menu = false);
      this.ad_unit_id = unit.id;
      this.onTagGenerate();
      if (download) {
        this.onDownloadTags();
      }

      this.ad_unit_menu = false;
    },
    onTagGenerate() {
      store
        .dispatch("configLoaderRenderer/generateTags", this.ad_unit_id)
        .then(() => this.showInfo("Successfully rendered config"))
        .catch(e => {
          this.showAlert(e);
          eventBus.$emit("action_warning", e);
        });
    },
    showAlert(e) {
      this.snackbar = true;
      this.snackbar_color = "error";
      this.snackbar_text = e.toString();
    },
    showInfo(e) {
      this.snackbar = true;
      this.snackbar_color = "info";
      this.snackbar_text = e.toString();
    },
    hideAlert() {
      this.snackbar = false;
    },
  },
};
</script>

<style scoped></style>
