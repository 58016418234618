import axios from "axios";

const store = {
  namespaced: true,

  state: {
    loading: false,
    dpa_id: null,
    date: null,
    financialReportMain: {
      table: [],
      total_estimated: 0,
      total_final: 0,
    },
    financialReportDialog: {
      sessionId: -1,
      modsCount: -1,
      dpa: {
        total_estimated: 0,
        total_final: 0,
        correction_ratio_global: 0,
        edited: false,
      },
      table: {
        table: [],
        total_estimated: 0,
        total_final: 0,
        correction_ratio_global: 0,
        is_table_valid: true,
      },
    },
  },

  getters: {
    getLoading: state => {
      return state.loading;
    },
    areMoneyModsCorrect: state => {
      return state.financialReportDialog.table.is_table_valid;
    },
    anyModsUnsaved: state => {
      return state.financialReportDialog.modsCount !== 0;
    },
    getFinancialReportMain: state => {
      return state.financialReportMain;
    },
    getFinancialReportDialogDpa: state => {
      return state.financialReportDialog.dpa;
    },
    getFinancialReportDialogTable: state => {
      return state.financialReportDialog.table;
    },
    getDefaultModObject: () => () => {
      return {
        id: 0,
        year: -1,
        month: -1,
        publisher_id: -1,
        demand_partner_id: -1,
        demand_partner_account: -1,
        turnover_manual: 1,
        site_entity_id: -1,
      };
    },
    getDPAId: state => state.dpa_id,
    getDate: state => state.date,
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setSession(state, payload) {
      state.financialReportDialog.sessionId = payload;
    },
    setModsCount(state, payload) {
      state.financialReportDialog.modsCount = payload;
    },
    setFinancialReportsMain(state, payload) {
      state.financialReportMain.table = payload.rows;
      state.financialReportMain.total_estimated = payload.total_estimated;
      state.financialReportMain.total_final = payload.total_final;
    },
    setFinancialReportDialogDpa(state, payload) {
      state.financialReportDialog.dpa.total_estimated = payload.total_estimated;
      state.financialReportDialog.dpa.total_final = payload.total_final;
      state.financialReportDialog.dpa.correction_ratio_global =
        payload.correction_ratio_global;
      state.financialReportDialog.dpa.edited = payload.edited;
    },
    setFinancialReportDialogTable(state, payload) {
      payload.rows.forEach((item, index) => {
        item.index = index;
        item.correction_insert = false;
        item.correction_input = false;
        if (item.status === "insertion input") {
          item.correction_insert = true;
        }
        if (item.status === "correction input") {
          item.correction_input = true;
        }
      });
      state.financialReportDialog.table.table = payload.rows;
      state.financialReportDialog.table.total_estimated =
        payload.total_estimated;
      state.financialReportDialog.table.total_final = payload.total_final;
      state.financialReportDialog.table.correction_ratio_global =
        payload.correction_ratio_global;
      state.financialReportDialog.table.is_table_valid = payload.is_table_valid;
    },
    setDPAId(state, dpa_id) {
      state.dpa_id = dpa_id;
    },
    setDate(state, date) {
      state.date = date;
    },
  },

  actions: {
    async fetchFinancialReportMain({ commit, getters }) {
      const d = getters.getDate;
      if (!d) return null;

      const year = Number(d.substr(0, 4)) || null;
      const month = Number(d.substr(5, 7)) || null;
      const demand_partner_id = getters.getDPAId || null;
      if (!year || !month) return null;
      const payload = { year, month, demand_partner_id };
      commit("setLoading", true);
      return new Promise(resolve => {
        axios
          .get("/reporting_financial/main", { params: payload })
          .then(response => {
            commit("setFinancialReportsMain", response.data.data);
            commit("setLoading", false);
          });
        resolve();
      });
    },
    async fetchFinancialReportDialog({ commit }, payload) {
      commit("setLoading", true);
      return new Promise(resolve => {
        let dpa_promise = axios
          .get("/reporting_financial/dpa", { params: payload })
          .then(response => {
            commit("setFinancialReportDialogDpa", response.data.data);
            commit("setLoading", false);
          });
        let pub_promise = axios
          .get("/reporting_financial/pub", { params: payload })
          .then(response => {
            commit("setFinancialReportDialogTable", response.data.data);
            commit("setLoading", false);
          });
        resolve(Promise.all([dpa_promise, pub_promise]));
      });
    },
    async sendMod({ commit, state, dispatch }, payload) {
      commit("setLoading", true);
      payload.session_id = state.financialReportDialog.sessionId;
      return new Promise(resolve => {
        let put_promise = axios
          .put("/reporting_turnover_mods/siteMod", payload)
          .then(() => {
            dispatch("countSessionMods");
          })
          .then(() => {
            commit("setLoading", false);
          });
        resolve(put_promise);
      });
    },
    async removeMod({ commit, dispatch }, payload) {
      commit("setLoading", true);
      return new Promise(resolve => {
        let delete_promise = axios
          .delete("/reporting_turnover_mods/siteMod", {
            data: payload,
          })
          .then(() => {
            dispatch("countSessionMods");
          })
          .then(() => {
            commit("setLoading", false);
          });
        resolve(delete_promise);
      });
    },
    async getSession({ commit, dispatch }) {
      commit("setLoading", true);
      return new Promise(resolve => {
        let p = axios
          .get("/reporting_turnover_mods/siteModSession", {
            params: {
              action: "getsession",
            },
          })
          .then(response => {
            commit("setSession", response.data.session_id);
            commit("setLoading", false);
          })
          .then(() => {
            dispatch("countSessionMods");
          });
        resolve(p);
      });
    },
    async saveSession({ commit, state, dispatch }) {
      commit("setLoading", true);
      return new Promise(resolve => {
        let p = axios
          .get("/reporting_turnover_mods/siteModSession", {
            params: {
              action: "save",
              session_id: state.financialReportDialog.sessionId,
            },
          })
          .then(() => {
            dispatch("getSession");
          })
          .then(() => {
            commit("setLoading", false);
          });
        resolve(p);
      });
    },
    async cancelSession({ commit, state, dispatch }) {
      commit("setLoading", true);
      return new Promise(resolve => {
        let p = axios
          .get("/reporting_turnover_mods/siteModSession", {
            params: {
              action: "cancel",
              session_id: state.financialReportDialog.sessionId,
            },
          })
          .then(() => {
            dispatch("getSession");
          })
          .then(() => {
            commit("setLoading", false);
          });
        resolve(p);
      });
    },
    async countSessionMods({ commit, state }) {
      return new Promise(resolve => {
        let p = axios
          .get("/reporting_turnover_mods/siteModSession", {
            params: {
              action: "modscount",
              session_id: state.financialReportDialog.sessionId,
            },
          })
          .then(response => {
            commit("setModsCount", response.data.modsCount);
            commit("setLoading", false);
          });
        resolve(p);
      });
    },
    async validateReport({ commit, state }, payload) {
      commit("setLoading", true);
      payload.session_id = state.financialReportDialog.sessionId;
      return new Promise(resolve => {
        let post_promise = axios
          .post("/reporting_turnover_mods/validateReport", null, {
            params: payload,
          })
          .then(() => {
            commit("setLoading", false);
          });
        resolve(post_promise);
      });
    },
    async deValidateReport({ commit, state }, payload) {
      commit("setLoading", true);
      payload.session_id = state.financialReportDialog.sessionId;
      return new Promise(resolve => {
        let post_promise = axios
          .delete("/reporting_turnover_mods/validateReport", {
            params: payload,
          })
          .then(() => {
            commit("setLoading", false);
          });
        resolve(post_promise);
      });
    },
    setDPA({ commit }, payload) {
      commit("setDPAId", payload);
    },
    setSelectedDate({ commit }, payload) {
      commit("setDate", payload);
    },
  },
};

export default store;
