<template>
  <FileDiffTool />
</template>

<script>
import FileDiffTool from "../../components/Helpers/FileDiffTool.vue";

export default {
  name: "FileDiffPage",
  components: { FileDiffTool },
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style scoped></style>
