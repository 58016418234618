import axios from "axios";

const store = {
  namespaced: true,

  state: {
    loading: false,
    paymentTerms: [],
  },

  getters: {
    getLoading: state => {
      return state.loading;
    },
    getPaymentTerms: state => {
      return state.paymentTerms;
    },
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },

    setPaymentTerms(state, paymentTerm) {
      state.paymentTerms = paymentTerm;
    },
  },

  actions: {
    async fetchPaymentTerms({ commit }) {
      commit("setLoading", true);
      const response = await axios.get("/payment_term/all");
      commit("setPaymentTerms", response.data);
      commit("setLoading", false);
    },
  },
};

export default store;
