<template>
  <v-row>
    <v-col cols="12">
      <v-data-table
        height="calc(100vh - 250px)"
        :loading="getLoading"
        :headers="headers"
        :search="search"
        :items="getDemandPartnerAccountsWithRelations"
        item-key="id"
        fixed-header
        :items-per-page="30"
        :footer-props="{
          'show-first-last-page': true,
          'items-per-page-options': [10, 20, 30, 50, 100],
        }"
      >
        <template v-slot:top>
          <v-row><v-divider /></v-row>
          <v-row align="center" justify="space-around" class="pb-5">
            <v-col cols="3" class="text-center">
              <v-btn
                color="primary"
                v-on:click="
                  editDemandPartnerAccount(getDefaultDemandPartnerAccount())
                "
                >{{ $t("buttons.demand_partner_account.add") }}
                <v-icon dark right>add_box</v-icon></v-btn
              >
            </v-col>
            <v-divider vertical inset />
            <v-col cols="6">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('buttons.general.search')"
                single-line
                hide-details
              />
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.name="{ item }">
          <p>{{ getDpaName(item) }}</p>
        </template>
        <template v-slot:item.action="{ item }">
          <div style="white-space: nowrap">
            <v-btn depressed icon @click="editDemandPartnerAccount(item)">
              <v-icon small>
                edit
              </v-icon>
            </v-btn>
            <v-btn depressed icon @click="open_config_dialog(item)">
              <v-icon small>
                settings
              </v-icon>
            </v-btn>
            <v-btn depressed icon @click="showDemandPartnerAccount(item)">
              <v-icon small>
                visibility
              </v-icon>
            </v-btn>
            <v-btn depressed icon @click="showDemandPartnerBidderConfig(item)">
              <v-icon small>
                more
              </v-icon>
            </v-btn>
            <v-btn depressed icon @click="onArchive(item)">
              <v-icon small color="error">
                delete
              </v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
      <v-dialog v-model="dialog" max-width="80%">
        <v-card style="overflow-x: hidden;">
          <v-row align="center">
            <v-col cols="auto">
              <v-card-title>
                <span v-if="!readOnly && editedIndex !== -1" class="headline"
                  >{{ $t("buttons.demand_partner_account.edit") }}:</span
                >
                <span
                  v-else-if="readOnly && editedIndex !== -1"
                  class="headline"
                  >{{ $t("buttons.demand_partner_account.show") }}:</span
                >
                <span v-else class="headline"
                  >{{ $t("buttons.demand_partner_account.add") }}:</span
                >
              </v-card-title>
            </v-col>
            <v-spacer />
            <v-col cols="auto">
              <v-btn
                v-if="!readOnly"
                :disabled="!valid"
                color="blue darken-1"
                text
                @click="save"
              >
                {{ $t("buttons.general.save") }}</v-btn
              ><v-btn color="blue darken-1" text @click="close">{{
                $t("buttons.general.close")
              }}</v-btn>
            </v-col>
          </v-row>
          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row justify="start">
                  <v-col cols="auto">
                    <v-switch
                      v-model="editedItem.default"
                      :false-value="false"
                      :true-value="true"
                      label="Default Demand Partner Account"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :readonly="readOnly"
                      :filled="readOnly"
                      v-model="editedItem.name"
                      label="Name"
                      :rules="createRules([baseValidation.required])"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-autocomplete
                      :readonly="readOnly"
                      :filled="readOnly"
                      :items="getDemandPartners"
                      item-text="name"
                      item-value="id"
                      :label="
                        $t('demand_partner_accounts.select_demand_partner')
                      "
                      v-model="editedItem.demand_partner_id"
                      :rules="createRules([baseValidation.required])"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select
                      :readonly="readOnly"
                      required
                      :filled="readOnly"
                      :items="dpa_types_list"
                      label="Type"
                      v-model="editedItem.type"
                      :rules="createRules([baseValidation.required])"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :readonly="readOnly"
                      :filled="readOnly"
                      v-model="editedItem.bidder_code"
                      label="Bidder code"
                      :rules="createRules([baseValidation.required])"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :readonly="readOnly"
                      :filled="readOnly"
                      v-model.number="editedItem.web_bidder_discount"
                      label="Web discount"
                      :rules="createRules([baseValidation.required])"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :readonly="readOnly"
                      :filled="readOnly"
                      v-model.number="editedItem.amp_bidder_discount"
                      label="Amp discount"
                      :rules="createRules([baseValidation.required])"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :readonly="readOnly"
                      :filled="readOnly"
                      v-model="editedItem.bidder_alias"
                      chips
                      label="Bidder alias"
                      no-filter
                      multiple
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select
                      :readonly="readOnly"
                      required
                      :filled="readOnly"
                      :items="getConsentTypeTargetOptions"
                      item-value="value"
                      item-text="name"
                      v-model="editedItem.consent_type_target"
                      label="Consent Type Target"
                      :rules="createRules([baseValidation.required])"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select
                      :readonly="readOnly"
                      :filled="readOnly"
                      required
                      :items="getProducts"
                      item-value="id"
                      item-text="name"
                      :label="$t('product.select_product')"
                      v-model="editedItem.product_id"
                      :rules="createRules([baseValidation.required])"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-textarea
                      label="Ads.txt"
                      v-model="ads_txt_text"
                      :loading="getAdsTxtLoading"
                      :rows="10"
                      no-resize
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              v-if="!readOnly"
              :disabled="!valid"
              color="blue darken-1"
              text
              @click="save"
            >
              {{ $t("buttons.general.save") }}</v-btn
            ><v-btn color="blue darken-1" text @click="close">{{
              $t("buttons.general.close")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="configDialog" max-width="500px">
        <v-card style="overflow-x: hidden;">
          <v-container class="px-6">
            <v-card elevation="5" disabled>
              <v-card-title>Default config</v-card-title>
              <v-card-text class="ma-2" elevation="-5">{{
                getDemandPartnerAccountById(dpa_id).default_match_config
              }}</v-card-text>
            </v-card>
          </v-container>
          <v-container class="px-6">
            <v-card elevation="5" class="" style="overflow-x: hidden;">
              <v-list dense>
                <v-subheader>{{
                  $t("publisher_demand_partner.winners_list")
                }}</v-subheader>

                <v-list-item
                  v-for="winner in getDemandPartnerAccountById(dpa_id).winners"
                  :key="winner"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="winner" />
                  </v-list-item-content>

                  <v-list-item-icon>
                    <v-btn
                      depressed
                      icon
                      large
                      class="mx-4"
                      @click="
                        remove_winner(
                          getDemandPartnerAccountById(dpa_id),
                          winner
                        )
                      "
                    >
                      <v-icon small>
                        remove
                      </v-icon>
                    </v-btn>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
              <v-row><v-divider class="my-4"/></v-row>
              <v-row class="mx-1">
                <v-col cols="9"
                  ><v-combobox
                    ref="dpa_winners_combobox"
                    :items="getActiveWinners"
                    item-text="winner"
                    :label="$t('publisher_demand_partner.winner_input')"
                    v-model="winner"
                /></v-col>
                <v-col cols="3">
                  <v-btn
                    class="ma-4"
                    color="primary"
                    depressed
                    @click="
                      push_win(getDemandPartnerAccountById(dpa_id), winner)
                    "
                    >{{ $t("buttons.general.add")
                    }}<v-icon>mdi-arrow</v-icon></v-btn
                  ></v-col
                >
              </v-row>
            </v-card>
          </v-container>
          <v-container class="px-6">
            <v-card elevation="5" style="overflow-x: hidden;">
              <v-list dense>
                <v-subheader>{{
                  $t("publisher_demand_partner.programmatic_channel_list")
                }}</v-subheader>
                <v-list-item
                  v-for="programmatic_channel in getDemandPartnerAccountById(
                    dpa_id
                  ).programmatic_channels"
                  :key="programmatic_channel"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="programmatic_channel" />
                  </v-list-item-content>

                  <v-list-item-icon>
                    <v-btn
                      depressed
                      icon
                      large
                      class="mx-4"
                      @click="
                        remove_programmatic_channel(
                          getDemandPartnerAccountById(dpa_id),
                          programmatic_channel
                        )
                      "
                    >
                      <v-icon small>
                        remove
                      </v-icon>
                    </v-btn>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
              <v-row><v-divider class="my-4"/></v-row>
              <v-row class="mx-1">
                <v-col cols="9"
                  ><v-combobox
                    ref="dpat_programmatic_channel"
                    :items="getProgrammaticChannels"
                    item-text="programmatic_channel"
                    :label="
                      $t('publisher_demand_partner.programmatic_channel_input')
                    "
                    v-model="programmatic_channel"
                /></v-col>
                <v-col cols="3">
                  <v-btn
                    class="ma-4"
                    color="primary"
                    depressed
                    @click="
                      push_programmatic_channel(
                        getDemandPartnerAccountById(dpa_id),
                        programmatic_channel
                      )
                    "
                    >{{ $t("buttons.general.add")
                    }}<v-icon>mdi-arrow</v-icon></v-btn
                  ></v-col
                >
              </v-row>
            </v-card>
          </v-container>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              @click="saveConfig(getDemandPartnerAccountById(dpa_id))"
              >{{ $t("buttons.publisher_demand_partner.save_winners") }}</v-btn
            >
            <v-btn color="blue darken-1" text @click="close_config_dialog">{{
              $t("buttons.general.close")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="bidderDialog" max-width="80%">
        <v-card elevation="5" style="overflow-x: hidden;">
          <v-row align="center">
            <v-col cols="auto">
              <v-card-title>{{
                getDemandPartnerAccountById(dpa_id).name
              }}</v-card-title>
            </v-col>
            <v-spacer />
            <v-col cols="auto" class="mr-2">
              <v-spacer />
              <v-btn color="blue darken-1" text @click="save_bidder_dialog">
                {{ $t("buttons.general.save") }}</v-btn
              ><v-btn
                color="blue darken-1"
                text
                @click="close_bidder_dialog()"
                >{{ $t("buttons.general.close") }}</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" sm="6" md="4">
              <v-card-text class="ma-2" elevation="-5">
                <v-container>
                  <v-form
                    ref="adslot_form"
                    v-model="bidder_config_valid"
                    lazy-validation
                  >
                    <div v-if="custom_field_config.static.length === 0">
                      No dpa fields configured
                    </div>
                    <v-simple-table dense>
                      <tbody>
                        <tr
                          v-for="field in custom_field_config.static || []"
                          v-bind:key="field.key"
                        >
                          <td>{{ field.key }}</td>
                          <td>
                            <v-text-field
                              dense
                              v-if="field.type === 'number'"
                              v-model.number="bidder_config[field.key]"
                            /><v-text-field
                              dense
                              single-line
                              v-else-if="field.type === 'text'"
                              v-model="bidder_config[field.key]"
                            />
                          </td>
                        </tr></tbody
                    ></v-simple-table>
                  </v-form>
                </v-container>
              </v-card-text>
            </v-col>
            <v-col cols="8">
              <v-card-text class="ma-2" elevation="-5">
                <v-container>
                  <v-form
                    ref="adslot_form"
                    v-model="bidder_config_valid"
                    lazy-validation
                  >
                    <v-row>
                      <v-col>
                        <v-card-subtitle>DPA fields</v-card-subtitle>
                        <v-list dense
                          ><v-list-item
                            v-for="field in custom_field_config.static"
                            v-bind:key="field.key"
                            ><v-list-item-subtitle>{{
                              field.key
                            }}</v-list-item-subtitle>
                            {{ field.type }}
                            <v-list-item-action>
                              <v-btn
                                depressed
                                icon
                                class="mx-4"
                                @click="remove_custom_field('static', field)"
                              >
                                <v-icon small>
                                  remove
                                </v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item></v-list
                        >
                      </v-col>
                      <v-col>
                        <v-card-subtitle>Site fields</v-card-subtitle>
                        <v-list dense
                          ><v-list-item
                            v-for="field in custom_field_config.global"
                            v-bind:key="field.key"
                            ><v-list-item-subtitle>{{
                              field.key
                            }}</v-list-item-subtitle>
                            {{ field.type }}
                            <v-list-item-action>
                              <v-btn
                                depressed
                                icon
                                class="mx-4"
                                @click="remove_custom_field('global', field)"
                              >
                                <v-icon small>
                                  remove
                                </v-icon>
                              </v-btn>
                            </v-list-item-action></v-list-item
                          ></v-list
                        >
                      </v-col>
                      <v-col>
                        <v-card-subtitle>Adslot fields</v-card-subtitle>
                        <v-list dense
                          ><v-list-item
                            v-for="field in custom_field_config.by_adslot"
                            v-bind:key="field.key"
                            ><v-list-item-subtitle>{{
                              field.key
                            }}</v-list-item-subtitle>
                            {{ field.type }}
                            <v-list-item-action>
                              <v-btn
                                depressed
                                icon
                                class="mx-4"
                                @click="remove_custom_field('by_adslot', field)"
                              >
                                <v-icon small>
                                  remove
                                </v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item></v-list
                        >
                      </v-col>
                      <v-col
                    /></v-row>
                    <v-row>
                      <v-col>
                        <v-text-field
                          label="New field key"
                          v-model="new_key_static"
                        />
                        <v-select
                          label="Type"
                          :items="types"
                          v-model="new_type_static"
                        />
                        <v-btn
                          @click="
                            new_key_static && new_type_static
                              ? custom_field_config.static.push({
                                  key: new_key_static,
                                  type: new_type_static,
                                })
                              : null
                          "
                          >Add</v-btn
                        >
                      </v-col>
                      <v-col>
                        <v-text-field
                          label="New field key"
                          v-model="new_key_global"
                        />
                        <v-select
                          label="Type"
                          :items="types"
                          v-model="new_type_global"
                        />
                        <v-btn
                          @click="
                            new_key_global && new_type_global
                              ? custom_field_config.global.push({
                                  key: new_key_global,
                                  type: new_type_global,
                                })
                              : null
                          "
                          >Add</v-btn
                        >
                      </v-col>
                      <v-col>
                        <v-text-field
                          label="New field key"
                          v-model="new_key_by_adslot"
                        />
                        <v-select
                          label="Type"
                          :items="types"
                          v-model="new_type_by_adslot"
                        />
                        <v-btn
                          @click="
                            new_key_by_adslot && new_type_by_adslot
                              ? custom_field_config.by_adslot.push({
                                  key: new_key_by_adslot,
                                  type: new_type_by_adslot,
                                })
                              : null
                          "
                          >Add</v-btn
                        >
                      </v-col>
                      <v-col
                    /></v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" text @click="save_bidder_dialog">
                  {{ $t("buttons.general.save") }}</v-btn
                ><v-btn
                  color="blue darken-1"
                  text
                  @click="close_bidder_dialog()"
                  >{{ $t("buttons.general.close") }}</v-btn
                >
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <ConfirmationDialog
        :confirmation-dialog="confirmationDialog"
        :on-confirm="confirmArchive"
        :on-close="closeArchive"
        msg="Do you want to archive DPA?"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { baseValidation } from "../../../validation/baseValidation";
import { validationHelper } from "../../../validation/validationHelper";
import store from "../../../store";
import { eventBus } from "@/main";
import ConfirmationDialog from "@/components/Helpers/ConfirmationDialog";

export default {
  name: "DemandPartnerAccountsTable",
  components: { ConfirmationDialog },
  mixins: [baseValidation, validationHelper],
  data() {
    return {
      dialog: false,
      configDialog: false,
      bidderDialog: false,
      confirmationDialog: false,
      itemToRemove: {},
      custom_field_config: {
        static: [
          { type: "number", key: "accountId" },
          { type: "number", key: "new" },
        ],
        global: [],
        by_adslot: [],
      },
      bidder_config: { accountId: 9585, new: 243084, zoneId: 1201454 },
      new_key_global: "",
      new_key_static: "",
      new_key_by_adslot: "",
      new_type_global: "",
      new_type_static: "",
      new_type_by_adslot: "",
      types: ["text", "number", "list"],
      dpa_bidder: {},
      dpa: {},
      ads_txt_text: "",
      dpa_id: -1,
      winner: "",
      programmatic_channel: "",
      readOnly: false,
      valid: true,
      bidder_config_valid: true,
      editedIndex: -1,
      editedItem: {},
      dpa_types_list: ["publisher", "optidigital"],
      dpa_type: "",
      search: "",
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Name (* -> Default for new Publishers)",
          value: "name",
        },
        {
          text: "Demand Partner",
          value: "demand_partner_name",
        },
        {
          text: "# Ads.txt lines",
          value: "ads_txt_count",
          align: "center",
        },
        {
          text: "Action",
          value: "action",
          sortable: false,
          filterable: false,
          align: "center",
        },
      ],
    };
  },
  mounted() {
    store.dispatch("publishers/fetchPublishers");
    store.dispatch("demandPartnerAccounts/fetchDemandPartnerAccounts");
    store.dispatch("demandPartnerAccounts/fetchActiveWinners");
    store.dispatch("demandPartnerAccounts/fetchProgrammaticChannels");
    store.dispatch("demandPartners/fetchDemandPartners");
    store.dispatch("product/fetchProducts");
  },
  computed: {
    ...mapGetters("demandPartnerAccounts", [
      "getLoading",
      "getDemandPartnerAccounts",
      "getDemandPartnerAccountById",
      "getDefaultDemandPartnerAccount",
      "getActiveWinners",
      "getProgrammaticChannels",
      "getConsentTypeTargetOptions",
    ]),
    ...mapGetters("demandPartners", ["getDemandPartners"]),
    ...mapGetters("product", {
      getProducts: "getProducts",
      getProductsLoading: "getLoading",
    }),
    ...mapGetters("adsTxt", { getAdsTxtLoading: "getLoading" }),
    ...mapGetters("dpaBidder", ["getDPABidder"]),
    getDemandPartnerAccountsWithRelations() {
      return this.getDemandPartnerAccounts.map(item => {
        if (item) {
          item.demand_partner_name = this.getDemandPartnerName(item);
        }
        return item;
      });
    },
  },
  methods: {
    ...mapActions("demandPartnerAccounts", [
      "fetchDemandPartnerAccounts",
      "createDemandPartnerAccount",
      "updateDemandPartnerAccount",
      "deleteDemandPartnerAccount",
      "updateDPABidder",
    ]),
    ...mapActions("demandPartners", ["fetchDemandPartners"]),
    ...mapActions("adsTxt", ["getAdsTxt", "updateAdsTxt"]),
    ...mapActions("dpaBidder", [
      "fetchDPABidder",
      "updateDPABidder",
      "updateDPABidderCustomConfig",
    ]),
    getDemandPartnerName(item) {
      let demandPartner = this.getDemandPartners.find(dp => {
        return dp.id === item.demand_partner_id;
      });
      if (demandPartner) {
        return demandPartner.name;
      }
    },
    async editDemandPartnerAccount(item) {
      this.editedIndex = this.getDemandPartnerAccounts.indexOf(item);
      this.editedItem = { ...item };
      this.ads_txt_text = "";
      this.readOnly = false;
      this.dialog = true;
      if (item.id !== 0) {
        this.ads_txt_text = await this.getAdsTxt(item.id);
      }
    },
    showDemandPartnerAccount(item) {
      this.editedIndex = this.getDemandPartnerAccounts.indexOf(item);
      this.editedItem = { ...item };
      this.readOnly = true;
      this.dialog = true;
    },
    push_win(item, winner) {
      if (winner !== this.$refs.dpa_winners_combobox.internalSearch) {
        winner = this.$refs.dpa_winners_combobox.internalSearch;
      }
      if (winner && !item.winners.find(elem => elem === winner))
        item.winners.push(winner);
      store.dispatch("demandPartnerAccounts/updateDemandPartnerAccount", {
        ...item,
      });
    },
    push_programmatic_channel(item, programmatic_channel) {
      if (
        programmatic_channel !==
        this.$refs.dpat_programmatic_channel.internalSearch
      ) {
        programmatic_channel = this.$refs.dpat_programmatic_channel
          .internalSearch;
      }
      if (
        programmatic_channel &&
        !item.programmatic_channels.find(elem => elem === programmatic_channel)
      )
        item.programmatic_channels.push(programmatic_channel);
    },
    remove_winner(item, winner) {
      item.winners = item.winners.filter(el => el !== winner);
    },
    remove_programmatic_channel(item, programmatic_channel) {
      item.programmatic_channels = item.programmatic_channels.filter(
        el => el !== programmatic_channel
      );
    },
    saveConfig(item) {
      store
        .dispatch("demandPartnerAccounts/updateDemandPartnerAccount", {
          ...item,
        })
        .then(response => {
          if (response && "warnings" in response) {
            eventBus.$emit("action_warning", response["warnings"]);
          }
          store.dispatch("demandPartnerAccounts/fetchDemandPartnerAccounts");
          this.close_config_dialog();
        })
        .catch(err => console.log(err));
    },
    getDpaName(dpa) {
      if (dpa.default === true) {
        return dpa.name + " (*)";
      }
      return dpa.name;
    },
    open_config_dialog(item) {
      this.configDialog = true;
      this.dpa_id = item.id;
    },
    showDemandPartnerBidderConfig(item) {
      this.bidderDialog = true;
      this.dpa_id = item.id;
      this.dpa = { ...item };
      this.custom_field_config = this.dpa.custom_fields_config;
      this.bidder_config = this.dpa.bidder_config_obj;
    },
    close_config_dialog() {
      this.configDialog = false;
      store.dispatch("demandPartnerAccounts/fetchDemandPartnerAccounts");
    },
    close_bidder_dialog() {
      this.bidderDialog = false;
      store.dispatch("demandPartnerAccounts/fetchDemandPartnerAccounts");
    },
    async save_bidder_dialog() {
      await store.dispatch("demandPartnerAccounts/updateDPABidder", this.dpa);
      await store.dispatch("demandPartnerAccounts/fetchDemandPartnerAccounts");
      this.bidderDialog = false;
    },
    close() {
      this.dialog = false;
      this.editedIndex = -1;
    },
    remove_custom_field(field_type, field) {
      this.custom_field_config[field_type] = this.custom_field_config[
        field_type
      ].filter(el => el.key !== field.key);
    },
    save() {
      if (this.$refs.form.validate()) {
        const ads_txt_lines = this.ads_txt_text;
        if (this.editedIndex > -1) {
          this.updateDemandPartnerAccount(this.editedItem).then(response => {
            if (response && "warnings" in response) {
              eventBus.$emit("action_warning", response["warnings"]);
            }
          });
          this.updateAdsTxt({
            dpa_id: this.editedItem.id,
            ads_txt_lines,
          });
        } else {
          this.createDemandPartnerAccount(this.editedItem).then(response => {
            if (response && "warnings" in response) {
              eventBus.$emit("action_warning", response["warnings"]);
            }
            if (response.data.id) {
              this.updateAdsTxt({
                dpa_id: response.data.id,
                ads_txt_lines,
              });
            }
          });
        }
        this.close();
      }
    },
    onArchive(item) {
      this.itemToRemove = item;
      this.confirmationDialog = true;
    },
    confirmArchive() {
      this.deleteDemandPartnerAccount(this.itemToRemove);
      this.confirmationDialog = false;
    },
    closeArchive() {
      this.confirmationDialog = false;
    },
  },
};
</script>

<style scoped></style>
