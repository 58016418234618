<template>
  <v-card elevation="0">
    <!--    <v-card-title>SSP Sync reports</v-card-title>-->
    <v-row />
    <div style="white-space: nowrap">
      <v-btn @click="triggerSync">Trigger Sync</v-btn>
      <v-btn @click="triggerInitBinding">Init Binding</v-btn>
    </div>
    <v-card-text>
      <v-data-table
        height="80vh"
        :items="getSyncReports"
        :loading="getLoading"
        :headers="headers"
        fixed-header
        :items-per-page="100"
        :footer-props="{
          'items-per-page-options': [20, 50, 100, 200],
          'show-first-last-page': true,
        }"
      >
        <template v-slot:item.view_report="{ item }">
          <v-btn depressed icon large class="mx-4" @click="viewItem(item)">
            <v-icon small>
              visibility
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.report`]="{ item }">
          <p class="truncate">{{ JSON.stringify(item.report) }}</p>
        </template>
      </v-data-table>
      <v-dialog v-model="dialog" max-width="60%">
        <v-card>
          <v-card-title>
            <v-icon class="ma-4" small>visibility</v-icon>
            Report view for id: {{ viewedItem.id }} at
            {{ viewedItem.create_time }}
          </v-card-title>
          <v-card-text>
            <!--            <v-textarea-->
            <!--              no-resize-->
            <!--              rows="20"-->
            <!--              class="textarea"-->
            <!--              outlined-->
            <!--              readonly-->
            <!--              label="JSON"-->
            <!--              :value="JSON.stringify(viewedItem.report)"-->
            <!--            />-->
            <PrismCodeEditor
              language="json"
              :value="JSON.stringify(viewedItem.report, null, 2)"
              label="Report"
              :readonly="true"
            />
          </v-card-text>
          <v-card-actions
            ><v-spacer />
            <v-btn color="primary" @click="closeDialog()"
              >Close</v-btn
            ></v-card-actions
          >
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PrismCodeEditor from "@/components/Settings/TagsManager/PrismCodeEditor.vue";

export default {
  name: "SyncReports",
  components: { PrismCodeEditor },
  data() {
    return {
      viewedItem: {},
      dialog: false,
      headers: [
        { text: "ID", value: "id" },
        // { text: "Create uuid", value: "create_uuid" },
        { text: "Create time", value: "create_time" },
        { text: "UserID", value: "user_id" },
        { text: "Report", value: "report" },
        { text: "View Report", value: "view_report" },
      ],
    };
  },
  mounted() {
    this.fetchSyncReports();
  },
  computed: {
    ...mapGetters("syncAdslotSSP", ["getSyncReports", "getLoading"]),
  },
  methods: {
    ...mapActions("syncAdslotSSP", [
      "fetchSyncReports",
      "triggerSyncReports",
      "initBinding",
    ]),
    viewItem(item) {
      this.viewedItem = item;
      this.dialog = true;
    },
    async triggerSync() {
      await this.triggerSyncReports();
    },
    async triggerInitBinding() {
      await this.initBinding();
    },
    closeDialog() {
      this.viewedItem = {};
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.truncate {
  width: 25vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
