<template>
  <v-row dense align="center">
    <v-col cols="6">
      <v-row align-content="stretch" justify="center">
        <v-col cols="12">
          <v-card style="height: 40vh;" class="table">
            <v-row>
              <v-card-title class="headline mb-1"
                >Action Button Table</v-card-title
              >
            </v-row>
            <v-row>
              <v-col>
                <v-row align="center" justify="center">
                  <v-col class="text-center">
                    <v-btn
                      :loading="loadingPushAllOdasSr"
                      small
                      color="primary"
                      @click="onPushAllOdasSr"
                    >
                      Push ODAS SR - All sites
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-col class="text-center">
                    <v-btn
                      :loading="loadingSynchPennylane"
                      small
                      color="primary"
                      @click="onSynchronizePennylane"
                    >
                      Synchronize Pennylane
                    </v-btn>
                  </v-col>
                </v-row>
                <v-spacer />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card style="height: 40vh;">
            <v-card-title>Fetch reports</v-card-title>
            <LoadingOverlay :overlay="getLoadingFetchReports">
              <template v-slot:default>
                <v-menu
                  v-model="datePicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="datePickerDate"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="datePickerDate"
                    type="month"
                    @input="datePicker = false"
                  />
                </v-menu>
                <v-btn v-on:click="onFetchReports">
                  Fetch Reports
                </v-btn>
              </template>
            </LoadingOverlay>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="6">
      <v-row align="stretch" justify="center">
        <v-col cols="12">
          <AuditLog />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import AuditLog from "../../components/Admin/AuditLog/AuditLogSmall.vue";
import LoadingOverlay from "@/components/Helpers/LoadingOverlay";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AdminDashboard",
  components: { LoadingOverlay, AuditLog },
  data() {
    return {
      homeState: true,
      datePickerDate: new Date().toISOString().substr(0, 7),
      datePicker: false,
      loadingPushAllOdasSr: false,
      loadingSynchPennylane: false,
    };
  },
  methods: {
    ...mapActions("admin", ["fetchReports", "synchronizePennylane"]),
    ...mapActions("siteWebConfig", ["pushAllOdasSr"]),
    onFetchReports() {
      let payload = {
        year: this.datePickerDate.substr(0, 4),
        month: this.datePickerDate.substr(5, 7),
      };
      this.fetchReports(payload);
    },
    async onPushAllOdasSr() {
      this.loadingPushAllOdasSr = true;
      await this.pushAllOdasSr();
      this.loadingPushAllOdasSr = false;
    },
    async onSynchronizePennylane() {
      this.loadingSynchPennylane = true;
      await this.synchronizePennylane();
      this.loadingSynchPennylane = false;
    },
  },
  computed: {
    ...mapGetters("admin", ["getLoadingFetchReports"]),
  },
};
</script>

<style scoped>
.table {
  background: url("./table.jpg");
  background-size: 100% 90%;
  background-repeat: no-repeat;
  background-position: 0px 50px;
}
</style>
