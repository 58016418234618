import axios from "axios";

const store = {
  namespaced: true,

  state: {
    loading: true,
    info: {
      startDate: "19920101",
      endDate: "19980429",
      itemsPerPage: 30,
      page: 1,
      logCount: 0,
      publisher_name: "",
      agent_mail: "",
      site_entity_name: "",
    },
    revisions: [],
  },

  getters: {
    getLoading: state => {
      return state.loading;
    },
    getRevisions: state => {
      return state.revisions;
    },
    getInfoItemsPerPage: state => {
      return state.info.itemsPerPage;
    },
    getLogCount: state => {
      return state.info.logCount;
    },
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setRevisions(state, revisions) {
      state.revisions = revisions;
    },
    setSearchData(state, payload) {
      state.info.startDate = payload[0].replace(/-/g, "");
      state.info.endDate = payload[1].replace(/-/g, "");
    },
    setPaginationInfo(state, payload) {
      state.info.page = payload.page;
      state.info.itemsPerPage = payload.itemsPerPage;
    },
    setLogsCount(state, payload) {
      state.info.logCount = payload;
    },
    setPublisherName(state, payload) {
      state.info.publisher_name = payload;
    },
    setAgentMail(state, payload) {
      state.info.agent_mail = payload;
    },
    setSiteEntityName(state, payload) {
      state.info.site_entity_name = payload;
    },
  },

  actions: {
    async fetchRevisions({ commit, state }) {
      commit("setLoading", true);
      return new Promise(resolve => {
        let queryParams = {
          startDate: state.info.startDate,
          endDate: state.info.endDate,
          page: state.info.page,
          itemsPerPage: state.info.itemsPerPage,
        };
        if (state.info.publisher_name) {
          queryParams.publisherFilter = state.info.publisher_name;
        }
        if (state.info.agent_mail) {
          queryParams.agentFilter = state.info.agent_mail;
        }
        if (state.info.site_entity_name) {
          queryParams.siteFilter = state.info.site_entity_name;
        }
        axios
          .get("/tags_manager/revision_changelog", {
            params: queryParams,
          })
          .then(response => {
            commit("setRevisions", response.data.items);
            commit("setLogsCount", response.data.items_count);
            commit("setLoading", false);
          });
        resolve();
      });
    },
    async setSearchDataAction({ commit, dispatch }, data) {
      return new Promise(resolve => {
        commit("setLoading", true);
        commit("setSearchData", data);
        dispatch("fetchRevisions");
        resolve();
      });
    },
    async updatePaginationInfo({ commit, dispatch }, data) {
      return new Promise(resolve => {
        commit("setLoading", true);
        commit("setPaginationInfo", {
          page: data.page,
          itemsPerPage: data.itemsPerPage,
        });
        dispatch("fetchRevisions");
        resolve();
      });
    },
    async fetchPublisherName({ commit, dispatch }, data) {
      return new Promise(resolve => {
        commit("setLoading", true);
        commit("setPublisherName", data);
        dispatch("fetchRevisions");
        resolve();
      });
    },
    async fetchAgentMail({ commit, dispatch }, data) {
      return new Promise(resolve => {
        commit("setLoading", true);
        commit("setAgentMail", data);
        dispatch("fetchRevisions");
        resolve();
      });
    },
    async fetchSiteEntityName({ commit, dispatch }, data) {
      return new Promise(resolve => {
        commit("setLoading", true);
        commit("setSiteEntityName", data);
        dispatch("fetchRevisions");
        resolve();
      });
    },
  },
};

export default store;
