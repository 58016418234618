<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-card-title>{{
          $tc("demand_partners.demand_partner", 2)
        }}</v-card-title>
      </v-row>
      <DemandPartnersTable />
    </v-col>
  </v-row>
</template>

<script>
import DemandPartnersTable from "../../components/Accounting/DemandPartners/DemandPartnersTable";

export default {
  name: "DemandPartners",
  components: { DemandPartnersTable },
};
</script>

<style scoped></style>
