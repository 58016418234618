var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-card-title',[_vm._v("Audit Log")])],1),_c('v-row',{attrs:{"align":"center","justify":"space-around"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-row',[_c('v-autocomplete',{attrs:{"label":"User email","items":_vm.getUsersEmails,"hide-no-data":"","loading":_vm.getLoading},on:{"change":() => {
                  this.setSearchDataAction({
                    searchAction: this.searchAction,
                    searchUser: this.searchUser,
                  });
                  this.options.page = 1;
                }},model:{value:(_vm.searchUser),callback:function ($$v) {_vm.searchUser=$$v},expression:"searchUser"}}),_c('v-icon',{attrs:{"disabled":!_vm.searchUser,"color":"red darken-3","icon":""},on:{"click":() => {
                  this.searchUser = '';
                  this.options.page = 1;
                  _vm.setSearchDataAction({
                    searchAction: this.searchAction,
                    searchUser: this.searchUser,
                  });
                }}},[_vm._v(" mdi-close-circle ")])],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-row',[_c('v-autocomplete',{attrs:{"label":"Action","items":_vm.getAllActions,"hide-no-data":"","loading":_vm.getLoading},on:{"change":() => {
                  this.setSearchDataAction({
                    searchAction: this.searchAction,
                    searchUser: this.searchUser,
                  });
                  this.options.page = 1;
                }},model:{value:(_vm.searchAction),callback:function ($$v) {_vm.searchAction=$$v},expression:"searchAction"}}),_c('v-icon',{attrs:{"disabled":!_vm.searchAction,"color":"red darken-3","icon":""},on:{"click":() => {
                  this.searchAction = '';
                  this.options.page = 1;
                  _vm.setSearchDataAction({
                    searchAction: this.searchAction,
                    searchUser: this.searchUser,
                  });
                }}},[_vm._v(" mdi-close-circle ")])],1)],1)],1),_c('v-data-table',{attrs:{"dense":"","height":"60vh","loading":_vm.getLoading,"headers":_vm.headers,"items":_vm.getAuditLogs,"server-items-length":_vm.getAuditLogCount,"items-per-page":_vm.getInfoItemsPerPage,"page":_vm.getInfoPage,"options":_vm.options,"item-key":"id","fixed-header":"","footer-props":{
          'show-first-last-page': true,
          'items-per-page-options': [10, 20, 30, 50, 100],
        }},on:{"update:options":function($event){_vm.options=$event}}}),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.fetchStartDate()}}},[_vm._v(_vm._s(_vm.$t("buttons.general.refresh")))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }