<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-card-title>
          {{ $tc("price_floor.price_floor_settings", 1) }}
        </v-card-title>
      </v-row>
      <PriceFloorSettings />
    </v-col>
  </v-row>
</template>

<script>
import PriceFloorSettings from "@/components/PriceFloor/Settings/PriceFloorSettings.vue";

export default {
  components: {
    PriceFloorSettings,
  },
};
</script>

<style></style>
