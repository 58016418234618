import axios from "axios";

const store = {
  namespaced: true,

  state: {
    loading: false,
    credentials: [],
    pdpa: {},
  },

  getters: {
    getLoading: state => {
      return state.loading;
    },
    getCredentials: state => {
      return state.credentials;
    },
    getPDPA: state => {
      return state.pdpa;
    },
    getDefaultCredential: () => () => {
      return {
        id: 0,
        key: "",
        value: "",
      };
    },
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setCredentials(state, payload) {
      state.credentials = payload;
    },
    setPDPA(state, payload) {
      state.pdpa = payload;
    },
  },

  actions: {
    async saveCredential({ commit, state }, credential) {
      commit("setLoading", true);
      await axios
        .put("/publisherdpa/sspCredential", {
          publisher_id: state.pdpa.publisher_id,
          demand_partner_account_id: state.pdpa.demand_partner_account_id,
          credential,
        })
        .catch(er => console.log(er));
      commit("setLoading", false);
    },
    async fetchCredentialsForPDPA({ commit }, pdpa) {
      commit("setLoading", true);
      commit("setPDPA", pdpa);
      await axios
        .get("/publisherdpa/sspCredential/all", {
          params: {
            publisher_id: pdpa.publisher_id,
            demand_partner_account_id: pdpa.demand_partner_account_id,
          },
        })
        .then(response => {
          commit("setCredentials", response.data);
        })
        .catch(er => console.log(er));
      commit("setLoading", false);
    },
    async removeCredential({ commit, state }, credential) {
      commit("setLoading", true);
      await axios
        .delete("/publisherdpa/sspCredential", {
          data: {
            publisher_id: state.pdpa.publisher_id,
            demand_partner_account_id: state.pdpa.demand_partner_account_id,
            credential,
          },
        })
        .catch(er => console.log(er));
      commit("setLoading", false);
    },
  },
};

export default store;
