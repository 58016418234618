import axios from "axios";

const store = {
  namespaced: true,

  state: {
    loading: true,
    info: {
      startDate: "",
      itemsPerPage: 30,
      page: 1,
      auditLogCount: 0,
    },
    allActions: [],
    searchActionField: "",
    usersEmails: [],
    searchUsersEmailsField: "",
    auditLogs: [],
  },

  getters: {
    getLoading: state => {
      return state.loading;
    },
    getInfoItemsPerPage: state => {
      return state.info.itemsPerPage;
    },
    getInfoPage: state => {
      return state.info.page;
    },
    getAuditLogCount: state => {
      return state.info.auditLogCount;
    },
    getAuditLogs: state => {
      return state.auditLogs;
    },
    getAuditStartDate: state => {
      return state.info.startDate;
    },
    getUsersEmails: state => {
      return state.usersEmails;
    },
    getAllActions: state => {
      return state.allActions;
    },
  },

  mutations: {
    clearData(state) {
      state.loading = true;
      state.info.startDate = "";
      state.info.itemsPerPage = 30;
      state.info.page = 1;
      state.info.auditLogCount = 0;
      state.auditLogs = [];
      state.allActions = [];
      state.usersEmails = [];
      state.searchUsersEmailsField = "";
      state.searchActionField = "";
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setLogs(state, auditLogs) {
      state.auditLogs = auditLogs;
    },
    setPaginationInfo(state, payload) {
      state.info.page = payload.page;
      state.info.itemsPerPage = payload.itemsPerPage;
    },
    setAuditLogsStartDate(state, payload) {
      state.info.startDate = payload.startDate;
    },
    setAuditLogsCount(state, payload) {
      state.info.auditLogCount = payload.auditLogCount;
    },
    setSearchData(state, payload) {
      state.usersEmails = payload.usersEmails;
      state.allActions = payload.allActions;
    },
    setSearchUsersEmailsField(state, payload) {
      state.searchUsersEmailsField = payload;
    },
    setSearchActionField(state, payload) {
      state.searchActionField = payload;
    },
  },

  actions: {
    async clearData({ commit }) {
      commit("clearData");
    },
    async setSearchDataAction({ commit, dispatch }, data) {
      return new Promise(resolve => {
        commit("setLoading", true);
        commit("setSearchActionField", data.searchAction);
        commit("setSearchUsersEmailsField", data.searchUser);
        dispatch("fetchLogCount");
        resolve();
      });
    },
    async fetchAuditData({ commit, state }) {
      return new Promise(resolve => {
        let queryParams = {
          startDate: state.info.startDate,
          page: state.info.page,
          itemsPerPage: state.info.itemsPerPage,
        };
        if (state.searchActionField && state.searchActionField !== "") {
          queryParams.searchAction = state.searchActionField;
        }
        if (
          state.searchUsersEmailsField &&
          state.searchUsersEmailsField !== ""
        ) {
          queryParams.searchUser = state.searchUsersEmailsField;
        }
        axios
          .get("/audit_log/logs/", {
            params: queryParams,
          })
          .then(response => {
            commit("setLogs", response.data.logs);
            commit("setLoading", false);
          });
        resolve();
      });
    },
    async fetchStartDate({ commit, dispatch }) {
      return new Promise(resolve => {
        commit("setLoading", true);
        axios.get("/audit_log/info/startDate/").then(response => {
          commit("setAuditLogsStartDate", {
            startDate: response.data.startDate,
          });
          dispatch("fetchSearchData");
        });
        resolve();
      });
    },
    async fetchSearchData({ commit, dispatch }) {
      return new Promise(resolve => {
        commit("setLoading", true);
        axios.get("/audit_log/info/usersAndActions/").then(response => {
          commit("setSearchData", {
            usersEmails: response.data.usersEmails,
            allActions: response.data.allActions,
          });
          dispatch("fetchLogCount");
        });
        resolve();
      });
    },
    async fetchLogCount({ commit, dispatch, state }) {
      return new Promise(resolve => {
        commit("setLoading", true);
        let queryParams = { startDate: state.info.startDate };
        if (state.searchActionField && state.searchActionField !== "") {
          queryParams.searchAction = state.searchActionField;
        }
        if (
          state.searchUsersEmailsField &&
          state.searchUsersEmailsField !== ""
        ) {
          queryParams.searchUser = state.searchUsersEmailsField;
        }
        axios
          .get("/audit_log/info/auditLogCount/", {
            params: queryParams,
          })
          .then(response => {
            commit("setAuditLogsCount", {
              auditLogCount: response.data.auditLogCount,
            });
            dispatch("fetchAuditData");
          });
        resolve();
      });
    },
    async updatePaginationInfo({ commit, dispatch }, data) {
      return new Promise(resolve => {
        commit("setLoading", true);
        commit("setPaginationInfo", {
          page: data.page,
          itemsPerPage: data.itemsPerPage,
        });
        dispatch("fetchAuditData");
        resolve();
      });
    },
  },
};

export default store;
