<template>
  <v-dialog
    v-model="show"
    persistent
    @click:outside="close"
    @keydown.esc="close"
    max-width="50%"
  >
    <v-card style="overflow-x: hidden;">
      <v-row align="center">
        <v-col cols="auto">
          <v-card-title>
            <span v-if="!readOnly && isSiteNew" class="headline"
              >{{ $t("buttons.sites.add_site") }}:</span
            >
            <span v-else-if="readOnly && !isSiteNew" class="headline"
              >{{ $t("buttons.sites.show_site") }}:</span
            >
            <span v-else class="headline"
              >{{ $t("buttons.sites.edit_site") }}:</span
            >
          </v-card-title>
        </v-col>
        <v-spacer />
        <v-col cols="auto">
          <v-btn
            v-if="!readOnly"
            :disabled="!valid"
            color="blue darken-1"
            text
            @click="save"
          >
            {{ $t("buttons.general.save") }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="close">
            {{ $t("buttons.general.close") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-card-title> {{ $t("sites.general") }}: </v-card-title>
              </v-col>
              <v-col cols="12">
                <v-menu
                  v-model="datePicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="editedSite.start_date"
                      prepend-icon="event"
                      readonly
                      :label="$t('general.start_date')"
                      v-on="on"
                      :rules="createRules([baseValidation.required])"
                    />
                  </template>
                  <v-date-picker
                    v-model="editedSite.start_date"
                    type="date"
                    @input="selectDate()"
                  />
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  :readonly="readOnly"
                  :filled="readOnly"
                  v-model="editedSite.name"
                  @change="trimName"
                  label="Name"
                  :rules="createRules([baseValidation.required])"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  :readonly="readOnly"
                  :filled="readOnly"
                  v-model="editedSite.url"
                  @change="this.propagateUrlToGamName"
                  label="URL"
                  :rules="
                    createRules([
                      baseValidation.required,
                      baseValidation.domain,
                    ])
                  "
                />
                <v-text-field
                  dense
                  outlined
                  readonly
                  v-model="editedSite.gam_ad_unit_name"
                  label="GAM adUnit name"
                  :loading="getLoading"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-card-title> ODAS: </v-card-title>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  readonly
                  :filled="readOnly"
                  v-model.number="editedSite.odas_site_id"
                  label="ODAS Site ID"
                  :rules="createRules([baseValidation.nullable_integer])"
                />
              </v-col>
              <v-col cols="auto">
                <v-autocomplete
                  :readonly="readOnly"
                  :items="getIabCategoryList"
                  item-text="name"
                  item-value="id"
                  :filled="readOnly"
                  v-model="editedSite.odas_site_category_id"
                  label="Site Category"
                  :rules="createRules([baseValidation.required])"
                >
                  <template v-slot:append-outer>
                    <v-icon
                      :disabled="readOnly"
                      icon
                      v-on:click="editedSite.odas_site_category_id = null"
                    >
                      delete
                    </v-icon>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-card-title>
                  {{ $t("sites.publisher_select") }}:
                </v-card-title>
              </v-col>
              <v-col class="d-flex" cols="12" sm="6">
                <v-select
                  :readonly="readOnly"
                  :filled="readOnly"
                  :items="getPublishers"
                  item-text="name"
                  item-value="id"
                  :label="$t('sites.select_label')"
                  v-model="editedSite.publisher_id"
                  :disabled="disablePublisherSelect"
                  :rules="createRules([baseValidation.required])"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-card-title> Technical:</v-card-title>
              </v-col>
              <v-col class="d-flex" cols="auto" sm="6" md="6">
                <v-text-field
                  :filled="readOnly"
                  :readonly="readOnly"
                  v-model="editedSite.site_code"
                  label="Site code"
                />
              </v-col>
              <v-col class="d-flex" cols="auto" sm="6" md="6">
                <v-select
                  :readonly="readOnly"
                  :filled="readOnly"
                  :items="sellersJsonStatuses"
                  item-text="name"
                  item-value="value"
                  label="Sellers Status"
                  v-model="editedSite.sellers_json_visible"
                  :rules="createRules([baseValidation.required])"
                />
              </v-col>
              <v-col v-if="isAdmin" class="d-flex" cols="auto" sm="6" md="6">
                <v-switch
                  :filled="readOnly"
                  :readonly="readOnly"
                  v-model="editedSite.tm_deploy_enabled"
                  label="TagManager - enable push live"
                />
              </v-col>
              <v-col>
                <v-select
                  :readonly="readOnly"
                  :filled="readOnly"
                  :items="tagManagerRendererVersions"
                  item-text="name"
                  item-value="value"
                  label="Tag Manager renderer version"
                  v-model="editedSite.site_config_renderer_version"
                  :rules="createRules([baseValidation.required])"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="!readOnly"
          :disabled="!valid"
          color="blue darken-1"
          text
          @click="save"
        >
          {{ $t("buttons.general.save") }}
        </v-btn>
        <v-btn color="blue darken-1" text @click="close">
          {{ $t("buttons.general.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import store from "./../../../store/index";
import { baseValidation } from "../../../validation/baseValidation";
import { validationHelper } from "../../../validation/validationHelper";

export default {
  name: "SiteDialog",
  props: {
    editedSite: {
      type: Object,
      required: true,
    },
    isSiteNew: {
      type: Boolean,
      required: true,
    },
    readOnly: {
      type: Boolean,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    },
    disablePublisherSelect: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [baseValidation, validationHelper],
  data() {
    return {
      valid: true,
      datePicker: false,
    };
  },
  mounted() {
    this.fetchIabCategories();
    if (!this.disablePublisherSelect) {
      store.dispatch("publishers/fetchPublishers");
    }
  },
  computed: {
    ...mapGetters("publishers", ["getLoading", "getPublishers"]),
    ...mapGetters("siteEntities", ["getIabCategoryList"]),
    ...mapGetters("context", ["getUserProfile"]),
    isAdmin() {
      return this.getUserProfile.role === "admin";
    },
    sellersJsonStatuses() {
      return [
        { name: "Visible", value: "VISIBLE" },
        { name: "Obfuscated", value: "OBFUSCATED" },
      ];
    },
    tagManagerRendererVersions() {
      return [
        { name: "Launcher V2", value: 2 },
        { name: "Launcher V3", value: 3 },
      ];
    },
  },
  methods: {
    ...mapActions("siteEntities", [
      "updateSiteEntities",
      "createSiteEntities",
      "fetchIabCategories",
    ]),
    ...mapActions("publishers", ["fetchPublishers"]),
    save() {
      if (this.$refs.form.validate()) {
        if (this.editedSite.odas_site_id === "") {
          this.editedSite.odas_site_id = null;
        }
        if (!this.isSiteNew) {
          this.updateSiteEntities(this.editedSite);
        } else {
          this.createSiteEntities(this.editedSite);
        }
        this.close();
      }
    },
    trimName() {
      this.editedSite.name = this.editedSite.name.trim();
    },
    propagateUrlToGamName() {
      if (this.isSiteNew && this.editedSite.url && this.editedSite.url !== "") {
        this.editedSite.url = this.editedSite.url.trim();
        let clean_url = this.editedSite.url
          .replace("http://", "")
          .replace("https://", "");
        let rootDomain = new URL("https://" + clean_url).hostname.replace(
          "www.",
          ""
        );
        this.editedSite.gam_ad_unit_name = "od." + rootDomain;
      }
    },
    selectDate() {
      this.datePicker = false;
    },
  },
};
</script>

<style scoped></style>
