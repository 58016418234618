<template>
  <v-row dense>
    <v-col cols="12">
      <AuditLogFullPage />
    </v-col>
  </v-row>
</template>

<script>
import AuditLogFullPage from "../../components/Admin/AuditLog/AuditLogFull.vue";

export default {
  name: "AuditLog",
  components: {
    AuditLogFullPage,
  },
};
</script>

<style scoped />
