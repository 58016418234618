import axios from "axios";

const store = {
  namespaced: true,

  state: {
    loading: true,
    currencies: [],
    currencyCodes: [],
  },

  getters: {
    getLoading: state => {
      return state.loading;
    },
    getAllCurrencies: state => {
      return state.currencies;
    },
    getCurrencyCodes: state => {
      return state.currencyCodes;
    },
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setAllCurrencies(state, payload) {
      state.currencies = payload;
    },
    setCurrencyCodes(state, payload) {
      state.currencyCodes = payload;
    },
  },

  actions: {
    async fetchAllCurrencies({ commit }) {
      commit("setLoading", true);
      return new Promise(resolve => {
        axios
          .get("/price_floor/configuration/settings/currency/all")
          .then(response => {
            commit("setAllCurrencies", response.data);
            commit("setLoading", false);
          });
        resolve();
      });
    },
    async fetchCurrencyCodes({ commit }) {
      commit("setLoading", true);
      return new Promise(resolve => {
        axios
          .get("/price_floor/configuration/settings/currency_codes")
          .then(response => {
            commit("setCurrencyCodes", response.data);
            commit("setLoading", false);
          });
        resolve();
      });
    },
    async editCurrency({ commit, dispatch }, currency) {
      commit("setLoading", true);
      return new Promise(resolve => {
        axios
          .put(`/price_floor/configuration/settings/currency/${currency.id}`, {
            code: currency.code,
            bucket_definition: currency.bucket_definition,
            name: currency.name,
          })
          .then(() => {
            dispatch("fetchAllCurrencies");
          });
        resolve();
      });
    },
    async addCurrency({ commit, dispatch }, currency) {
      commit("setLoading", true);
      return new Promise(resolve => {
        axios
          .post(`/price_floor/configuration/settings/currency`, {
            code: currency.code,
            bucket_definition: currency.bucket_definition,
            name: currency.name,
          })
          .then(() => {
            dispatch("fetchAllCurrencies");
          });
        resolve();
      });
    },
  },
};

export default store;
