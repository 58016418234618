<template>
  <v-row align="center" justify="center">
    <v-col>
      <v-row align="center" class="pt-5 pb-5">
        <v-card-title>Sellers.json</v-card-title>
        <v-spacer />
        <v-switch
          :true-value="1"
          :false-value="0"
          label="Obfuscated"
          v-model="obfuscated"
        />
        <v-btn
          :disabled="!base_information_validation"
          @click="onRenderSellersJson"
          class="mr-6 ml-6"
          color="primary"
        >
          Render
        </v-btn>
        <v-btn
          @click="importIdentifiersDialog = true"
          class="mr-6 ml-6"
          color="primary"
        >
          Import Identifiers
        </v-btn>
        <v-btn @click="onRefreshData" class="mr-6 ml-6" color="primary">
          Refresh
        </v-btn>
        <v-btn
          :disabled="!base_information_validation"
          @click="onSaveData"
          class="mr-6 ml-6"
          color="primary"
        >
          Save
        </v-btn>
      </v-row>
      <v-divider />
      <v-col>
        <LoadingOverlay :overlay="getLoading">
          <template v-slot:default>
            <v-row>
              <v-card-title> Contact info </v-card-title>
            </v-row>
            <v-form
              v-model="base_information_validation"
              lazy-validation
              ref="base_information_validation"
            >
              <v-row>
                <v-col
                  ><v-text-field
                    v-model="contact_email"
                    label="Contact Email"
                    :rules="createRules([baseValidation.email])"
                /></v-col>
                <v-col
                  ><v-text-field
                    v-model="contact_address"
                    label="Contact Adress"
                /></v-col>
                <v-col
                  ><v-text-field
                    readonly
                    disabled
                    v-model="version"
                    label="Version"
                /></v-col>
              </v-row>
            </v-form>
            <v-divider />
            <v-row>
              <v-card-title> Identifiers </v-card-title>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="12">
                <v-data-table :headers="headers" :items="identifiers">
                  <template v-slot:top>
                    <v-btn
                      color="primary"
                      class="mx-4"
                      @click="openIdentifierDialog(getDefaultIdentifier())"
                    >
                      Add Identifier
                    </v-btn>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-btn
                      color="primary"
                      icon
                      small
                      class="mx-1"
                      @click="openIdentifierDialog(item)"
                    >
                      <v-icon>
                        edit
                      </v-icon>
                    </v-btn>
                    <v-btn
                      color="red"
                      icon
                      small
                      class="mx-1"
                      @click="removeIdentifier(item)"
                    >
                      <v-icon>
                        delete
                      </v-icon>
                    </v-btn>
                  </template>
                  />
                </v-data-table>
              </v-col>
            </v-row>
          </template>
        </LoadingOverlay>
      </v-col>
      <v-dialog width="50vw" v-model="identifierDialog">
        <v-card>
          <v-card-title> Identifier </v-card-title>
          <v-card-text>
            <v-text-field v-model="editedIdentifier.name" label="Name" />
            <v-text-field v-model="editedIdentifier.value" label="Value" />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" @click="onAddOrUpdateIdentifier">Add</v-btn>
            <v-btn @click="identifierDialog = false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog width="80vw" v-model="renderDialog">
        <v-card style="overflow-x: hidden;">
          <v-card-actions>
            <v-card-title>Render Sellers Json</v-card-title>
            <v-spacer />
            <v-col cols="auto">
              <v-btn
                :disabled="getRenderedSellersJson.length === 0"
                @click="copySellersJson"
                value="Copy Config"
                append
              >
                Copy
                <v-icon right>mdi-content-copy</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn
                @click="downloadRenderedSellersJson"
                class="ma-4"
                color="primary"
              >
                Download
              </v-btn>
              <v-btn
                v-if="!getLoading"
                @click="upload"
                class="ma-4"
                color="primary"
              >
                Upload to Google Storage
              </v-btn>
              <v-btn v-if="getLoading" class="ma-4" color="primary">
                <v-progress-circular indeterminate />
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn class="ma-4" @click="renderDialog = false" color="primary">
                Close
              </v-btn>
            </v-col>
          </v-card-actions>
          <v-card-text>
            <v-row>
              <v-textarea
                v-model="getRenderedSellersJson"
                readonly
                no-resize
                id="rendered_sellers_json"
                height="45vh"
                outlined
                rounded
              />
            </v-row>
            <v-row>
              <p>
                Note: Publishers that name starts with "QA-" or ends with "-QA"
                are ignored!!!
              </p>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-col cols="auto">
              <v-btn
                :disabled="getRenderedSellersJson.length === 0"
                @click="copySellersJson"
                value="Copy Config"
                append
              >
                Copy
                <v-icon right>mdi-content-copy</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn
                @click="downloadRenderedSellersJson"
                class="ma-4"
                color="primary"
              >
                Download
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn @click="renderDialog = false" class="ma-4" color="primary">
                Close
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog width="50vw" v-model="importIdentifiersDialog">
        <v-card>
          <v-card-title> Import Identifiers </v-card-title>
          <v-card-text>
            <v-text-field label="URL" v-model="urlToImportIdentifiers"
          /></v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="importIdentifiers" color="primary"> Import </v-btn>
            <v-btn @click="importIdentifiersDialog = false"> Cancel </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar
        v-model="snackbarDialog"
        top
        :timeout="5000"
        :color="snackbarColor"
      >
        {{ snackbarText }}
        <v-btn text @click="hideAlert()">
          Close
        </v-btn>
      </v-snackbar>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LoadingOverlay from "@/components/Helpers/LoadingOverlay";
import { baseValidation } from "@/validation/baseValidation";
import { validationHelper } from "@/validation/validationHelper";

export default {
  mixins: [baseValidation, validationHelper],
  components: {
    LoadingOverlay,
  },
  data() {
    return {
      identifiers: [],
      base_information_validation: false,
      importIdentifiersDialog: false,
      urlToImportIdentifiers: "",
      renderDialog: false,
      snackbarDialog: false,
      snackbarText: "",
      snackbarColor: "error",
      identifierDialog: false,
      contact_email: "",
      contact_address: "",
      version: "",
      editedIdentifier: {},
      editedIdentifierIndex: -1,
      obfuscated: 0,
      headers: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Value",
          value: "value",
        },
        {
          text: "Action",
          value: "action",
          align: "end",
        },
      ],
    };
  },
  async mounted() {
    this.refreshData();
  },
  computed: {
    ...mapGetters("sellersJson", [
      "getDefaultIdentifier",
      "getIdentifiers",
      "getContactInfo",
      "getLoading",
      "getRenderedSellersJson",
    ]),
  },
  methods: {
    ...mapActions("sellersJson", [
      "fetchSellersJsonData",
      "updateSellersJsonData",
      "setSellersJsonData",
      "fetchRenderedSellersJson",
      "downloadRenderedSellersJson",
      "uploadRenderedSellersJson",
      "importExternalSellerJson",
    ]),
    async setDataFromRepository() {
      this.identifiers = [...this.getIdentifiers];
      const contactInfo = { ...this.getContactInfo };
      this.contact_email = contactInfo.contact_email;
      this.contact_address = contactInfo.contact_address;
      this.version = contactInfo.version;
    },
    async syncDataToStore() {
      const dataToSave = {
        contactInfo: {
          contact_email: this.contact_email,
          contact_address: this.contact_address,
          version: this.version,
        },
        identifiers: this.identifiers,
        obfuscated: this.obfuscated,
      };
      await this.setSellersJsonData(dataToSave);
    },
    async refreshData() {
      await this.fetchSellersJsonData();
      await this.setDataFromRepository();
    },
    async onRefreshData() {
      await this.refreshData();
      this.showInfo("Data Updated");
    },
    async saveSellersJsonData() {
      await this.syncDataToStore();
      await this.updateSellersJsonData();
      await this.setDataFromRepository();
    },
    async onSaveData() {
      await this.saveSellersJsonData();
      this.showInfo("Data Saved");
    },
    openIdentifierDialog(item) {
      this.editedIdentifier = item;
      this.identifierDialog = true;
    },
    onAddOrUpdateIdentifier() {
      this.identifiers.push(this.editedIdentifier);
      this.identifierDialog = false;
    },
    removeIdentifier(item) {
      const index = this.identifiers.indexOf(item);
      this.identifiers.splice(index, 1);
    },
    async onRenderSellersJson() {
      await this.saveSellersJsonData();
      await this.fetchRenderedSellersJson();
      this.renderDialog = true;
    },
    copySellersJson() {
      let textToCopy = document.querySelector("#rendered_sellers_json");
      textToCopy.select();

      try {
        const successful = document.execCommand("copy");
        const msg = successful ? "successfully" : "unsuccessfully";
        this.showInfo("Text was copied " + msg);
      } catch (err) {
        this.showAlert("Oops, unable to copy");
      }

      window.getSelection().removeAllRanges();
    },
    async upload() {
      const result = await this.uploadRenderedSellersJson();
      if (result === false) {
        this.showAlert("Failed to upload");
      } else {
        this.showInfo("Uploaded Succesfully");
      }
    },
    async importIdentifiers() {
      const result = await this.importExternalSellerJson(
        this.urlToImportIdentifiers
      );
      if (result === false) {
        this.showAlert("Failed to import");
      } else {
        this.showInfo("Imported Succesfully");
        this.setDataFromRepository();
      }
      this.importIdentifiersDialog = false;
    },
    showAlert(e) {
      this.snackbarDialog = true;
      this.snackbarColor = "error";
      this.snackbarText = e.toString();
    },
    showInfo(e) {
      this.snackbarDialog = true;
      this.snackbarColor = "info";
      this.snackbarText = e.toString();
    },
    hideAlert() {
      this.snackbarDialog = false;
    },
  },
};
</script>

<style></style>
