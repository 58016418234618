<template>
  <v-row class="sites-grid">
    <v-col cols="12">
      <v-data-table
        height="60vh"
        :loading="getLoadingFinancialReporting"
        :headers="headers"
        :items="getFinancialReportMain.table"
        item-key="id"
        fixed-header
        :items-per-page="30"
        :footer-props="{
          'show-first-last-page': true,
          'items-per-page-options': [10, 20, 30, 50, 100],
        }"
      >
        <template slot="top">
          <v-row>
            <v-col cols="4">
              <v-menu
                v-model="datePicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="datePickerDate"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="datePickerDate"
                  type="month"
                  @input="selectDate(datePickerDate)"
                />
              </v-menu>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                v-model="demandPartnerAutocomplete"
                :loading="getLoadingDemandPartners"
                :items="getDemandPartners"
                item-text="name"
                item-value="id"
                v-on:change="fetchFinancialReporting"
                clearable
                hide-no-data
                @input="selectDPA($event)"
              />
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn color="primary" v-on:click="openDialog(item)">
            {{ $t("buttons.general.edit") }}
          </v-btn>
        </template>
        <template v-slot:item.estimated_revenue="{ value }">
          {{ formatMoney(value) + " €" }}
        </template>
        <template v-slot:item.final_revenue="{ value }">
          {{ formatMoney(value) + " €" }}
        </template>
        <template v-slot:item.correction_value="{ value }">
          {{ formatMoney(value) + " €" }}
        </template>
        <template v-slot:item.status="{ value }">
          <v-tooltip bottom :disabled="value.name === 'Estimated'">
            <template #activator="{ on }">
              <v-chip :color="mapFinancialStatusColor(value.name)" v-on="on">
                {{ value.name }}
              </v-chip>
            </template>
            <span v-if="value.name !== 'Estimated'"> {{ value.date }} </span>
          </v-tooltip>
        </template>
        <template slot="body.prepend">
          <tr class="font-weight-bold">
            <td v-bind:key="index" v-for="(h, index) in headers">
              <div v-if="h.value === 'dp_name'">Total</div>
              <div v-if="h.value === 'estimated_revenue'" class="text-right">
                {{ formatMoney(getFinancialReportMain.total_estimated) + " €" }}
              </div>
              <div v-if="h.value === 'final_revenue'" class="text-right">
                {{ formatMoney(getFinancialReportMain.total_final) + " €" }}
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
      <FinancialReportingDialog
        ref="dialogRef"
        v-bind:close-dialog="closeDialog"
        v-bind:is-open="dialog"
        v-bind:refresh-parent="fetchFinancialReporting"
      />
      <v-snackbar v-model="snackbarUnsavedChanges" top color="error" multi-line>
        {{ $t("financial_reporting.warning_unsaved_changes") }}
        <v-btn @click="snackbarUnsavedChanges = false" inverted color="red">
          {{ $t("buttons.general.ok") }}
        </v-btn>
      </v-snackbar>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FinancialReportingDialog from "./FinancialReportingDialog";
import { moneyMixin } from "../../../helpers/moneyMixin";
import { mapStatusColor } from "@/helpers/mapStatusColorMixin";

export default {
  name: "FinancialReportingTable",
  components: { FinancialReportingDialog },
  mixins: [moneyMixin, mapStatusColor],
  data: () => ({
    snackbarUnsavedChanges: false,
    dialog: false,
    dialogRow: {},
    datePicker: false,
    datePickerDate: null,
    demandPartnerAutocomplete: null,
    headers: [
      {
        text: "Demand Partner",
        value: "dp_name",
      },
      {
        text: "Demand Partner Account",
        value: "dpa_name",
      },
      {
        text: "Estimated Revenue",
        value: "estimated_revenue",
        align: "right",
      },
      {
        text: "Final Revenue",
        value: "final_revenue",
        align: "right",
      },
      {
        text: "Correction Ratio",
        value: "correction_ratio",
      },
      {
        text: "Correction Value",
        value: "correction_value",
      },
      {
        text: "Publishers exceptions",
        value: "publishers_exceptions",
      },
      {
        text: "Status",
        value: "status",
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
        filterable: false,
        align: "center",
      },
    ],
  }),
  mounted() {
    this.fetchDemandPartners();
    this.fetchFinancialReporting();
    this.setUpDate(this);
    this.demandPartnerAutocomplete = this.getDPAId || null;
  },
  computed: {
    ...mapGetters("demandPartners", ["getDemandPartners"]),
    ...mapGetters("demandPartners", { getLoadingDemandPartners: "getLoading" }),
    ...mapGetters("financialReporting", [
      "getFinancialReportMain",
      "anyModsUnsaved",
      "getDate",
      "getDPAId",
    ]),
    ...mapGetters("financialReporting", {
      getLoadingFinancialReporting: "getLoading",
    }),
  },
  methods: {
    ...mapActions("demandPartners", ["fetchDemandPartners"]),
    ...mapActions("financialReporting", [
      "fetchFinancialReportMain",
      "setDPA",
      "setSelectedDate",
    ]),
    openDialog(item) {
      this.$refs.dialogRef.setDialog(item, this.datePickerDate);
      this.dialog = true;
    },
    closeDialog() {
      if (this.anyModsUnsaved) {
        this.snackbarUnsavedChanges = true;
      } else {
        this.dialog = false;
      }
    },
    setUpDate() {
      let d = new Date();
      d.setDate(0); //prev month
      d.setDate(1); // set first day of prev month
      this.datePickerDate = this.getDate || d.toISOString().substr(0, 7);
      this.setSelectedDate(this.datePickerDate);
    },
    fetchFinancialReporting() {
      this.fetchFinancialReportMain();
    },
    selectDPA(event) {
      this.setDPA(event);
      this.fetchFinancialReportMain();
    },
    selectDate(event) {
      this.setSelectedDate(event);
      this.datePicker = false;
      this.fetchFinancialReportMain();
    },
  },
};
</script>

<style lang="scss" scoped>
.table-bcg-color {
  background-color: #90caf9;
}
</style>
