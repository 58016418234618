<template>
  <div>
    <div
      v-if="paymentInfo.status == 'validated'"
      class="d-flex flex-wrap flex-column"
    >
      <v-btn
        :loading="getLoading"
        class="ma-1"
        color="primary"
        @click="onGenerateInvoice"
      >
        <v-icon class="mr-2">description</v-icon>
        Generate Payment
      </v-btn>
    </div>
    <div
      v-if="paymentInfo.status == 'invoiced_draft'"
      class="d-flex flex-wrap flex-column"
    >
      <v-text-field label="Invoice Date" :value="paymentInfo.invoice_date" />
      <v-text-field label="Due Date" :value="paymentInfo.invoice_due_date" />
      <v-btn
        :loading="getLoading"
        class="ma-1"
        color="lime"
        @click="onDownloadInvoice"
      >
        <v-icon class="mr-2">download</v-icon>
        Download Invoice
      </v-btn>
      <v-btn
        :loading="getLoading"
        class="ma-1"
        color="warning"
        @click="onGenerateInvoice"
      >
        <v-icon class="mr-2">autorenew</v-icon>
        Regenerate Invoice
      </v-btn>
      <v-btn
        :loading="getLoading"
        class="ma-1"
        color="green"
        @click="onValidateInvoice"
      >
        <v-icon class="mr-2">done</v-icon>
        Validate Invoice
      </v-btn>
    </div>
    <div
      v-if="paymentInfo.status == 'invoiced'"
      class="d-flex flex-wrap flex-column"
    >
      <v-text-field label="Invoice Date" :value="paymentInfo.invoice_date" />
      <v-text-field label="Due Date" :value="paymentInfo.invoice_due_date" />
      <v-btn
        :loading="getLoading"
        class="ma-1"
        color="lime"
        @click="onDownloadInvoice"
      >
        <v-icon class="mr-2">download</v-icon>
        Download Invoice
      </v-btn>
      <v-btn
        :loading="getLoading"
        class="ma-1"
        color="blue"
        @click="onSendInvoice"
      >
        <v-icon class="mr-2">done</v-icon>
        Send Invoice
      </v-btn>
      <v-btn
        :loading="getLoading"
        class="ma-1"
        color="green"
        @click="onValidatePayment"
      >
        <v-icon class="mr-2">done</v-icon>
        Payment Validated
      </v-btn>
    </div>
    <div
      v-if="paymentInfo.status == 'payment_done'"
      class="d-flex flex-wrap flex-column"
    >
      <v-text-field
        label="Payment Date"
        :value="paymentInfo.invoice_payment_date"
      />
      <v-btn
        :loading="getLoading"
        class="ma-1"
        color="lime"
        @click="onDownloadInvoice"
      >
        <v-icon class="mr-2">download</v-icon>
        Download Invoice
      </v-btn>
    </div>
    <div
      v-if="paymentInfo.status == 'invoice_sent'"
      class="d-flex flex-wrap flex-column"
    >
      <v-text-field label="Invoice Date" :value="paymentInfo.invoice_date" />
      <v-text-field label="Due Date" :value="paymentInfo.invoice_due_date" />
      <v-text-field
        label="Invoice Sent Date"
        :value="paymentInfo.invoice_sent_date"
      />
      <v-btn
        :loading="getLoading"
        class="ma-1"
        color="lime"
        @click="onDownloadInvoice"
      >
        <v-icon class="mr-2">download</v-icon>
        Download Invoice
      </v-btn>
      <v-btn
        :loading="getLoading"
        class="ma-1"
        color="green"
        @click="onValidatePayment"
      >
        <v-icon class="mr-2">done</v-icon>
        Payment Validated
      </v-btn>
      <v-btn
        :loading="getLoading"
        class="ma-1"
        color="yellow"
        @click="onSendInvoice"
      >
        <v-icon class="mr-2">done</v-icon>
        Re-Send Invoice
      </v-btn>
    </div>
    <PaymentDateDialogVue
      :visible="dateDialog"
      :titleText="dateDialogTitle"
      :onConfirmCallback="dateDialogCallback"
      :onCancelCallback="onHideDateDialog"
      :dpaType="dpa_type"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PaymentDateDialogVue from "./PaymentDateDialog.vue";

export default {
  components: {
    PaymentDateDialogVue,
  },
  data() {
    return {
      dateDialog: false,
      dateDialogCallback: new Function(),
      dateDialogTitle: "",
    };
  },
  props: {
    paymentInfo: {
      type: Object,
    },
    dpa_type: {
      type: String,
    },
    payment_term: {
      type: String,
    },
  },
  computed: {
    ...mapGetters("turnoverFinal", ["getLoading"]),
    ...mapGetters("publishers", ["getPennylaneMappingPublisher"]),
    isSupplierMapped() {
      const supplierMapping = this.getPennylaneMappingPublisher.supplier;
      return !!supplierMapping?.publisher_id;
    },
    isCustomerMapped() {
      const customerMapping = this.getPennylaneMappingPublisher.customer;
      return !!customerMapping?.publisher_id;
    },
  },
  methods: {
    ...mapActions("warnings", ["showSnackbarError"]),
    ...mapActions("turnoverFinal", [
      "generateInvoice",
      "downloadInvoice",
      "validatePayment",
      "validateInvoice",
      "sendInvoice",
    ]),
    onShowDateDialog(title, callback) {
      this.dateDialogTitle = title;
      this.dateDialogCallback = callback;
      this.dateDialog = true;
    },
    onHideDateDialog() {
      this.dateDialog = false;
    },
    onGenerateInvoice() {
      this.onShowDateDialog("Select Invoice Date", generateDate => {
        if (this.dpa_type === "publisher" || this.dpa_type === "optidigital") {
          if (this.dpa_type === "publisher" && !this.isCustomerMapped) {
            this.showSnackbarError("Publisher's Customer is not connected");
            this.onHideDateDialog();
            return;
          }
          if (this.dpa_type === "optidigital" && !this.isSupplierMapped) {
            this.showSnackbarError("Publisher's Supplier is not connected");
            this.onHideDateDialog();
            return;
          }

          this.generateInvoice({
            dpa_type: this.dpa_type,
            payment_term: this.payment_term,
            invoice_date: generateDate,
          });
          this.onHideDateDialog();
        }
      });
    },
    onDownloadInvoice() {
      this.downloadInvoice({
        dpa_type: this.dpa_type,
        payment_term: this.payment_term,
      });
    },
    onSendInvoice() {
      this.sendInvoice({
        dpa_type: this.dpa_type,
        payment_term: this.payment_term,
      });
    },
    onValidateInvoice() {
      this.validateInvoice({
        dpa_type: this.dpa_type,
        payment_term: this.payment_term,
      });
    },
    onValidatePayment() {
      this.onShowDateDialog("Select Payment Date", paymentDate => {
        this.validatePayment({
          dpa_type: this.dpa_type,
          payment_term: this.payment_term,
          payment_date: paymentDate,
        });
        this.onHideDateDialog();
      });
    },
  },
};
</script>

<style></style>
