<template>
  <v-dialog v-model="show" max-width="50%" @click:outside="close">
    <v-card style="overflow-x: hidden;">
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-row>
                <v-col cols="12">
                  <v-card-title>Edit:</v-card-title>
                </v-col>
                <v-col cols="12">
                  <v-alert v-if="error" dense outlined type="error">
                    Check the code for Price bucket. It should has format
                    <code>[{}, {}, ....]</code>
                  </v-alert>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :readonly="readOnly"
                    :filled="readOnly"
                    v-model="editedItem.name"
                    label="Name"
                    :rules="createRules([baseValidation.required])"
                  />
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    :readonly="readOnly"
                    :filled="readOnly"
                    :items="getCurrencyCodes"
                    label="Currency"
                    v-model="editedItem.code"
                    :rules="createRules([baseValidation.required])"
                  />
                </v-col>
                <v-col cols="12">
                  <prism-code-editor
                    language="json"
                    v-model="priceBucket"
                    label="Price bucket"
                    :readonly="!editable"
                    :rules="createRules([baseValidation.required])"
                  />
                </v-col>
              </v-row>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="!readOnly"
          :disabled="!valid"
          color="blue darken-1"
          text
          @click="save"
        >
          {{ $t("buttons.general.save") }}</v-btn
        ><v-btn color="blue darken-1" text @click="close">{{
          $t("buttons.general.close")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { baseValidation } from "../../../validation/baseValidation";
import { validationHelper } from "../../../validation/validationHelper";
import { publisherValidation } from "../../../validation/publisherValidation";
import PrismCodeEditor from "@/components/Settings/TagsManager/PrismCodeEditor.vue";

export default {
  name: "PriceFloorCurrencyDialog",
  props: ["editedItem", "editedIndex"],
  components: { PrismCodeEditor },
  mixins: [baseValidation, validationHelper, publisherValidation],
  data() {
    return {
      show: false,
      readOnly: false,
      valid: true,
      disable_select: false,
      datePicker: false,
      invoiceContacts: [],
      editable: true,
      priceBucket: "[{}]",
      error: false,
    };
  },
  watch: {
    editedItem() {
      this.priceBucket = "[{}]";
      this.formatEditedItems();
    },
  },
  mounted() {
    this.fetchAllCurrencies();
    this.fetchCurrencyCodes();
  },
  computed: {
    ...mapGetters("priceFloorSettings", [
      "getAllCurrencies",
      "getCurrencyCodes",
    ]),
  },
  methods: {
    ...mapActions("priceFloorSettings", [
      "fetchAllCurrencies",
      "fetchCurrencyCodes",
      "editCurrency",
      "addCurrency",
    ]),
    formatEditedItems() {
      if (JSON.stringify(this.editedItem.bucket_definition)) {
        this.priceBucket = JSON.stringify(this.editedItem.bucket_definition);
      }
    },
    close() {
      this.show = false;
      this.error = false;
    },
    save() {
      if (this.$refs.form.validate()) {
        let parsedPriceBucket = JSON.parse(this.priceBucket);
        if (
          !Array.isArray(parsedPriceBucket) ||
          typeof parsedPriceBucket !== "object"
        ) {
          this.error = true;
        } else if (parsedPriceBucket.length === 0) {
          this.error = true;
        } else if (
          parsedPriceBucket.length &&
          !parsedPriceBucket.every(value => typeof value === "object")
        ) {
          this.error = true;
        } else {
          if (this.editedIndex === -1) {
            this.addCurrency({
              ...this.editedItem,
              bucket_definition: parsedPriceBucket,
            });
          } else {
            this.editCurrency({
              ...this.editedItem,
              bucket_definition: parsedPriceBucket,
            });
          }
          this.error = false;
          this.close();
        }
      }
    },
  },
};
</script>

<style scoped>
.country-extra {
  display: flex;
  flex-flow: column wrap;
  max-height: 650px;
  gap: 0 50px;
}
</style>
