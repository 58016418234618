<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-card-title>
          {{ $tc("price_floor.price_floor_publishers", 1) }}
        </v-card-title>
      </v-row>
      <PriceFloorPublishers />
    </v-col>
  </v-row>
</template>

<script>
import PriceFloorPublishers from "@/components/PriceFloor/Publishers/PriceFloorPublishers.vue";

export default {
  components: {
    PriceFloorPublishers,
  },
};
</script>

<style></style>
