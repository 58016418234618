<template>
  <v-data-table
    :loading="getLoading"
    :headers="getHeaders"
    :items="getPublisherFinancialFilteredRows"
    item-key="id"
    fixed-header
    :items-per-page="30"
    :footer-props="{
      'show-first-last-page': true,
      'items-per-page-options': [10, 20, 30, 50, 100],
    }"
  >
    <template v-slot:top>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="12">
              <v-form ref="pub_select">
                <v-row>
                  <v-col cols="2">
                    <v-menu
                      v-model="datePicker"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="datePickerDate"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="datePickerDate"
                        type="month"
                        @input="selectMonth(datePickerDate)"
                        :rules="createRules([baseValidation.required])"
                      />
                    </v-menu>
                  </v-col>
                  <v-col class="d-flex" cols="auto">
                    <v-autocomplete
                      :items="getPublishers"
                      item-text="name"
                      item-value="id"
                      :label="$t('sites.select_label')"
                      :v-model="publisher"
                      :value="publisher"
                      @input="selectPublisher($event)"
                      :rules="createRules([baseValidation.required])"
                    />
                  </v-col>
                  <v-col class="d-flex" cols="auto">
                    <v-select
                      :items="getProducts"
                      item-text="name"
                      item-value="id"
                      :label="$t('product.select_product')"
                      :value="getProductId"
                      @change="selectProduct($event)"
                      :rules="createRules([baseValidation.required])"
                    />
                  </v-col>
                  <v-col class="d-flex" cols="auto">
                    <v-switch v-model="showSite" label="Show site" />
                  </v-col>
                  <v-col class="d-flex" cols="auto">
                    <v-autocomplete
                      :items="getPaymentTerms"
                      item-text="name"
                      item-value="name"
                      v-model="filter.payment_term"
                      label="Payment Term"
                      @change="updateQueryPaymentTerm"
                    >
                      <template v-slot:append-outer>
                        <v-icon
                          icon
                          v-on:click="
                            () => {
                              filter.payment_term = null;
                              updateQueryPaymentTerm(null);
                            }
                          "
                        >
                          delete
                        </v-icon>
                      </template></v-autocomplete
                    >
                  </v-col>
                  <v-col class="d-flex" cols="auto">
                    <v-autocomplete
                      :items="dpa_types_list"
                      v-model="filter.dpa_type"
                      label="DPA Type"
                      @change="updateQueryDpaType"
                    >
                      <template v-slot:append-outer>
                        <v-icon
                          icon
                          v-on:click="
                            () => {
                              filter.dpa_type = null;
                              updateQueryDpaType(null);
                            }
                          "
                        >
                          delete
                        </v-icon>
                      </template></v-autocomplete
                    >
                  </v-col>
                  <v-col>
                    <v-btn
                      class="ma-4"
                      color="success"
                      v-on:click="fetchPublisherFinancial"
                    >
                      Refresh
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-divider />
              <PaymentInfo />
              <v-divider />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
    <template v-slot:item.estimated_revenue="{ value }">
      {{ formatMoney(value) + " €" }}
    </template>
    <template v-slot:item.final_revenue="{ value }">
      {{ formatMoney(value) + " €" }}
    </template>
    <template v-slot:item.final_turnover_net="{ value }">
      {{ formatMoney(value) + " €" }}
    </template>
    <template v-slot:item.final_margin_optidigital="{ value }">
      {{ formatMoney(value) + " €" }}
    </template>
    <template v-slot:item.status="{ value }">
      <v-tooltip bottom :disabled="value.name === 'Estimated'">
        <template #activator="{ on }">
          <v-chip :color="mapFinancialStatusColor(value.name)" v-on="on">
            {{ value.name }}
          </v-chip>
        </template>
        <span v-if="value.name !== 'Estimated'"> {{ value.date }} </span>
      </v-tooltip>
    </template>
    <template v-slot:body.append>
      <tr>
        <th>Total:</th>
        <th v-if="showSite" />
        <th />
        <th />
        <td align="right">
          <b>
            {{
              formatMoney(getPublisherFinancial.total_estimated || 0) + " €"
            }}</b
          >
        </td>
        <td align="right">
          <b>
            {{ formatMoney(getPublisherFinancial.total_final || 0) + " €" }}</b
          >
        </td>
        <td align="right">
          <b> {{ getPublisherFinancial.correction_ratio_global }}</b>
        </td>
        <td align="right">
          <b>
            {{
              formatMoney(getPublisherFinancial.total_turnover_net || 0) + " €"
            }}</b
          >
        </td>
        <td align="right">
          <b>
            {{
              formatMoney(getPublisherFinancial.total_margin_optidigital || 0) +
                " €"
            }}</b
          >
        </td>
        <th />
        <th />
      </tr>
    </template>
    <v-row><v-divider /></v-row>
  </v-data-table>
</template>

<script>
import store from "./../../../store/index";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { moneyMixin } from "@/helpers/moneyMixin";
import { baseValidation } from "@/validation/baseValidation";
import { validationHelper } from "@/validation/validationHelper";
import { mapStatusColor } from "@/helpers/mapStatusColorMixin";
import PaymentInfo from "./PaymentInfo.vue";

export default {
  name: "ReportingTurnoverFinalTable",
  mixins: [moneyMixin, baseValidation, validationHelper, mapStatusColor],
  watch: {
    showSite(newValue) {
      this.setShowSite(newValue);
      store.dispatch("turnoverFinal/fetchPublisherFinancial");
    },
  },
  data() {
    return {
      datePicker: false,
      datePickerDate: null,
      publisher: null,
      showSite: false,
      dpa_types_list: ["publisher", "optidigital"],
      filter: {
        dpa_type: null,
        payment_term: null,
      },
      publisher_header: {
        text: "Publisher Site",
        value: "publisher_site",
      },
      headers: [
        {
          text: "Publisher",
          value: "publisher_name",
        },
        {
          text: "Demand Partner Account",
          value: "dpa_name",
        },
        {
          text: "DPA Type",
          value: "dpa_type",
        },
        {
          text: "Estimated Revenue",
          value: "estimated_revenue",
          align: "right",
        },
        {
          text: "Final Revenue",
          value: "final_revenue",
          align: "right",
        },
        {
          text: "Correction Ratio",
          value: "correction_ratio",
          align: "right",
        },
        {
          text: "Publisher Net",
          value: "final_turnover_net",
          align: "right",
        },
        {
          text: "Opti Digital Net",
          value: "final_margin_optidigital",
          align: "right",
        },
        {
          text: "Payment Term",
          value: "payment_term",
          align: "right",
        },
        {
          text: "Status",
          value: "status",
        },
      ],
    };
  },
  beforeDestroy() {
    this.datePickerDate = null;
    this.publisher = null;
    this.filter.dpa_type = null;
    this.setUpDate();
    this.setDate(null);
    this.setPublisherId(null);
    this.setPublisherFinancial({
      rows: [],
      estimated_revenue: 0,
      final_revenue: 0,
    });
  },
  computed: {
    ...mapGetters("turnoverFinal", [
      "getLoading",
      "getPublisherFinancial",
      "getPublisherId",
      "getProductId",
      "getDate",
    ]),
    ...mapGetters("product", {
      getProducts: "getProducts",
      getProductsLoading: "getLoading",
    }),
    ...mapGetters("paymentTerm", ["getPaymentTerms"]),
    ...mapGetters("publishers", [
      "getPublishers",
      "getPennylaneMappingPublisher",
    ]),
    ...mapActions("publishers", ["fetchPublishers"]),
    getHeaders() {
      if (this.showSite) {
        return [
          ...this.headers.slice(0, 1),
          this.publisher_header,
          ...this.headers.slice(1),
        ];
      }
      return this.headers;
    },
    getPublisherFinancialFilteredRows() {
      if (!this.getPublisherFinancial.rows) {
        return [];
      }
      return this.getPublisherFinancial.rows.filter(value => {
        if (this.filter.dpa_type) {
          if (value.dpa_type !== this.filter.dpa_type) {
            return false;
          }
        }
        if (this.filter.payment_term) {
          if (value.payment_term !== this.filter.payment_term) {
            return false;
          }
        }
        return true;
      });
    },
  },
  created() {
    store.dispatch("publishers/fetchPublishers");
    store.dispatch("product/fetchProducts");
    store.dispatch("turnoverFinal/fetchPublisherFinancial");
    this.fetchPaymentTerms();
    this.setQueryFilters({ paymentTerm: "", DpaType: "" });
    this.setShowSite(this.showSite);
    this.setUpDate(this);
    this.publisher = this.getPublisherId || null;
    const urlSearchParams = new URLSearchParams(window.location.search);
    const queryStringParams = Object.fromEntries(urlSearchParams.entries());
    if (Object.entries(queryStringParams).length !== 0) {
      this.publisher = parseInt(queryStringParams.publisher_id);
      this.selectPublisher(parseInt(queryStringParams.publisher_id));
    }
  },
  mounted() {
    this.$refs.pub_select.validate();
  },
  methods: {
    ...mapActions("paymentTerm", ["fetchPaymentTerms"]),
    ...mapActions("turnoverFinal", [
      "fetchPublisherFinancial",
      "setPublisher",
      "setSelectedDate",
    ]),
    ...mapMutations("turnoverFinal", [
      "setShowSite",
      "setQueryFilters",
      "setPublisherId",
      "setProductId",
      "setDate",
      "setPublisherFinancial",
    ]),
    setUpDate() {
      let d = new Date();
      d.setDate(0); //prev month
      d.setDate(1); // set first day of prev month
      this.datePickerDate = this.getDate || d.toISOString().substr(0, 7);
      store.dispatch("turnoverFinal/setSelectedDate", this.datePickerDate);
    },
    selectPublisher(event) {
      store.dispatch("turnoverFinal/setPublisher", event);
      store.dispatch("turnoverFinal/fetchPublisherFinancial");
      store.dispatch("publishers/getPennylaneClientMappingByPublisher", event);
    },
    selectProduct(event) {
      store.commit("turnoverFinal/setProductId", event);
      store.dispatch("turnoverFinal/fetchPublisherFinancial");
    },
    selectMonth(event) {
      store.dispatch("turnoverFinal/setSelectedDate", event);
      this.datePicker = false;
      store.dispatch("turnoverFinal/fetchPublisherFinancial");
    },
    updateQueryPaymentTerm(paymentTerm) {
      this.setQueryFilters({ paymentTerm, dpaType: this.filter.dpa_type });
      store.dispatch("turnoverFinal/fetchPublisherFinancial");
    },
    updateQueryDpaType(dpaType) {
      this.setQueryFilters({
        paymentTerm: this.filter.payment_term,
        dpaType,
      });
      store.dispatch("turnoverFinal/fetchPublisherFinancial");
    },
  },
  components: { PaymentInfo },
};
</script>

<style scoped></style>
