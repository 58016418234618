<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-row justify="center" align="center">
        <v-col cols="5">
          <v-row justify="center" align="center">
            <v-col>
              <v-card-title>
                Auto-invoices Turnover Publisher
              </v-card-title>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-row align="center" justify="center" class="pt-5 pb-5">
                <v-col cols="6">
                  <v-row align="center" justify="center">
                    <v-card-title>Net 30:</v-card-title>
                    <v-chip
                      :color="
                        mapFinancialPaymentColor(
                          getPaymentStatusInfo.opti_net30.status
                        )
                      "
                    >
                      {{
                        getStatusName(getPaymentStatusInfo.opti_net30.status)
                      }}
                    </v-chip>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <PaymentInfoActionButtons
                    class="text-center"
                    :paymentInfo="getPaymentStatusInfo.opti_net30"
                    dpa_type="optidigital"
                    payment_term="NET 30"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider />
          <v-row>
            <v-col cols="12">
              <v-row align="center" justify="center" class="pt-5 pb-5">
                <v-col cols="6">
                  <v-row align="center" justify="center">
                    <v-card-title>Net 60:</v-card-title>
                    <v-chip
                      :color="
                        mapFinancialPaymentColor(
                          getPaymentStatusInfo.opti_net60.status
                        )
                      "
                    >
                      {{
                        getStatusName(getPaymentStatusInfo.opti_net60.status)
                      }}
                    </v-chip>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <PaymentInfoActionButtons
                    class="text-center"
                    :paymentInfo="getPaymentStatusInfo.opti_net60"
                    dpa_type="optidigital"
                    payment_term="NET 60"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-divider vertical />
        <v-col cols="5">
          <v-row justify="center" align="center">
            <v-col>
              <v-card-title>
                Invoice commission Opti Digital
              </v-card-title>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-row align="center" justify="center" class="pt-5 pb-5">
                <v-col cols="6">
                  <v-row align="center" justify="center">
                    <v-card-title>Net 30:</v-card-title>
                    <v-chip
                      :color="
                        mapFinancialPaymentColor(
                          getPaymentStatusInfo.pub_net30.status
                        )
                      "
                    >
                      {{ getStatusName(getPaymentStatusInfo.pub_net30.status) }}
                    </v-chip>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <PaymentInfoActionButtons
                    class="text-center"
                    :paymentInfo="getPaymentStatusInfo.pub_net30"
                    dpa_type="publisher"
                    payment_term="NET 30"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider />
          <v-row>
            <v-col cols="12">
              <v-row align="center" justify="center" class="pt-5 pb-5">
                <v-col cols="6">
                  <v-row align="center" justify="center">
                    <v-card-title>Net 60:</v-card-title>
                    <v-chip
                      :color="
                        mapFinancialPaymentColor(
                          getPaymentStatusInfo.pub_net60.status
                        )
                      "
                    >
                      {{ getStatusName(getPaymentStatusInfo.pub_net60.status) }}
                    </v-chip>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <PaymentInfoActionButtons
                    class="text-center"
                    :paymentInfo="getPaymentStatusInfo.pub_net60"
                    dpa_type="publisher"
                    payment_term="NET 60"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import PaymentInfoActionButtons from "./PaymentInfoActionButtons.vue";
import { mapStatusColor } from "@/helpers/mapStatusColorMixin";
import { mapGetters } from "vuex";

export default {
  components: { PaymentInfoActionButtons },
  mixins: [mapStatusColor],
  computed: {
    ...mapGetters("turnoverFinal", ["getPaymentStatusInfo"]),
  },

  methods: {
    getStatusName(status) {
      const sanitized = status?.toLowerCase().trim();
      return (
        {
          ["pending_validation"]: "Pending Validation",
          ["validated"]: "Validated",
          ["invoiced"]: "Invoiced",
          ["invoiced_draft"]: "Invoiced Draft",
          ["payment_done"]: "Payment Done",
          ["invoice_sent"]: "Invoice Sent",
        }[sanitized] ?? "Not Available"
      );
    },
  },
};
</script>

<style></style>
