<template>
  <v-dialog v-model="show" max-width="80%">
    <v-card style="overflow-x: hidden;">
      <v-row align="center">
        <v-col cols="auto">
          <v-card-title>
            <span>Edit Publisher's data:</span>
          </v-card-title>
        </v-col>
        <v-spacer />
      </v-row>
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-row>
                <v-col cols="12">
                  <v-card-title>{{ $t("publishers.general") }}:</v-card-title>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    readonly
                    v-model="getPublisherData.name"
                    label="Name"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    readonly
                    v-model="getPublisherData.gam_network_id"
                    label="GAM Network ID"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    readonly
                    v-model="getPublisherData.odas_account_name"
                    label="ODAS Account Name"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    readonly
                    v-model="getPublisherData.url"
                    label="URL"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-card-title
                    >{{ $t("publishers.company_info") }}:</v-card-title
                  >
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    readonly
                    v-model="getPublisherData.company_name"
                    label="Company Name"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    readonly
                    v-model="getPublisherData.company_address"
                    label="Company Address"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    readonly
                    v-model="getPublisherData.company_country"
                    label="Company Country"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    readonly
                    v-model="getPublisherData.company_iban"
                    label="Company IBAN"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    readonly
                    v-model="getPublisherData.company_bic"
                    label="Company BIC"
                  />
                </v-col>
                <v-col cols="auto">
                  <v-switch
                    readonly
                    v-model="getPublisherData.vat_present"
                    label="Use VAT"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    readonly
                    v-model="getPublisherData.company_vat"
                    label="Company VAT"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    readonly
                    v-model="getPublisherData.sales_contact_name"
                    label="Sales Contact Name"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    readonly
                    v-model="getPublisherData.sales_contact_email"
                    label="Sales Contact Email"
                  />
                </v-col>
              </v-row>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="close">{{
          $t("buttons.general.close")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "PublisherDialog",
  props: ["editedIndex", "publisher-data"],
  data() {
    return {
      show: false,
      readOnly: true,
      valid: true,
    };
  },
  computed: {
    ...mapGetters("priceFloorPublishers", ["getPublisherData"]),
  },
  methods: {
    ...mapActions("priceFloorPublishers", ["fetchDataForPublisher"]),

    close() {
      this.show = false;
    },
  },
};
</script>

<style scoped></style>
