<template>
  <v-col>
    <v-row>
      <v-card-title>Publisher Financial View</v-card-title>
      <v-snackbar v-model="getInvoiceSnackbar" top :color="snackbar_color">
        {{ getInvoiceSentNotification }}
        <v-btn color="white" text @click="snackbar = false">
          Close
        </v-btn>
      </v-snackbar>
    </v-row>
    <ReportingTurnoverFinalTable />
  </v-col>
</template>

<script>
import ReportingTurnoverFinalTable from "../../components/Accounting/ReportingTurnoverFinal/ReportingTurnoverFinalTable";
import { mapGetters } from "vuex";

export default {
  name: "ReportingTurnoverFinal",
  components: { ReportingTurnoverFinalTable },
  data() {
    return {
      snackbar_color: "blue",
    };
  },
  computed: {
    ...mapGetters("turnoverFinal", [
      "getInvoiceSentNotification",
      "getInvoiceSnackbar",
    ]),
  },
};
</script>

<style scoped></style>
