import axios from "axios";
import to from "await-to-js";

let store;
store = {
  namespaced: true,

  state: {
    users: [],
    agents: [],
    invoice_types: [],
    currencies: [],
    profile: {
      user_id: "",
      email: "",
      role: "",
      google_name: "",
      google_picture_url: "",
    },
    nav_menu_routes: {
      home: { title: "home", path: "/", icon: "mdi-home" },
      public_config: {
        title: "public_config",
        path: "/publicConfig/sellersJson",
        icon: "mdi-tag-text",
      },
      inventory: { title: "inventory", path: "/api", icon: "mdi-tag-multiple" },
      demand: { title: "demand", path: "/api", icon: "mdi-account-cash" },
      billing: { title: "billing", path: "/api", icon: "mdi-currency-eur" },
      settings: { title: "settings", path: "/settings", icon: "mdi-settings" },
      releases: { title: "releases", path: "/releases", icon: "mdi-web" },
      publishers: {
        title: "publishers",
        path: "/accounting/publishers",
      },
      sites: { title: "sites", path: "/accounting/sites", icon: "mdi-web" },
      change_history: {
        title: "change_history",
        path: "/accounting/changeHistory",
        icon: "mdi-history",
      },
      ab_tests: {
        title: "ab_tests",
        path: "/accounting/abTests",
        icon: "mdi-ab-testing",
      },
      demand_partners: {
        title: "demand_partners",
        path: "/accounting/demandPartners",
      },
      demand_partners_account: {
        title: "demand_partners_account",
        path: "/accounting/demandPartnerAccounts/",
      },
      financial_reporting: {
        title: "financial_reporting",
        path: "/accounting/financialReporting/",
      },
      publisher_financial: {
        title: "publisher_financial",
        path: "/accounting/publisherFinancial/",
      },
      /* payments: {
        title: "payments",
        path: "/accounting/payments/",
      }, */
      sellers_json: {
        title: "sellers_json",
        path: "/publicConfig/sellersJson",
      },
      releases_web: {
        title: "releases_web",
        path: "/releases/web",
        icon: "mdi-home",
      },
      releases_amp: {
        title: "releases_amp",
        path: "/releases/amp",
        icon: "mdi-home",
      },
      releases_audit_log: {
        title: "releases_audit_log",
        path: "/releases/log",
        icon: "mdi-home",
      },
      releases_redis_live: {
        title: "releases_redis_live",
        path: "/releases/redis",
        icon: "mdi-home",
      },
      price_floor: {
        title: "price_floor",
        path: "/priceFloor",
        icon: "mdi-chart-timeline-variant",
      },
      price_floor_configuration: {
        title: "price_floor_configuration",
        path: "/priceFloor/configuration",
        icon: "mdi-chart-timeline-variant",
      },
      price_floor_publishers: {
        title: "price_floor_publishers",
        path: "/priceFloor/publishers",
        icon: "mdi-chart-timeline-variant",
      },
      price_floor_settings: {
        title: "price_floor_settings",
        path: "/priceFloor/settings",
        icon: "mdi-chart-timeline-variant",
      },
    },
    nav_menu: [
      {
        title: "",
        sub_items: [
          {
            title: "",
          },
        ],
      },
    ],
    authorization_token: localStorage.getItem("authorization_token") || "",
    refresh_authorization_token:
      localStorage.getItem("refresh_authorization_token") || "",
    refreshing_token: null,
    my_publisher: "",
    extern_view: localStorage.getItem("extern_view") === "true" ?? false,
  },

  getters: {
    getViewExtern: state => state.extern_view,
    getMyPublisher: state => state.my_publisher,
    getUserProfile: state => state.profile,
    getUserProfileImg: state => state.profile.google_picture_url,
    getNavMenu: state => state.nav_menu,
    getUsers: state => state.users,
    getAgents: state => state.agents,
    getInvoiceTypes: state => state.invoice_types,
    getCurrencies: state => state.currencies,
    getNavMenuRoutes: state =>
      state.nav_menu.map(item => {
        let ret = state.nav_menu_routes[item.title];
        if (item.sub_items) {
          ret.sub_items = item.sub_items
            .map(sub_item => state.nav_menu_routes[sub_item.title] || undefined)
            .filter(el => el !== undefined);
        }
        return ret;
      }),
    authorization_token: state => state.authorization_token,
    refresh_authorization_token: state => state.refresh_authorization_token,
    getRefreshingToken: state => state.refreshing_token,
    isAuthorizationToken: state =>
      state.authorization_token !== "" &&
      state.refresh_authorization_token !== "",
    appVersion: () => {
      return process.env.VUE_APP_VERSION;
    },
    env: () => process.env.NODE_ENV,
  },

  mutations: {
    setViewExtern(state, { value = true, reload = true } = {}) {
      if (window.reload_opti_UI_timeout) {
        clearTimeout(window.reload_opti_UI_timeout);
      }
      state.extern_view = value;
      localStorage.setItem("extern_view", state.extern_view);
      if (reload) {
        window.reload_opti_UI_timeout = setTimeout(
          () => location.reload(),
          500
        );
      }
    },
    setMyPublisher(state, my_publisher) {
      state.my_publisher = my_publisher;
    },
    setContext(state, profile) {
      state.profile = profile;
    },
    setRefreshingToken(state, refreshing) {
      state.refreshing_token = refreshing;
    },
    setAuthorizationToken(state, authorization_token) {
      state.authorization_token = authorization_token;
      localStorage.setItem("authorization_token", authorization_token);
      axios.defaults.headers.common.Authorization = `${authorization_token}`;
    },
    setRefreshAuthorizationToken(state, refresh_authorization_token) {
      state.refresh_authorization_token = refresh_authorization_token;
      localStorage.setItem(
        "refresh_authorization_token",
        refresh_authorization_token
      );
    },
    removeAuthorizationToken(state) {
      state.authorization_token = "";
    },
    removeRefreshAuthorizationToken(state) {
      state.refresh_authorization_token = "";
    },
    setNavMenu(state, navMenu) {
      state.nav_menu = navMenu;
    },
    setUsers(state, users) {
      state.users = users;
    },
    setAgents(state, agents) {
      state.agents = agents;
    },
    setInvoiceTypes(state, invoice_types) {
      state.invoice_types = invoice_types;
    },
    setCurrencies(state, currencies) {
      state.currencies = currencies;
    },
    setGamNetworkName(state, network_name) {
      state.gam_network_name = network_name;
    },
  },

  actions: {
    async fetchMyPublisher({ commit }) {
      const response = await axios.get("/user/me/publisher");
      if (response.status === 200) {
        const my_publisher_name = response.data?.data?.publisher;
        commit("setMyPublisher", my_publisher_name);
        return my_publisher_name;
      }
    },
    async loginGoogle({ commit }, authCode) {
      return to(
        axios
          .post("/auth/google", { code: authCode, redirect_uri: "postmessage" })
          .then(response => {
            if (response.status === 200) {
              commit("setAuthorizationToken", response.data.Authorization);
              commit(
                "setRefreshAuthorizationToken",
                response.data.RefreshAuthorization
              );
              commit("setContext", response.data);
              return true;
            }
            return false;
          })
          .catch(() => {
            return false;
          })
      );
    },
    async logout({ commit }) {
      return new Promise(resolve => {
        commit("removeAuthorizationToken");
        commit("removeRefreshAuthorizationToken");
        localStorage.removeItem("authorization_token");
        localStorage.removeItem("refresh_authorization_token");
        axios
          .post("/auth/logout")
          .then(() => {
            delete axios.defaults.headers.common.Authorization;
            window.location.reload();
          })
          .catch(() => {
            delete axios.defaults.headers.common.Authorization;
            window.location.reload();
          });
        resolve();
      });
    },
    async fetchNavMenu({ commit }) {
      return to(
        axios
          .get("/context/nav_menu")
          .then(response => {
            if (response.status === 200) {
              commit("setNavMenu", response.data);
              return true;
            }
          })
          .catch(() => {
            return false;
          })
      );
    },
    fetchContext({ commit }) {
      return axios
        .get("/context/profile")
        .then(response => {
          if (response.status === 200) {
            commit("setContext", response.data);
            return true;
          }
        })
        .catch(error => {
          throw error;
        });
    },
    fetchUsers({ commit }) {
      /* requires admin */
      return axios
        .get("/user/")
        .then(response => {
          if (response.status === 200) {
            commit("setUsers", response.data.data);
            return true;
          }
        })
        .catch(error => {
          throw error;
        });
    },
    fetchAgents({ commit }) {
      return axios
        .get("/user/agents")
        .then(response => {
          if (response.status === 200) {
            commit("setAgents", response.data.data);
            return true;
          }
        })
        .catch(error => {
          throw error;
        });
    },
    fetchInvoiceTypes({ commit }) {
      return axios
        .get("/invoice/types")
        .then(response => {
          if (response.status === 200) {
            commit("setInvoiceTypes", response.data);
            return true;
          }
        })
        .catch(error => {
          throw error;
        });
    },
    fetchCurrencies({ commit }) {
      return axios
        .get("/dicts/currencies")
        .then(response => {
          if (response.status === 200) {
            commit("setCurrencies", response.data);
            return true;
          }
        })
        .catch(error => {
          throw error;
        });
    },
  },
};

export default store;
