import Vue from "vue";
import VueRouter from "vue-router";
import AdminDashboard from "./pages/Admin/AdminDashboard.vue";
import Login from "./pages/Login.vue";
import Layout from "./pages/Layout.vue";
import store from "./store/index";
import AuditLog from "./pages/Admin/AuditLog.vue";
import SubPagesRouter from "./pages/SubPagesRouter.vue";
import Publishers from "./pages/Accounnting/Publishers.vue";
import AccountingDashboard from "./pages/Accounnting/AccountingDashboard.vue";
import DemandPartners from "./pages/Accounnting/DemandPartners";
import DemandPartnerAccounts from "./pages/Accounnting/DemandPartnerAccounts";
import FinancialReporting from "./pages/Accounnting/FinancialReporting";
import Payments from "./pages/Accounnting/Payments";
import FourOhFour from "./components/FourOhFour";
import ReportingTurnoverFinal from "./pages/Accounnting/ReportingTurnoverFinal";
import AdConfigRender from "./pages/Settings/AdConfigRender";
import SellersJson from "@/pages/PublicConfig/SellersJson";
import ReleasesAuditLog from "./pages/Releases/ReleasesAuditLog.vue";
import ReleasesTable from "./pages/Releases/ReleasesTable.vue";
import ReleasesRedis from "./pages/Releases/ReleasesRedis.vue";
import FileDiffPage from "./pages/Settings/FileDiffPage";
import SyncReportsPage from "./pages/SSP/SyncReportsPage";
import PriceFloorConfiguration from "./pages/PriceFloor/PriceFloorConfiguration.vue";
import PriceFloorPublishers from "./pages/PriceFloor/PriceFloorPublishers.vue";
import PriceFloorSettings from "./pages/PriceFloor/PriceFloorSettings.vue";

Vue.use(VueRouter);

const routing_strategy = ({ extern, default_route }) => {
  const user = store.getters["context/getUserProfile"];
  store.commit("context/setViewExtern", { value: true, reload: false });
  const force_extern_view = /*store.getters["context/getViewExtern"]*/ true;

  if (user.role === "extern" || force_extern_view) {
    return extern;
  }
  return default_route;
};

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/",
      component: Layout,
      children: [
        {
          path: "/admin",
          component: SubPagesRouter,
          children: [
            {
              path: "/",
              name: "Dashboard",
              component: AdminDashboard,
            },
            {
              path: "audit-log",
              name: "AuditLog",
              component: AuditLog,
            },
          ],
        },
        {
          path: "/publicConfig",
          component: SubPagesRouter,
          children: [
            {
              path: "sellersJson",
              name: "SellersJson",
              component: SellersJson,
            },
          ],
        },
        {
          path: "/accounting",
          component: SubPagesRouter,
          children: [
            {
              path: "/",
              name: "AccountingDashboard",
              component: AccountingDashboard,
            },
            {
              path: "publishers",
              name: "AccountingPublishers",
              component: Publishers,
            },
            {
              path: "sites",
              name: "SiteEntities",
              component() {
                return routing_strategy({
                  extern: import(
                    /* webpackChunkName: "ext-site" */ "@/components/Extern/Sites/ExternSites.vue"
                  ),
                  default_route: import(
                    /* webpackChunkName: "site" */ "@/pages/Accounnting/Sites.vue"
                  ),
                });
              },
            },
            {
              path: "changeHistory",
              name: "changeHistory",
              component() {
                return routing_strategy({
                  extern: import(
                    /* webpackChunkName: "ext-history" */
                    "@/components/Extern/History/ExternHistory.vue"
                  ),
                  default_route: import(
                    /* webpackChunkName: "ext-history" */ "@/pages/Accounnting/ChangeHistory.vue"
                  ),
                });
              },
            },
            {
              path: "abTests",
              name: "abTests",
              component() {
                return routing_strategy({
                  extern: import(
                    /* webpackChunkName: "ext-ab-test" */
                    "@/components/Extern/AbTests/ExternAbTests.vue"
                  ),
                  default_route: import(
                    /* webpackChunkName: "ab-test" */ "@/pages/Accounnting/AbTests.vue"
                  ),
                });
              },
            },
            {
              path: "demandPartners",
              name: "DemandPartners",
              component: DemandPartners,
            },
            {
              path: "demandPartnerAccounts",
              name: "DemandPartnerAccounts",
              component: DemandPartnerAccounts,
            },
            {
              path: "financialReporting",
              name: "FinancialReporting",
              component: FinancialReporting,
            },
            {
              path: "publisherFinancial",
              name: "PublisherFinancial",
              component: ReportingTurnoverFinal,
            },
            {
              path: "payments",
              name: "Payments",
              component: Payments,
            },
          ],
        },
        {
          path: "/",
          name: "Home",
          component() {
            return routing_strategy({
              extern: import(
                /* webpackChunkName: "ext-home-page" */ "@/components/Extern/ExternHome.vue"
              ),
              default_route: import(
                /* webpackChunkName: "home-page" */ "@/pages/Home"
              ),
            });
          },
        },
        {
          path: "404",
          name: "FourOhFour",
          component: FourOhFour,
        },
        {
          path: "/settings",
          component: SubPagesRouter,
          children: [
            {
              path: "TagsManager",
              name: "TagsManager",
              component() {
                return routing_strategy({
                  extern: import(
                    /* webpackChunkName: "ext-tm" */
                    "@/components/Extern/TagsManager/ExternTagsManager.vue"
                  ),
                  default_route: import(
                    /* webpackChunkName: "tm" */ "@/pages/Settings/TagsManager"
                  ),
                });
              },
            },
            {
              path: "AdConfigRender",
              name: "AdConfigRender",
              component: AdConfigRender,
            },
            {
              path: "FileDiff",
              name: "FileDiff",
              component: FileDiffPage,
            },
          ],
        },
        {
          path: "/ssp",
          component: SubPagesRouter,
          children: [
            {
              path: "sync_reports",
              name: "SyncReports",
              component: SyncReportsPage,
            },
          ],
        },
        {
          path: "/releases",
          component: SubPagesRouter,
          children: [
            {
              path: "web",
              name: "ReleasesWeb",
              component: ReleasesTable,
              props: {
                inventoryType: "WEB",
              },
            },
            {
              path: "amp",
              name: "ReleasesAmp",
              component: ReleasesTable,
              props: {
                inventoryType: "AMP",
              },
            },
            {
              path: "log",
              name: "ReleasesAuditLog",
              component: ReleasesAuditLog,
            },
            {
              path: "redis",
              name: "ReleasesRedis",
              component: ReleasesRedis,
            },
          ],
        },
        {
          path: "/priceFloor",
          component: SubPagesRouter,
          children: [
            {
              path: "configuration",
              name: "PriceFloorConfiguration",
              component: PriceFloorConfiguration,
            },
            {
              path: "publishers",
              name: "PriceFloorPublishers",
              component: PriceFloorPublishers,
            },
            {
              path: "settings",
              name: "PriceFloorSettings",
              component: PriceFloorSettings,
            },
          ],
        },
      ],
    },
    {
      path: "*",
      redirect: "/404",
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const loginPage = "/login";
  if (to.path !== loginPage) {
    if (store.getters["context/isAuthorizationToken"]) {
      await store.dispatch("context/fetchContext");
      if (
        to.path === "/admin" &&
        store.getters["context/getUserProfile"].role === "extern"
      ) {
        next("/404");
      }
      return next();
    }
    return next(loginPage);
  }
  return next();
});

export default router;
