<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-card-title>
          {{ $t("financial_reporting.financial_reporting") }}
        </v-card-title>
      </v-row>
      <FinancialReportingTable />
    </v-col>
  </v-row>
</template>

<script>
import FinancialReportingTable from "../../components/Accounting/FinancialReporting/FinancialReportingTable";
export default {
  name: "FinancialReporting",
  components: { FinancialReportingTable },
};
</script>

<style scoped></style>
