import axios from "axios";

const store = {
  namespaced: true,

  state: {
    loading: false,
    products: [],
  },

  getters: {
    getLoading: state => {
      return state.loading;
    },
    getProducts: state => {
      return state.products;
    },
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setProducts(state, products) {
      state.products = products;
    },
  },

  actions: {
    async fetchProducts({ commit }) {
      commit("setLoading", true);
      const response = await axios.get("/product/all");
      commit("setProducts", response.data);
      commit("setLoading", false);
      response.data;
    },
  },
};

export default store;
