<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-card-title>
          {{ $t("financial_reporting.payments") }}
        </v-card-title>
      </v-row>
      <PaymentsTable />
    </v-col>
  </v-row>
</template>

<script>
import PaymentsTable from "../../components/Accounting/Payments/PaymentsTable";
export default {
  name: "Payments",
  components: { PaymentsTable },
};
</script>

<style scoped></style>
