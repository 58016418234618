import axios from "axios";
import fileDownload from "js-file-download";

const store = {
  namespaced: true,

  state: {
    loading: true,
    rendered_text: "",
    is_config_rendered: false,
    revisionsList: [],
    ad_unit_id: null,
    rendererData: {
      revision: { number: 0, status: "none" },
      site: null,
      inventoryType: null,
    },
  },

  getters: {
    getLoading: state => {
      return state.loading;
    },
    getRenderedText: state => state.rendered_text,
    isConfigRendered: state => state.is_config_rendered,
    getAdUnitId: state => state.ad_unit_id,
    getRevisionsList: state => {
      return state.revisionsList;
    },
    isRenderDataSelected: state => {
      return (
        state.rendererData.site != null &&
        state.rendererData.inventoryType != null &&
        state.rendererData.revision !== 0
      );
    },
    isSiteAndInventoryTypeSelected: state => {
      return state.rendererData.site != null;
    },
    getRendererDataInventoryType: state => state.rendererData.inventoryType,
    getRendererDataSite: state => state.rendererData.site,
    getRendererDataRevision: state => state.rendererData.revision,
    getNewestRevision: state => {
      return (
        state.revisionsList.find(r => r.status === "live") ||
        state.revisionsList[0]
      );
    },
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setRevisionsList(state, revisionsList) {
      state.revisionsList = revisionsList;
    },
    setRenderedText(state, rendered_text) {
      state.rendered_text = rendered_text;
      state.is_config_rendered = false;
    },
    setRenderedTextConfig(state, rendered_text) {
      state.rendered_text = rendered_text;
      state.is_config_rendered = true;
    },
    setAdUnitId(state, ad_unit_id) {
      state.ad_unit_id = ad_unit_id;
    },
    setRendererConfigDataRevision(state, revision) {
      state.rendererData.revision = revision;
      state.rendered_text = "";
    },
    setRendererConfigData(state, renderData) {
      state.rendererData.inventoryType = renderData.inventoryType;
      state.rendererData.revision = renderData.revision;
      state.rendererData.site = renderData.site;
      state.rendered_text = "";
    },
  },

  actions: {
    async importAdConfig({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/ad_manager/config_loader", payload)
          .then(response => {
            if (response.status !== 200) throw new Error("Bad request");
            if (response.data.status === "warning") {
              commit("warnings/addWarning", response.data.warnings, {
                root: true,
              });
              commit("setLoading", false);
              resolve(response.data);
            }
            if (response.data.status !== "success")
              reject(Error(response.data.msg));
            commit("setLoading", false);
            resolve(response.data);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    async fetchSiteConfigInfo({ commit, getters }, selectConfigData) {
      commit("setRendererConfigData", selectConfigData);
      if (!getters.isSiteAndInventoryTypeSelected) {
        return;
      }
      commit("setLoading", true);
      await axios
        .get("/tags_manager/config_info", {
          params: {
            site_id: getters.getRendererDataSite.id,
            inventory_type: getters.getRendererDataInventoryType,
          },
        })
        .then(response => {
          if (response.data.revisions.length === 0) {
            commit("setRevisionsList", []);
            commit("setRendererConfigDataRevision", 0);
          } else {
            commit("setRevisionsList", response.data.revisions);
            commit("tagsManager/setRevision", getters.getNewestRevision, {
              root: true,
            });
            commit(
              "tagsManager/setInventoryType",
              getters.getRendererDataInventoryType,
              { root: true }
            );
            commit("tagsManager/setSite", getters.getRendererDataSite, {
              root: true,
            });
            if (selectConfigData.setNewestRevision) {
              commit(
                "setRendererConfigDataRevision",
                getters.getNewestRevision
              );
            }
          }
          commit("setLoading", false);
        });
    },
    async renderAdConfig({ commit, getters }) {
      commit("setLoading", false);
      let payload = {
        site_id: getters.getRendererDataSite.id,
        inventory_type: getters.getRendererDataInventoryType,
        revision: getters.getRendererDataRevision.number,
      };
      return new Promise((resolve, reject) => {
        axios
          .get("/ad_manager/config_renderer", {
            params: payload,
            transformResponse: [data => data],
          })
          .then(response => {
            if (response.status !== 200) throw new Error("Bad request");
            commit("setRenderedTextConfig", response.data);
            commit("setLoading", false);
            resolve();
          })
          .catch(() => {
            commit("setLoading", false);
            reject("Unable to render ad config");
          });
      });
    },
    async renderODASStoredRequest({ commit, getters }) {
      commit("setLoading", false);
      let payload = {
        site_id: getters.getRendererDataSite.id,
        inventory_type: getters.getRendererDataInventoryType,
        revision: getters.getRendererDataRevision.number,
      };
      return new Promise((resolve, reject) => {
        axios
          .get("/ad_manager/odas_sr_renderer", {
            params: payload,
            transformResponse: [data => data],
          })
          .then(response => {
            if (response.status !== 200) throw new Error("Bad request");
            commit("setRenderedText", response.data);
            commit("setLoading", false);
            resolve();
          })
          .catch(() => {
            commit("setLoading", false);
            reject("Unable to render ad config");
          });
      });
    },
    async downloadAdConfig({ commit, getters }) {
      let payload = {
        site_id: getters.getRendererDataSite.id,
        inventory_type: getters.getRendererDataInventoryType,
        revision: getters.getRendererDataRevision.number,
      };
      return new Promise((resolve, reject) => {
        axios
          .get("/ad_manager/config_renderer/download", {
            params: payload,
            transformResponse: [data => data],
            responseType: "blob", // Important
          })
          .then(response => {
            if (response.status !== 200) throw new Error("Bad request");
            const suggestedFileName = response.headers["x-suggested-filename"];
            fileDownload(response.data, suggestedFileName);
            resolve();
          })
          .catch(() => {
            commit("setLoading", false);
            reject("Unable to render ad config");
          });
      });
    },
    async generateTags({ commit, getters }, ad_unit_id) {
      commit("setLoading", false);
      let payload = {
        site_id: getters.getRendererDataSite.id,
        inventory_type: getters.getRendererDataInventoryType,
        revision: getters.getRendererDataRevision.number,
        ad_unit_id: ad_unit_id || getters.getAdUnitId,
      };
      commit("setAdUnitId", ad_unit_id);
      return new Promise((resolve, reject) => {
        axios
          .get("/ad_unit/generate_tags/", {
            params: payload,
          })
          .then(response => {
            if (response.status !== 200) reject(Error("Bad request"));
            if (response.headers["content-type"] === "application/json") {
              if (
                "status" in response.data &&
                response.data.status === "warning"
              ) {
                commit("warnings/addWarning", response.data.warnings, {
                  root: true,
                });
                reject(response.data.warnings);
              } else {
                reject("Bad status");
              }
            } else if (response.headers["content-type"] === "text/plain") {
              commit("setRenderedText", response.data);
            } else {
              commit("setLoading", false);
              resolve();
            }
          })
          .catch(() => {
            commit("setLoading", false);
            reject("Unable to generate tags");
          });
      });
    },
    async downloadTags({ commit, getters }, ad_unit_id) {
      commit("setLoading", false);
      let payload = {
        site_id: getters.getRendererDataSite.id,
        inventory_type: getters.getRendererDataInventoryType,
        revision: getters.getRendererDataRevision.number,
        ad_unit_id,
      };
      return new Promise((resolve, reject) => {
        axios
          .get("/ad_unit/generate_tags/download", {
            params: payload,
          })
          .then(response => {
            if (response.status !== 200) reject(Error("Bad request"));
            const suggestedFileName = response.headers["x-suggested-filename"];
            fileDownload(response.data, suggestedFileName);
            resolve();
          })
          .catch(() => {
            commit("setLoading", false);
            reject("Unable to generate tags");
          });
      });
    },
  },
};

export default store;
