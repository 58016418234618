var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',[_vm._v("Releases Audit Log")]),_c('v-row',{attrs:{"align":"center","justify":"space-around"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-row',[_c('v-text-field',{attrs:{"label":"Search action","loading":_vm.getLoading},on:{"change":() => {
                this.setAuditLogSearch(this.search_action);
                this.options.page = 1;
              }},model:{value:(_vm.search_action),callback:function ($$v) {_vm.search_action=$$v},expression:"search_action"}}),_c('v-icon',{attrs:{"disabled":!_vm.search_action,"color":"red darken-3","icon":""},on:{"click":() => {
                this.search_action = '';
                this.options.page = 1;
                this.setAuditLogSearch(this.search_action);
              }}},[_vm._v(" mdi-close-circle ")])],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"height":"80vh","items":_vm.getAuditLog,"loading":_vm.getLoading,"headers":_vm.headers,"fixed-header":"","server-items-length":_vm.getAuditLogCount,"items-per-page":100,"page":this.getAuditLogPage,"options":_vm.options,"item-key":"id","footer-props":{
          'items-per-page-options': [100],
          'show-first-last-page': true,
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.view_context`,fn:function({ item }){return [_c('v-btn',{staticClass:"mx-4",attrs:{"depressed":"","icon":"","large":""},on:{"click":function($event){return _vm.viewItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" visibility ")])],1)]}},{key:`item.context`,fn:function({ item }){return [_c('p',{staticClass:"truncate"},[_vm._v(_vm._s(item.context))])]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"60%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('v-icon',{staticClass:"ma-4",attrs:{"small":""}},[_vm._v("visibility")]),_vm._v(" Context view for action "+_vm._s(_vm.viewedItem.action)+" at "+_vm._s(_vm.viewedItem.date)+" ")],1),_c('v-card-text',[_c('v-textarea',{staticClass:"textarea",attrs:{"no-resize":"","rows":"20","outlined":"","readonly":"","label":"JSON"},model:{value:(_vm.viewedItem.context),callback:function ($$v) {_vm.$set(_vm.viewedItem, "context", $$v)},expression:"viewedItem.context"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v("Close")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }