<template>
  <v-container>
    <v-row v-if="label !== ''" class="my-0 mx-5" dense>
      <slot name="label"
        ><v-label>{{ label }}</v-label></slot
      >
    </v-row>
    <v-row class="my-0" dense>
      <v-col>
        <v-row dense>
          <prism-editor
            class="my-editor"
            v-bind:value="getValue"
            @input="
              updateValue($event);
              validate($event);
            "
            :highlight="getHighlighter"
            lineNumbers
            :readonly="readonly"
          />
          <v-row v-if="!readonly" class="my-0 mx-5" dense>
            <v-card-text style="color: red">{{ validation_error }}</v-card-text>
            <v-btn @click="beautify" color="primary">Beautify</v-btn>
          </v-row>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { highlight, languages } from "prismjs/components/prism-core";
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-json";
import "prismjs/themes/prism-coy.css";
import { js_beautify } from "js-beautify";
import { parse } from "esprima";

export default {
  components: {
    PrismEditor,
  },
  props: {
    language: {
      type: String,
      required: true,
      validator(lang) {
        return ["js", "json"].indexOf(lang) !== -1;
      },
    },
    value: {
      type: String,
      required: false,
      default: "",
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
  },
  computed: {
    getValue() {
      if (this.value) {
        return this.value;
      }
      return "";
    },
    getHighlighter() {
      if (this.language === "js") {
        return this.js_highlighter;
      }
      if (this.language === "json") {
        return this.json_highlighter;
      }
      return v => {
        return v;
      };
    },
  },
  data() {
    return {
      validation_error: "",
    };
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
    beautify() {
      if (this.language === "js") {
        this.beautify_js();
      }
      if (this.language === "json") {
        this.beautify_json();
      }
    },
    beautify_json() {
      if (this.validate_json(this.value)) {
        let beautify_value = JSON.stringify(JSON.parse(this.value), null, 2);
        this.updateValue(beautify_value);
        if (this.language === "js") {
          this.validate_javascript(beautify_value);
        }
      }
    },
    beautify_js() {
      if (this.validate_javascript(this.value)) {
        let temp_js = `var optiDigitalSetup = {${this.value}};`;
        temp_js = js_beautify(temp_js);
        let start = temp_js.indexOf("{");
        let end = temp_js.lastIndexOf("}");
        let beautify_value =
          temp_js
            .substring(start + 1, end)
            .split("\n")
            .filter(line => line.trim())
            .map(line => (line.startsWith("    ") ? line.substring(4) : line))
            .join("\n") || this.siteWebConfig.customFields;
        this.updateValue(beautify_value);
        if (this.language === "js") {
          this.validate_javascript(beautify_value);
        }
      }
    },
    js_highlighter(code) {
      return highlight(code, languages.js, "javascript"); // languages.<insert language> to return html with markup
    },
    json_highlighter(code) {
      return highlight(code, languages.json, "json"); // languages.<insert language> to return html with markup
    },
    validate(code) {
      if (this.language === "js") {
        this.validate_javascript(code);
      }
      if (this.language === "json") {
        this.validate_json(code);
      }
    },
    validate_javascript(code) {
      let temp_js = `var optiDigitalSetup = {${code}};`;
      try {
        parse(temp_js); //throws error on invalid code input
        this.validation_error = "";
        return true;
      } catch (err) {
        this.validation_error =
          "Invalid Java Script code, Error: " +
          '"' +
          err.description +
          '"' +
          ", Line number: " +
          '"' +
          err.lineNumber +
          '".';
        return false;
      }
    },
    validate_json(str) {
      try {
        this.validation_error = "";
        JSON.parse(str);
      } catch (e) {
        this.validation_error = String(e);
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped>
.my-editor {
  background: #ffffff;
  color: #000;
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}
</style>
