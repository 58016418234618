import axios from "axios";

const store = {
  namespaced: true,

  state: {
    loading: true,
    webBidder: [],
    adslotWebBidder: [],
    bidder_config_obj: {},
    enabledWebBidderCount: 0,
  },

  getters: {
    getLoading: state => {
      return state.loading;
    },
    getWebBidder: state => {
      return state.webBidder;
    },
    getEnabledWebBidderCount: state => {
      return state.enabledWebBidderCount;
    },
    getAdslotWebBidder: state => {
      return state.adslotWebBidder;
    },
    getStaticBidderConfig: state => {
      return state.bidder_config_obj;
    },
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setWebBidder(state, payload) {
      state.webBidder = payload;
      state.webBidder.forEach(el => {
        if (!el.site_config) el.site_config = "{}";
        el.site_config_obj =
          JSON.parse(
            el.site_config.replace(/'/g, '"').replace(/None/g, "null")
          ) || {};
      });
      state.enabledWebBidderCount =
        state.webBidder.filter(item => item.status === 1).length || 0;
    },
    setAdslotWebBidder(state, payload) {
      state.adslotWebBidder = payload.map(function(el) {
        el.config = el.config.replace(/'/g, '"');
        el.config = el.config.replace(/None/g, "null");
        el.config_obj = JSON.parse(el.config.replace(/'/g, '"')) || {};
        if (!el.bidder_config) el.bidder_config = "{}";
        el.bidder_config = el.bidder_config.replace(/'/g, '"');
        el.bidder_config = el.bidder_config.replace(/None/g, "null");
        state.bidder_config_obj =
          JSON.parse(el.bidder_config.replace(/'/g, '"')) || {};
        return el;
      });
    },
  },

  actions: {
    async fetchWebBidder({ commit, rootState }) {
      commit("setLoading", true);
      const payload = {
        site_id: rootState.tagsManager.site.id,
        revision: rootState.tagsManager.revision.number,
        inventory_type: rootState.tagsManager.inventoryType,
      };
      if (!payload.revision) return null;
      return new Promise(resolve => {
        axios.get("/web_bidder/", { params: payload }).then(response => {
          commit("setWebBidder", response.data.data);
          commit("setLoading", false);
          resolve();
        });
      });
    },
    async fetchAdslotWebBidder({ commit, rootState }, bidder) {
      commit("setLoading", true);
      const payload = {
        site_id: rootState.tagsManager.site.id,
        revision: rootState.tagsManager.revision.number,
        inventory_type: rootState.tagsManager.inventoryType,
        dpa_id: bidder.demand_partner_account_id,
      };
      if (!payload.site_id || !payload.revision) return null;
      return new Promise(resolve => {
        axios.get("/adslot_web_bidder/", { params: payload }).then(response => {
          commit("setAdslotWebBidder", response.data.data);
          commit("setLoading", false);
          resolve();
        });
      });
    },
    async updateWebBidderCustomConfig({ commit }, data) {
      commit("setLoading", true);
      const params = {
        bidder_web_config_id: data.id,
      };

      if (!params.bidder_web_config_id) return null;
      return new Promise(resolve => {
        axios
          .put("/web_bidder/custom_fields_config/", data.config, { params })
          .then(() => {
            commit("setLoading", false);
            resolve();
          });
      });
    },
    async updateWebBidderAdslots({ commit, rootState }, data) {
      commit("setLoading", true);
      const payload = {
        site_id: rootState.tagsManager.site.id,
        revision: rootState.tagsManager.revision.number,
        inventory_type: rootState.tagsManager.inventoryType,
        bidder_web_config_id: data[0].bidder_config_id,
      };

      if (
        !payload.site_id ||
        !payload.revision ||
        !payload.inventory_type ||
        !payload.bidder_web_config_id
      )
        return null;
      return new Promise(resolve => {
        axios
          .put("/adslot_web_bidder/", { data }, { params: payload })
          .then(response => {
            commit("setWebBidder", response.data.data);
            commit("setLoading", false);
            resolve();
          });
      });
    },
    async updateBidderGlobals({ commit, rootState }, data) {
      const payload = {
        site_id: rootState.tagsManager.site.id,
        revision: rootState.tagsManager.revision.number,
        inventory_type: rootState.tagsManager.inventoryType,
        bidder_web_config_id: data.id,
      };

      if (
        !payload.site_id ||
        !payload.revision ||
        !payload.inventory_type ||
        !payload.bidder_web_config_id
      )
        return null;
      commit("setLoading", true);
      return new Promise(resolve => {
        axios
          .put("/web_bidder/global_fields", data, { params: payload })
          .then(() => {
            commit("setLoading", false);
            resolve();
          });
      });
    },
    async updateBidderInfo({ commit }, data) {
      const payload = {
        status: data.status,
        consent_type_target: data.consent_type_target,
      };
      commit("setLoading", true);
      return new Promise(resolve => {
        axios.put("/web_bidder/" + data.id, payload).then(() => {
          commit("setLoading", false);
          resolve();
        });
      });
    },
  },
};

export default store;
