<template>
  <v-card elevation="0">
    <v-overlay absolute :opacity="0.4" :value="overlay">
      <v-progress-circular
        :value="isLoading"
        size="64"
        indeterminate
        color="primary"
      />
    </v-overlay>
    <slot />
  </v-card>
</template>
<script>
export default {
  name: "LoadingOverlay",
  props: {
    overlay: Boolean,
  },
  computed: {
    isLoading() {
      return Number(this.overlay);
    },
  },
};
</script>

<style scoped></style>

<!--
<LoadingOverlay overlay>
  <template v-slot:default>

  </template>
</LoadingOverlay>
>-->
