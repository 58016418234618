<template>
  <v-dialog v-model="show" max-width="80%">
    <v-card style="overflow-x: hidden;">
      <v-row align="center">
        <v-col cols="auto">
          <v-card-title class="mr-4"
            >Publisher - Demand partner account</v-card-title
          >
        </v-col>
        <v-spacer />
        <v-col cols="auto">
          <v-btn color="blue darken-1" text @click="close">{{
            $t("buttons.general.close")
          }}</v-btn>
        </v-col>
      </v-row>

      <v-card-text>
        <v-container>
          <v-data-table
            height="50vh"
            :loading="getLoading"
            :headers="headers"
            :items="getPDPAforPublisherList"
            item-key="demand_partner_account_id"
            fixed-header
            :items-per-page="30"
            :footer-props="{
              'show-first-last-page': true,
              'items-per-page-options': [10, 20, 30, 50, 100],
            }"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title
                  ><span class="headline">{{
                    getPublisherForDPA.name
                  }}</span></v-toolbar-title
                >
                <v-divider class="mx-4" inset vertical />
                <v-spacer />
                <v-dialog v-model="new_dialog" max-width="500px">
                  <template v-slot:activator="{ on }">
                    <v-row justify="end">
                      <v-col cols="auto">
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          @click="addDefaultsDpa"
                        >
                          Load Defaults
                        </v-btn>
                      </v-col>
                      <v-col cols="auto">
                        <v-btn color="primary" dark class="mb-2" v-on="on">{{
                          $t("buttons.publisher_demand_partner.add_connection")
                        }}</v-btn>
                      </v-col>
                    </v-row>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{
                        $t("publisher_demand_partner.select_dpa")
                      }}</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-form ref="form_dpa_add" v-model="valid_dpa_add">
                          <v-autocomplete
                            :items="getDemandPartnerAccounts"
                            item-text="name"
                            :label="
                              $t('publishers.select_demand_partner_account')
                            "
                            v-model="dpa"
                            return-object
                            :rules="createRules([baseValidation.required])"
                          />
                        </v-form>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn color="blue darken-1" text @click="close_inner">{{
                        $t("buttons.general.close")
                      }}</v-btn>
                      <v-btn
                        color="blue darken-1"
                        :disabled="!valid_dpa_add"
                        text
                        @click="addDPA"
                        >{{ $t("buttons.general.add") }}</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn
                color="blue darken-1"
                text
                @click="open_winners_dialog(item)"
              >
                <v-icon small>
                  edit
                </v-icon>
              </v-btn>
              <v-btn
                :color="
                  item.is_spp_credentials_provided ? 'green' : 'orange darken-1'
                "
                text
                @click="open_credential_dialog(item)"
              >
                <v-icon small>
                  mdi-key
                </v-icon>
              </v-btn>
              <v-btn
                depressed
                icon
                large
                class="mx-4"
                @click="open_confirm(item)"
              >
                <v-icon small>
                  close
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="close">{{
          $t("buttons.general.close")
        }}</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="winnerDialog" max-width="500px">
      <v-card>
        <v-container class="px-6">
          <v-card elevation="5" disabled>
            <v-card-title>Default config </v-card-title>
            <v-card-text class="ma-2" elevation="-5">{{
              pdpa.default_match_config
            }}</v-card-text>
          </v-card>
        </v-container>
        <v-container class="px-6">
          <v-card elevation="5" disabled>
            <v-card-title>Current config</v-card-title>
            <v-card-text class="ma-2" elevation="-5">{{
              getPDPAforPublisher(dpa_id).match_config
            }}</v-card-text>
          </v-card>
        </v-container>
        <v-container>
          <v-card class="ma-4" elevation="5">
            <v-card-text>
              <v-list dense>
                <v-subheader>{{
                  $t("publisher_demand_partner.winners_list")
                }}</v-subheader>

                <v-list-item
                  v-for="winner in getPDPAforPublisher(dpa_id).winners"
                  :key="winner"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="winner" />
                  </v-list-item-content>

                  <v-list-item-icon>
                    <v-btn
                      depressed
                      icon
                      large
                      class="mx-4"
                      @click="
                        remove_winner(getPDPAforPublisher(dpa_id), winner)
                      "
                    >
                      <v-icon small>
                        remove
                      </v-icon>
                    </v-btn>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
              <v-row><v-divider class="my-4"/></v-row>
              <v-row>
                <v-col cols="9">
                  <v-combobox
                    ref="pdpd_combobox"
                    :items="getActiveWinners"
                    item-text="winner"
                    :label="$t('publisher_demand_partner.winner_input')"
                    v-model="winner"
                  />
                </v-col>
                <v-col cols="3">
                  <v-btn
                    class="ma-4"
                    color="primary"
                    @click="push_win(getPDPAforPublisher(dpa_id), winner)"
                    >{{ $t("buttons.general.add") }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-container>
        <v-container class="px-6">
          <v-card elevation="5" style="overflow-x: hidden;">
            <v-list dense>
              <v-subheader>{{
                $t("publisher_demand_partner.programmatic_channel_list")
              }}</v-subheader>
              <v-list-item
                v-for="programmatic_channel in getPDPAforPublisher(dpa_id)
                  .programmatic_channels"
                :key="programmatic_channel"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="programmatic_channel" />
                </v-list-item-content>

                <v-list-item-icon>
                  <v-btn
                    depressed
                    icon
                    large
                    class="mx-4"
                    @click="
                      remove_programmatic_channel(
                        getPDPAforPublisher(dpa_id),
                        programmatic_channel
                      )
                    "
                  >
                    <v-icon small>
                      remove
                    </v-icon>
                  </v-btn>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
            <v-row><v-divider class="my-4"/></v-row>
            <v-row class="mx-1">
              <v-col cols="9"
                ><v-combobox
                  ref="dpat_programmatic_channel"
                  :items="getProgrammaticChannels"
                  item-text="programmatic_channel"
                  :label="
                    $t('publisher_demand_partner.programmatic_channel_input')
                  "
                  v-model="programmatic_channel"
              /></v-col>
              <v-col cols="3">
                <v-btn
                  class="ma-4"
                  color="primary"
                  depressed
                  @click="
                    push_programmatic_channel(
                      getPDPAforPublisher(dpa_id),
                      programmatic_channel
                    )
                  "
                  >{{ $t("buttons.general.add")
                  }}<v-icon>mdi-arrow</v-icon></v-btn
                ></v-col
              >
            </v-row>
          </v-card>
        </v-container>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="restoreDefaults(getPDPAforPublisher(dpa_id))"
            >{{
              $t("buttons.publisher_demand_partner.restore_defaults")
            }}</v-btn
          >
          <v-btn
            color="blue darken-1"
            :disabled="!configModified"
            text
            @click="saveWinner(getPDPAforPublisher(dpa_id))"
            >{{ $t("buttons.publisher_demand_partner.save_winners") }}</v-btn
          >
          <v-btn color="blue darken-1" text @click="close_winner">{{
            $t("buttons.general.close")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirm_dpa_remove_dialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title
          >Are you sure?
        </v-card-title>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="removeDPA(getPDPAforPublisher(dpa_id))"
          >
            Yes
          </v-btn>
          <v-btn color="primary" text @click="close_inner()">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="p_dpa_credentials_dialog" max-width="80%">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title
          >Publisher - Demand Partner Accounts Credentials
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-row>
            <v-col>
              <v-btn
                color="primary"
                @click="edit_p_dpa_credential(getDefaultCredential())"
              >
                Add Credentials entry
              </v-btn>
            </v-col>
          </v-row>
          <v-data-table
            :items="getCredentials"
            :headers="p_dpa_credentials_headers"
            height="50vh"
            :loading="getLoading"
            item-key="key"
            fixed-header
            :items-per-page="30"
            :footer-props="{
              'show-first-last-page': true,
              'items-per-page-options': [10, 20, 30, 50, 100],
            }"
          >
            <template v-slot:item.value>
              <p>********</p>
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn
                color="blue darken-1"
                text
                @click="edit_p_dpa_credential(item)"
              >
                <v-icon color="primary" small>
                  edit
                </v-icon>
              </v-btn>
              <v-btn text @click="pre_remove_p_dpa_credential(item)">
                <v-icon color="error" small>
                  delete
                </v-icon>
              </v-btn>
            </template>
            /></v-data-table
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="p_dpa_credentials_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="p_dpa_credential_dialog_edit" max-width="60%">
      <v-card>
        <v-card-title>
          Edit/Add Dpa Credential Entry
        </v-card-title>
        <v-card-text>
          <v-form v-model="credentials_validation" ref="form_credential">
            <v-row>
              <v-col>
                <v-text-field
                  label="Key"
                  v-model="p_dpa_credential_edit_item.key"
                  :rules="createRules([baseValidation.required])"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Value"
                  v-model="p_dpa_credential_edit_item.value"
                  :rules="createRules([baseValidation.required])"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            @click="pre_save_p_dpa_credental"
            :disabled="!credentials_validation"
          >
            Add
          </v-btn>
          <v-btn @click="p_dpa_credential_dialog_edit = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="p_dpa_credentials_dialog_confirmation_remove"
      width="40%"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title
          >Please, confirm deleting credential entry.
        </v-card-title>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="error" @click="remove_p_dpa_credential">
            Remove
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            @click="p_dpa_credentials_dialog_confirmation_remove = false"
          >
            Cancel
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="p_dpa_credentials_dialog_confirmation_overwrite"
      width="40%"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title
          >Please, confirm overwriting credential entry.
        </v-card-title>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="warning" @click="save_p_dpa_credental">
            Overwrite
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            @click="p_dpa_credentials_dialog_confirmation_overwrite = false"
          >
            Cancel
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import store from "./../../../store/index";
import { baseValidation } from "../../../validation/baseValidation";
import { validationHelper } from "../../../validation/validationHelper";
import { eventBus } from "@/main";

export default {
  name: "PublisherDemandPartnerDialog",
  props: ["publisher"],
  mixins: [baseValidation, validationHelper],
  data() {
    return {
      confirm_dpa_remove_dialog: false,
      show: false,
      readOnly: false,
      new_dialog: false,
      valid_dpa_add: false,
      disable_select: false,
      model: null,
      winnerDialog: false,
      configModified: false,
      dpa: null,
      pdpa: {},
      dpa_id: -1,
      winner: null,
      programmatic_channel: null,
      p_dpa_credentials_publisher: {},
      p_dpa_credentials_dialog: false,
      p_dpa_credential_dialog_edit: false,
      p_dpa_credential_edit_item: {},
      p_dpa_credentials_dialog_confirmation_overwrite: false,
      p_dpa_credentials_dialog_confirmation_remove: false,
      credentials_validation: true,
      p_dpa_credentials_headers: [
        { text: "Key", value: "key" },
        { text: "Value", value: "value" },
        {
          text: "Action",
          value: "action",
          sortable: false,
          filterable: false,
          align: "center",
        },
      ],
      headers: [
        {
          text: "ID",
          value: "demand_partner_account_id",
        },
        {
          text: "Name",
          value: "demand_partner_account_name",
        },
        {
          text: "Action",
          value: "action",
          sortable: false,
          filterable: false,
          align: "center",
        },
        { text: "", value: "data-table-expand" },
      ],
    };
  },
  mounted() {
    store.dispatch("publishers/fetchPDPAForPublisher");
    store.dispatch("demandPartnerAccounts/fetchActiveWinners");
    store.dispatch("demandPartnerAccounts/fetchProgrammaticChannels");
    store.dispatch("demandPartnerAccounts/fetchDemandPartnerAccounts");
  },
  computed: {
    ...mapGetters("publishers", [
      "getLoading",
      "getPublishers",
      "getPDPAforPublisherList",
      "getPublisherForDPA",
      "getPDPAforPublisher",
    ]),
    ...mapGetters("demandPartnerAccounts", [
      "getDemandPartnerAccounts",
      "getProgrammaticChannels",
      "getActiveWinners",
    ]),
    ...mapGetters("publisherDPACredentials", [
      "getDefaultCredential",
      "getCredentials",
    ]),
  },
  methods: {
    ...mapActions("publishers", ["addDefaultsDpa", "fetchPDPAForPublisher"]),
    ...mapActions("publisherDPACredentials", [
      "fetchCredentialsForPDPA",
      "removeCredential",
      "saveCredential",
    ]),
    removeDPA(item) {
      store
        .dispatch("publishers/delete_pdpa", item)
        .then()
        .catch(err => console.log(err))
        .finally(() => this.close_inner());
    },
    push_win(item, winner) {
      if (winner !== this.$refs.pdpd_combobox.internalSearch) {
        winner = this.$refs.pdpd_combobox.internalSearch;
      }
      if (winner && !item.winners.find(elem => elem === winner)) {
        item.winners.push(winner);
        this.configModified = true;
      }
    },
    remove_winner(item, winner) {
      item.winners = item.winners.filter(el => el !== winner);
      this.configModified = true;
    },
    push_programmatic_channel(item, programmatic_channel) {
      if (
        programmatic_channel !==
        this.$refs.dpat_programmatic_channel.internalSearch
      ) {
        programmatic_channel = this.$refs.dpat_programmatic_channel
          .internalSearch;
      }
      if (
        programmatic_channel &&
        !item.programmatic_channels.find(elem => elem === programmatic_channel)
      ) {
        item.programmatic_channels.push(programmatic_channel);
        this.configModified = true;
      }
    },
    remove_programmatic_channel(item, programmatic_channel) {
      item.programmatic_channels = item.programmatic_channels.filter(
        el => el !== programmatic_channel
      );
      this.configModified = true;
    },
    addDPA() {
      if (!this.$refs.form_dpa_add.validate()) {
        return;
      }
      store
        .dispatch("publishers/createDPA", { ...this.dpa })
        .then(response => {
          if (response && "warnings" in response) {
            eventBus.$emit("action_warning", response["warnings"]);
          }
        })
        .catch(err => console.log(err));
      this.close_inner();
    },
    restoreDefaults(item) {
      item.match_config = { ...item.default_match_config };

      store
        .dispatch("publishers/restore_dpa_config", { ...item })
        .then(() => store.dispatch("publishers/fetchPDPAForPublisher"))
        .catch(err => console.log(err));
    },
    saveWinner(item) {
      store
        .dispatch("publishers/update_dpa", { ...item })
        .then(() => store.dispatch("publishers/fetchPDPAForPublisher"))
        .then(() => (this.configModified = false))
        .catch(err => console.log(err));
    },
    open_winners_dialog(item) {
      this.winnerDialog = true;
      this.pdpa = item;
      this.dpa_id = item.demand_partner_account_id;
      this.configModified = false;
    },
    open_confirm(item) {
      this.confirm_dpa_remove_dialog = true;
      this.pdpa = item;
      this.dpa_id = item.demand_partner_account_id;
    },
    close() {
      this.show = false;
    },
    close_inner() {
      this.confirm_dpa_remove_dialog = false;
      this.new_dialog = false;
    },
    close_winner() {
      this.winnerDialog = false;
      store.dispatch("publishers/fetchPDPAForPublisher");
    },
    async open_credential_dialog(item) {
      this.p_dpa_credentials_publisher = { ...item };
      await this.fetchCredentialsForPDPA(item);
      this.p_dpa_credentials_dialog = true;
    },
    edit_p_dpa_credential(credentials_edit_item) {
      this.p_dpa_credential_edit_item = credentials_edit_item;
      this.p_dpa_credential_dialog_edit = true;
    },
    pre_save_p_dpa_credental() {
      if (!this.$refs.form_credential.validate()) {
        return;
      }
      if (
        this.getCredentials.find(
          element => element.key === this.p_dpa_credential_edit_item.key
        ) // also I could use some
      ) {
        this.p_dpa_credentials_dialog_confirmation_overwrite = true;
      } else {
        this.save_p_dpa_credental();
      }
    },
    async save_p_dpa_credental() {
      await this.saveCredential(this.p_dpa_credential_edit_item);
      await this.open_credential_dialog(this.p_dpa_credentials_publisher);
      this.p_dpa_credential_dialog_edit = false;
      this.p_dpa_credentials_dialog_confirmation_overwrite = false;
      this.fetchPDPAForPublisher();
    },
    pre_remove_p_dpa_credential(credentials_edit_item) {
      this.p_dpa_credential_edit_item = credentials_edit_item;
      this.p_dpa_credentials_dialog_confirmation_remove = true;
    },
    async remove_p_dpa_credential() {
      await this.removeCredential(this.p_dpa_credential_edit_item);
      await this.open_credential_dialog(this.p_dpa_credentials_publisher);
      this.p_dpa_credentials_dialog_confirmation_remove = false;
      this.fetchPDPAForPublisher();
    },
  },
};
</script>

<style scoped></style>
